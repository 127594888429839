export const api_stage = {
  API_URL_STAGE1: "https://stage1.seedphoto.dev/",
  API_URL_STAGE2: "https://stage2.seedphoto.dev/",
  API_URL_STAGE3: "https://stage3.seedphoto.dev/",
  API_URL_MAIN: "https://api.seed.photo/",
};

export const api_stage_twitter = {
  API_URL_STAGE1: "https://twitter.seedphoto.dev",
  API_URL_MAIN: "https://twitter.seed.photo",
};

export const stage_env = {
  stage: process.env.NEXT_PUBLIC_STAGE,
};

export const web3Configs = {
  projectId_Main: "ee9718cba6351426f9cc94d4cd8ed051",
  projectId_stage: "aaf759f367d2816e382d8699c3e1eceb",
};

export const metadataWalletConnect = {
  name: "Seed Photo",
  description: "SEED.Photo is a NFT minting platform for Photographers.",
  url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}`,
  // icons: ["/apple-touch-icon"],
};

//BAu1zNHx8bucLSR87IZDRBtYUMLmrXVoi5FEg7Nb7b6Ae7QGLRf3k8q3wuY9FqTeofzdSDyx7Eazt8Eu72ygpwM
export const web3AuthClientId = {
  TestNet:
    "BGgtd-ejefdIRR32FkmiXHIxAhotjD0GY41-Opp6eacNVAtAEbFbLoDwn--t-OMJvuEsodmTGQD3fnnxXzu1MwE",
};
