// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_ACCOUNT_REFERRALS,
  GET_ACCOUNT_REFERRALS_SUCCESS,
  GET_ACCOUNT_REFERRALS_FAILURE,
} from "./actionTypes";
import { referralSuccess, referralFailure } from "./action";
import { getAccountReferrals } from "@/config/helpers/backend_helper";
function* Referral(action) {
  try {
    const { userName, requestData } = action.payload;
    //  console.log(requestData, ":::::::::", userName);
    //  console.log(requestData.userName);
    const user = requestData.userName;
    const response = yield call(getAccountReferrals, user, requestData);
    // console.log(response.Data);
    yield put(referralSuccess(response));
  } catch (error) {
    yield put(referralFailure(error));
  }
}

function* ReferralSaga() {
  yield takeLatest(GET_ACCOUNT_REFERRALS, Referral);
}

export default ReferralSaga;
