// saga.js
import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { GET_ENUMS } from "./actionTypes";
import { GetEnumsSuccess, GetEnumsError } from "./action";
import { getEnums } from "@/config/helpers/backend_helper";

function* GetEnumsfunction({ payload: { Enums } }) {
  try {
    const response = yield call(getEnums, Enums);
    // console.log("enums" , response);
    yield put(GetEnumsSuccess(response));
  } catch (error) {
    yield put(GetEnumsError(error));
  }
}

export function* watchEnums() {
  yield takeEvery(GET_ENUMS, GetEnumsfunction);
}

function* EnumsSaga() {
  yield all([fork(watchEnums)]);
}

export default EnumsSaga;
