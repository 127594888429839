// actions.js
import {
  MUSEUM_CALENDAR,
  MUSEUM_CALENDAR_SUCCESS,
  MUSEUM_CALENDAR_ERROR,
} from "./actionTypes";

export const museumCalendarData = (week) => {
  return {
    type: MUSEUM_CALENDAR,
    payload: week,
  };
};

export const museumCalendarDataSuccess = (data) => {
  return {
    type: MUSEUM_CALENDAR_SUCCESS,
    payload: data,
  };
};

export const museumCalendarDataError = (data) => {
  return {
    type: MUSEUM_CALENDAR_ERROR,
    payload: data,
  };
};
