// actions.js
import {
  REFERRAL_DASHBOARD_ARTIST,
  REFERRAL_DASHBOARD_ARTIST_SUCCESS,
  REFERRAL_DASHBOARD_ARTIST_ERROR,
  RESET_REFERRAL_DASHBOARD_ARTIST,
} from "./actionTypes";

export const referralDashboardData = () => {
  return {
    type: REFERRAL_DASHBOARD_ARTIST,
  };
};

export const referralDashboardDataSuccess = (data) => {
  return {
    type: REFERRAL_DASHBOARD_ARTIST_SUCCESS,
    payload: data,
  };
};

export const referralDashboardDataError = (data) => {
  return {
    type: REFERRAL_DASHBOARD_ARTIST_ERROR,
    payload: data,
  };
};

export const resetReferralDashboardData = () => {
  return {
    type: RESET_REFERRAL_DASHBOARD_ARTIST,
  };
};
