// actions.js
import {
  MUSEUM_RELEASED,
  MUSEUM_RELEASED_SUCCESS,
  MUSEUM_RELEASED_ERROR,
} from "./actionTypes";

export const museumReleasedData = () => {
  return {
    type: MUSEUM_RELEASED,
  };
};

export const museumReleasedDataSuccess = (data) => {
  return {
    type: MUSEUM_RELEASED_SUCCESS,
    payload: data,
  };
};

export const museumReleasedDataError = (data) => {
  return {
    type: MUSEUM_RELEASED_ERROR,
    payload: data,
  };
};
