import {
  FOLLOW_MEMBER,
  FOLLOW_MEMBER_ERROR,
  FOLLOW_MEMBER_SUCCESS,
  RESET_FOLLOW_MEMBER,
} from "./actionTypes";

//followMember
export const followMember = (username) => {
  return {
    type: FOLLOW_MEMBER,
    payload: username,
  };
};

export const followMemberSuccess = (user) => {
  return {
    type: FOLLOW_MEMBER_SUCCESS,
    payload: user,
  };
};

export const followMemberError = (error) => {
  return {
    type: FOLLOW_MEMBER_ERROR,
    payload: error,
  };
};

//resetFlags
export const resetFollowMemberFlag = () => {
  return {
    type: RESET_FOLLOW_MEMBER,
  };
};
