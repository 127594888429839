import { call, put, takeEvery } from "redux-saga/effects";

import {
  getToggleFavorites,
  getToggleFavoriteCollection,
} from "@/config/helpers/backend_helper";

import {
  toggleFavoritesDataSuccess,
  toggleFavoritesDataError,
  FavoriteCollectionSuccess,
  FavoriteCollectionError,
} from "./action";

import { TOGGLE_FAVORITES, TOGGLE_FAVORITES_COLLECTIONS } from "./actionTypes";

function* fetchLikeToggle({ payload: { id } }) {
  console.log("payload " + id);
  try {
    const response = yield call(getToggleFavorites, id);
    yield put(toggleFavoritesDataSuccess(response));
  } catch (error) {
    yield put(toggleFavoritesDataError(error));
  }
}

function* fetchLikeCollectonToggle({ payload: { id } }) {
  try {
    const response = yield call(getToggleFavoriteCollection, id);
    yield put(FavoriteCollectionSuccess(response));
  } catch (error) {
    yield put(FavoriteCollectionError(error));
  }
}

function* ToggleLikeSaga() {
  yield takeEvery(TOGGLE_FAVORITES, fetchLikeToggle);
  yield takeEvery(TOGGLE_FAVORITES_COLLECTIONS, fetchLikeCollectonToggle);
}

export default ToggleLikeSaga;
