import { call, put, takeEvery } from "redux-saga/effects";
import { postHomeNewsLetters } from "@/config/helpers/backend_helper";
import { NewsLetterDataSuccess, NewsLetterDataError } from "./action";
import { NEWS_LETTER_HOME } from "./actionTypes";

function* fetchNewsLetterData({ payload: Email }) {
  try {
    const response = yield call(postHomeNewsLetters, Email);

    yield put(NewsLetterDataSuccess(response));
  } catch (error) {
    yield put(NewsLetterDataError(error));
  }
}

function* NewsLetterSaga() {
  yield takeEvery(NEWS_LETTER_HOME, fetchNewsLetterData);
}

export default NewsLetterSaga;
