import {
  MUSEUM_AUCTION,
  MUSEUM_AUCTION_SUCCESS,
  MUSEUM_AUCTION_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgAuction: "",
  loadingAuction: false,
  errorAuction: false,
  successAuction: null,
  nft: [],
};

const MuseumAuction = (state = initialState, action) => {
  switch (action.type) {
    //current week
    case MUSEUM_AUCTION:
      return {
        ...state,
        loadingAuction: true,
        errorAuction: false,
        successAuction: false,
        errorMsgAuction: null,
      };
    case MUSEUM_AUCTION_SUCCESS:
      return {
        ...state,
        loadingAuction: false,
        errorAuction: false,
        successAuction: true,
        nft: action.payload,
        errorMsgAuction: null,
      };
    case MUSEUM_AUCTION_ERROR:
      return {
        ...state,
        errorMsgAuction: action.payload.data,
        loadingAuction: false,
        errorAuction: true,
        successAuction: false,
      };

    default:
      return state;
  }
};

export default MuseumAuction;
