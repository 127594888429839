import {
  NEW_LISTING_HOME,
  NEW_LISTING_HOME_SUCCESS,
  NEW_LISTING_HOME_ERROR,
  RESET_NEW_LISTING_HOME,
} from "./actionTypes";

const initialState = {
  errorMsgNewListingNfts: "",
  loadingNewListingNfts: false,
  errorNewListingNfts: false,
  successNewListingNfts: null,
  nfts: null,
};

const NewListing = (state = initialState, action) => {
  switch (action.type) {
    //NEW_LISTING_HOME
    case NEW_LISTING_HOME:
      state = {
        ...state,
        loadingNewListingNfts: true,
        errorNewListingNfts: false,
        successNewListingNfts: false,
        errorMsgNewListingNfts: null,
      };
      break;
    case NEW_LISTING_HOME_SUCCESS:
      state = {
        ...state,
        loadingNewListingNfts: false,
        errorMsgNewListingNfts: false,
        successNewListingNfts: true,
        nfts: action.payload,
      };
      break;
    case NEW_LISTING_HOME_ERROR:
      state = {
        ...state,
        errorMsgNewListingNfts: action.payload.data,
        loadingNewListingNfts: false,
        errorNewListingNfts: true,
        successNewListingNfts: false,
      };
      break;
    case RESET_NEW_LISTING_HOME:
      state = {
        ...state,
        errorMsgNewListingNfts: null,
        loadingNewListingNfts: false,
        errorNewListingNfts: false,
        successNewListingNfts: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default NewListing;
