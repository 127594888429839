import {
  getTutorialsList,
  getTutorialsList2,
  postTutorialIncreaseViewCount,
} from "@/config/helpers/backend_helper";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  TUTORIALS_INCREASE_VIEW_COUNT,
  TUTORIALS_LIST,
  TUTORIALS_LIST2,
} from "./actionTypes";
import {
  increaseViewCountDataError,
  increaseViewCountDataSuccess,
  tutorialsDataError,
  tutorialsDataError2,
  tutorialsDataSuccess,
  tutorialsDataSuccess2,
} from "./actions";

function* fetchTutorialsData(action) {
  try {
    const response = yield call(getTutorialsList, action.payload);

    yield put(tutorialsDataSuccess(response));
  } catch (error) {
    yield put(tutorialsDataError(error));
  }
}

function* fetchTutorialsData2(action) {
  try {
    const response = yield call(getTutorialsList2, action.payload);

    yield put(tutorialsDataSuccess2(response));
  } catch (error) {
    yield put(tutorialsDataError2(error));
  }
}

function* fetchIncreaseViewCountSaga({ payload: { tutorialId } }) {
  try {
    const response = yield call(postTutorialIncreaseViewCount, tutorialId);

    yield put(increaseViewCountDataSuccess(response));
  } catch (error) {
    yield put(increaseViewCountDataError(error));
  }
}

function* tutorialsSaga() {
  yield takeLatest(TUTORIALS_LIST, fetchTutorialsData);
  yield takeLatest(TUTORIALS_LIST2, fetchTutorialsData2);
  yield takeLatest(TUTORIALS_INCREASE_VIEW_COUNT, fetchIncreaseViewCountSaga);
}

export default tutorialsSaga;
