import {
  NFT_DETAILS,
  NFT_DETAILS_SUCCESS,
  NFT_DETAILS_ERROR,
  NFT_DETAILS_STEP2,
  NFT_DETAILS_STEP2_SUCCESS,
  NFT_DETAILS_STEP2_ERROR,
  NFT_TOGGLE_ARCHIVE,
  NFT_TOGGLE_ARCHIVE_SUCCESS,
  NFT_TOGGLE_ARCHIVE_ERROR,
  NFT_CANCEL_PROMOTION,
  NFT_CANCEL_PROMOTION_SUCCESS,
  NFT_CANCEL_PROMOTION_ERROR,
  NFT_CHECK_DETAILS,
  NFT_CHECK_DETAILS_SUCCESS,
  NFT_CHECK_DETAILS_ERROR,
  RESET_NFT_DETAIL_DATA,
  RESET_NFT_DETAIL_STEP2_DATA,
  RESET_NFT_DETAIL_ALL_DATA,
  RESET_TOGGLE_ARCHIVE_DATA,
  RESET_ONLY_NFT_DETAIL_DATA,
  RESET_CANCEL_PROMOTION_DATA,
} from "./actionTypes";

const initialState = {
  errorMsgNftDetail: "",
  loadingNftDetail: false,
  errorNftDetail: false,
  successNftDetail: null,
  info: null,
  errorMsgNftDetailStep2: "",
  loadingNftDetailStep2: false,
  errorNftDetailStep2: false,
  successNftDetailStep2: null,
  infoStep2: null,
  errorMsgArchive: "",
  loadingArchive: false,
  errorArchive: false,
  successArchive: null,
  archive: [],
  errorMsgCancelPromotion: "",
  loadingCancelPromotion: false,
  errorCancelPromotion: false,
  successCancelPromotion: false,
  cancelPromotion: null,
  //check-details
  errorMsgCheckDetail: "",
  loadingCheckDetail: false,
  errorCheckDetail: false,
  successCheckDetail: null,
  checkDetail: null,
};

const NftDetail = (state = initialState, action) => {
  switch (action.type) {
    //NFT_DETAILS
    case NFT_DETAILS:
      state = {
        ...state,
        loadingNftDetail: true,
        errorNftDetail: false,
        successNftDetail: false,
        errorMsgNftDetail: null,
      };
      break;
    case NFT_DETAILS_SUCCESS:
      state = {
        ...state,
        loadingNftDetail: false,
        errorMsgNftDetail: false,
        successNftDetail: true,
        info: action.payload,
      };
      break;
    case NFT_DETAILS_ERROR:
      state = {
        ...state,
        errorMsgNftDetail: action.payload,
        loadingNftDetail: false,
        errorNftDetail: true,
        successNftDetail: false,
      };
      break;
    //NFT_DETAILS_STEP2
    case NFT_DETAILS_STEP2:
      state = {
        ...state,
        loadingNftDetailStep2: true,
        errorNftDetailStep2: false,
        successNftDetailStep2: false,
        errorMsgNftDetailStep2: null,
      };
      break;
    case NFT_DETAILS_STEP2_SUCCESS:
      state = {
        ...state,
        loadingNftDetailStep2: false,
        errorMsgNftDetailStep2: false,
        successNftDetailStep2: true,
        infoStep2: action.payload,
      };
      break;
    case NFT_DETAILS_STEP2_ERROR:
      state = {
        ...state,
        errorMsgNftDetailStep2: action.payload,
        loadingNftDetailStep2: false,
        errorNftDetailStep2: true,
        successNftDetailStep2: false,
      };
      break;
    //NFT_TOGGLE_ARCHIVE
    case NFT_TOGGLE_ARCHIVE:
      state = {
        ...state,
        loadingArchive: true,
        errorArchive: false,
        successArchive: false,
        errorMsgArchive: null,
      };
      break;
    case NFT_TOGGLE_ARCHIVE_SUCCESS:
      state = {
        ...state,
        loadingArchive: false,
        errorMsgArchive: false,
        successArchive: true,
        archive: action.payload,
      };
      break;
    case NFT_TOGGLE_ARCHIVE_ERROR:
      state = {
        ...state,
        errorMsgArchive: action.payload,
        loadingArchive: false,
        errorArchive: true,
        successArchive: false,
      };
      break;
    //NFT_CANCEL_PROMOTION
    case NFT_CANCEL_PROMOTION:
      state = {
        ...state,
        loadingCancelPromotion: true,
        errorCancelPromotion: false,
        successCancelPromotion: false,
        errorMsgCancelPromotion: null,
      };
      break;
    case NFT_CANCEL_PROMOTION_SUCCESS:
      state = {
        ...state,
        loadingCancelPromotion: false,
        errorMsgCancelPromotion: false,
        successCancelPromotion: true,
        cancelPromotion: action.payload,
      };
      break;
    case NFT_CANCEL_PROMOTION_ERROR:
      state = {
        ...state,
        errorMsgCancelPromotion: action.payload,
        loadingCancelPromotion: false,
        errorCancelPromotion: true,
        successCancelPromotion: false,
      };
      break;
    //NFT_CHECK_DETAILS
    case NFT_CHECK_DETAILS:
      state = {
        ...state,
        loadingCheckDetail: true,
        errorCheckDetail: false,
        successCheckDetail: false,
        errorMsgCheckDetail: null,
      };
      break;
    case NFT_CHECK_DETAILS_SUCCESS:
      state = {
        ...state,
        loadingCheckDetail: false,
        errorMsgCheckDetail: false,
        successCheckDetail: true,
        checkDetail: action.payload,
      };
      break;
    case NFT_CHECK_DETAILS_ERROR:
      state = {
        ...state,
        errorMsgCheckDetail: action.payload,
        loadingCheckDetail: false,
        errorCheckDetail: true,
        successCheckDetail: false,
      };
      break;
    //RESET_NFT_DETAIL_DATA
    case RESET_NFT_DETAIL_DATA:
      state = {
        ...state,
        errorMsgNftDetail: null,
        loadingNftDetail: false,
        errorNftDetail: false,
        successNftDetail: null,
        info: null,
        errorMsgCheckDetail: null,
        loadingCheckDetail: false,
        errorCheckDetail: false,
        successCheckDetail: null,
        checkDetail: null,
      };
      break;
    //RESET_NFT_DETAIL_DATA_STEP2
    case RESET_NFT_DETAIL_STEP2_DATA:
      state = {
        ...state,
        errorMsgNftDetailStep2: null,
        loadingNftDetailStep2: false,
        errorNftDetailStep2: false,
        successNftDetailStep2: null,
        infoStep2: null,
      };
      break;
    //RESET_ONLY_NFT_DETAIL_DATA
    case RESET_ONLY_NFT_DETAIL_DATA:
      state = {
        ...state,
        info: null,
      };
      break;
    //RESET_NFT_DETAIL_ALL_DATA
    case RESET_NFT_DETAIL_ALL_DATA:
      state = {
        ...state,
        errorMsgNftDetail: null,
        loadingNftDetail: false,
        errorNftDetail: false,
        successNftDetail: null,
        info: null,
        errorMsgArchive: "",
        loadingArchive: false,
        errorArchive: false,
        successArchive: null,
        archive: [],
      };
      break;
    case RESET_CANCEL_PROMOTION_DATA:
      state = {
        ...state,
        errorMsgCancelPromotion: "",
        loadingCancelPromotion: false,
        errorCancelPromotion: false,
        successCancelPromotion: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default NftDetail;
