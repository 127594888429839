import "@/assets/styles/app.scss";
import { wagmiConfig } from "@/config/helpers/web3Utiles";
import store from "@/store";
import { DM_Sans, Outfit, Poppins } from "next/font/google";
// import { QueryClient, QueryClientProvider } from "react-query";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { WagmiProvider } from "wagmi";
import {
  ElliWallet,
  EthosWallet,
  FrontierWallet,
  GlassWallet,
  MartianWallet,
  MorphisWallet,
  NightlyWallet,
  OneKeyWallet,
  SensuiWallet,
  SuiMainnetChain,
  SuiTestnetChain,
  // SuiWallet,
  SuietWallet,
  SurfWallet,
  WalletProvider,
} from "@suiet/wallet-kit";
import { CookiesProvider } from "react-cookie";
import "@suiet/wallet-kit/style.css";

const poppins = Poppins({
  subsets: ["latin"],
  family: "Poppins",
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  display: "swap",
  preload: true,
});

const dm_sans = DM_Sans({
  subsets: ["latin"],
  family: "DM Sans",
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  display: "swap",
  preload: true,
});

const outfit = Outfit({
  subsets: ["latin"],
  family: "Outfit",
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  display: "swap",
  preload: true,
});

const SupportedChains = [SuiTestnetChain, SuiMainnetChain];

const DefaultWallets = [
  SuietWallet,
  // SuiWallet,
  EthosWallet,
  GlassWallet,
  ElliWallet,
  MartianWallet,
  FrontierWallet,
  MorphisWallet,
  NightlyWallet,
  OneKeyWallet,
  SensuiWallet,
  SurfWallet,
];

export default function App({ Component, pageProps }) {
  const queryClient = new QueryClient();
  return (
    <Provider store={store}>
      <style jsx global>
        {`
          :root {
            --poppins: ${poppins.style.fontFamily};
            --dm_sans: ${dm_sans.style.fontFamily};
            --outfit: ${outfit.style.fontFamily};
          }
        `}
      </style>
      <QueryClientProvider client={queryClient}>
        <>
          {/* <SuiClientProvider networks={networkConfig} defaultNetwork="mainnet"> */}
          <WalletProvider
            chains={SupportedChains}
            defaultWallets={DefaultWallets}
            autoConnect={true}
          >
            <>
              <WagmiProvider config={wagmiConfig}>
                <CookiesProvider>
                  <Component {...pageProps} />
                </CookiesProvider>
              </WagmiProvider>
            </>
          </WalletProvider>
          {/* </SuiClientProvider> */}
        </>
      </QueryClientProvider>
    </Provider>
  );
}
