import { 
  RANDOM_USERNAME ,
  RANDOM_USERNAME_SUCCESS,
  RANDOM_USERNAME_ERROR
} from "./actionTypes";

const initialState = {

  Random: null,
  loadingRandom: false,
  successRandom : false,
  errorRandom : false,
  messageRandom: null
};

const Randomuser = (state = initialState, action) => {
  switch (action.type) {

    //RANDOM_USERNAME
    case RANDOM_USERNAME:
      state = {
        ...state,
        loadingRandom: true,
        successRandom: false,
        errorRandom: false,
      };
      break;
    case RANDOM_USERNAME_SUCCESS:
      state = {
        ...state,
        loadingRandom: false,
        successRandom: true,
        errorRandom: false,
        Random: action.payload,
      };
      break;
    case RANDOM_USERNAME_ERROR:
      state = {
        ...state,
        loadingRandom: false,
        successRandom: false,
        errorRandom: true,
        Random: null,
        messageRandom: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Randomuser;
