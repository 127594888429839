import {
  NFT_DELETE,
  NFT_DELETE_SUCCESS,
  NFT_DELETE_ERROR,
  RESET_NFT_DELETE,
} from "./actionTypes";

const initialState = {
  errorMsg: "",
  loading: false,
  error: false,
  success: null,
  Id: null,
};

const DeleteNft = (state = initialState, action) => {
  switch (action.type) {
    case NFT_DELETE:
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
        errorMsg: null,
      };
      break;
    case NFT_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        errorMsg: false,
        success: true,
        Id: action.payload,
      };
      break;
    case NFT_DELETE_ERROR:
      state = {
        ...state,
        errorMsg: action.payload.data,
        loading: false,
        error: true,
        success: false,
      };
      break;
    case RESET_NFT_DELETE:
      state = {
        ...state,
        errorMsg: "",
        loading: false,
        error: false,
        success: null,
        Id: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DeleteNft;
