import { takeLatest, call, put } from "redux-saga/effects";
import {
  COLLECTED_NFTCARD_REQUEST,
  COLLECTED_NFTCARD_SUCCESS,
  COLLECTED_NFTCARD_FAILURE,
} from "./actionTypes";
import { CollectedNftCardSuccess, CollectedNftCardFailure } from "./action";
import { getNftCards } from "@/config/helpers/backend_helper";

function* CollectedNftCard(action) {
  try {
    //   console.log(action.payload);
    const response = yield call(getNftCards, action.payload);
    //  console.log(response);
    yield put(CollectedNftCardSuccess(response));
  } catch (error) {
    yield put(CollectedNftCardFailure(error));
  }
}

function* CollectedNftCardSaga() {
  yield takeLatest(COLLECTED_NFTCARD_REQUEST, CollectedNftCard);
}

export default CollectedNftCardSaga;
