import {
  TOGGLE_FAVORITES,
  TOGGLE_FAVORITES_SUCCESS,
  TOGGLE_FAVORITES_ERROR,
  TOGGLE_FAVORITES_COLLECTIONS,
  TOGGLE_FAVORITES_COLLECTIONS_SUCCESS,
  TOGGLE_FAVORITES_COLLECTIONS_ERROR,
} from "./actionTypes";

//toggleFavorites
export const toggleFavoritesData = (id) => {
  return {
    type: TOGGLE_FAVORITES,
    payload: { id },
  };
};

export const toggleFavoritesDataSuccess = (data) => {
  return {
    type: TOGGLE_FAVORITES_SUCCESS,
    payload: data,
  };
};

export const toggleFavoritesDataError = (data) => {
  return {
    type: TOGGLE_FAVORITES_ERROR,
    payload: data,
  };
};

//toggleFavoritesCollections
export const FavoriteCollectionData = (id) => {
  return {
    type: TOGGLE_FAVORITES_COLLECTIONS,
    payload: { id },
  };
};

export const FavoriteCollectionSuccess = (data) => {
  return {
    type: TOGGLE_FAVORITES_COLLECTIONS_SUCCESS,
    payload: data,
  };
};

export const FavoriteCollectionError = (data) => {
  return {
    type: TOGGLE_FAVORITES_COLLECTIONS_ERROR,
    payload: data,
  };
};
