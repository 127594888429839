import {
  MUSEUM_CALENDAR,
  MUSEUM_CALENDAR_SUCCESS,
  MUSEUM_CALENDAR_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgCalendarNfts: "",
  loadingCalendarNfts: false,
  errorCalendarNfts: false,
  successCalendarNfts: null,
  nfts: [],
};

const MuseumCalendar = (state = initialState, action) => {
  switch (action.type) {
    case MUSEUM_CALENDAR:
      return {
        ...state,
        loadingCalendarNfts: true,
        errorCalendarNfts: false,
        successCalendarNfts: false,
        errorMsgCalendarNfts: null,
      };
    case MUSEUM_CALENDAR_SUCCESS:
      return {
        ...state,
        loadingCalendarNfts: false,
        errorCalendarNfts: false,
        successCalendarNfts: true,
        nfts: action.payload,
        errorMsgCalendarNfts: null,
      };
    case MUSEUM_CALENDAR_ERROR:
      return {
        ...state,
        errorMsgCalendarNfts: action.payload.data,
        loadingCalendarNfts: false,
        errorCalendarNfts: true,
        successCalendarNfts: false,
      };
    default:
      return state;
  }
};

export default MuseumCalendar;
