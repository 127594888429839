import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { getHomeTrendingArtWork } from "@/config/helpers/backend_helper";

import { trendingDataSuccess, trendingDataError } from "./actions";

import { TRENDING_ARTWORKS_HOME } from "./actionTypes";

function* fetchTrendingArtworksData() {
  try {
    const response = yield call(getHomeTrendingArtWork);
    yield put(trendingDataSuccess(response));
  } catch (error) {
    yield put(trendingDataError(error));
  }
}

function* TrendingArtworksSaga() {
  yield takeLatest(TRENDING_ARTWORKS_HOME, fetchTrendingArtworksData);
}

export default TrendingArtworksSaga;
