import {
  EVENTS_DATA,
  EVENTS_DATA_SUCCESS,
  EVENTS_DATA_ERROR,
  RESET_EVENTS_DATA,
} from "./actionTypes";

const initialState = {
  errorMsg: "",
  loading: false,
  error: false,
  success: null,
  events: null,
};

const Events = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS_DATA:
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
        errorMsg: null,
      };
      break;
    case EVENTS_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
        events: action.payload,
        errorMsg: null,
      };
      break;
    case EVENTS_DATA_ERROR:
      state = {
        ...state,
        errorMsg: action.payload,
        loading: false,
        error: true,
        success: false,
      };
      break;
    case RESET_EVENTS_DATA:
      state = {
        ...state,
        errorMsg: "",
        loading: false,
        error: false,
        success: null,
        events: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Events;
