import { call, put, takeEvery } from "redux-saga/effects";
import { getMuseumAuction } from "@/config/helpers/backend_helper";
import { museumAuctionError, museumAuctionSuccess } from "./actions";
import { MUSEUM_AUCTION } from "./actionTypes";

function* fetchMuseumAuction() {
  try {
    //  console.log(action.payload);
    const response = yield call(getMuseumAuction);
    yield put(museumAuctionSuccess(response));
  } catch (error) {
    yield put(museumAuctionError(error));
  }
}

function* museumAuctionSaga() {
  yield takeEvery(MUSEUM_AUCTION, fetchMuseumAuction);
}

export default museumAuctionSaga;
