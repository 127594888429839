// actions.js
import {
  ACCOUNT_REPORT_USER,
  ACCOUNT_REPORT_USER_SUCCESS,
  ACCOUNT_REPORT_USER_ERROR,
  RESET_ACCOUNT_REPORT_USER,
} from "./actionTypes";

export const reportUser = (data) => {
  return {
    type: ACCOUNT_REPORT_USER,
    payload: { data },
  };
};

export const reportUserSuccess = (data) => {
  return {
    type: ACCOUNT_REPORT_USER_SUCCESS,
    payload: data,
  };
};

export const reportUserError = (error) => {
  return {
    type: ACCOUNT_REPORT_USER_ERROR,
    payload: error,
  };
};

export const resetReportUser = () => {
  return {
    type: RESET_ACCOUNT_REPORT_USER,
  };
};
