// actions.js
import {
  HOME_TOP_ARTISTS,
  HOME_TOP_ARTISTS_SUCCESS,
  HOME_TOP_ARTISTS_FAILURE,
  RESET_HOME_TOP_ARTISTS,
} from "./actionTypes";

export const topArtistsRequest = (requestData) => ({
  type: HOME_TOP_ARTISTS,
  payload: requestData,
});

export const topArtistsSuccess = (data) => ({
  type: HOME_TOP_ARTISTS_SUCCESS,
  payload: data,
});

export const topArtistsFailure = (error) => ({
  type: HOME_TOP_ARTISTS_FAILURE,
  payload: error,
});

export const ResetTopArtists = () => ({
  type: RESET_HOME_TOP_ARTISTS,
});
