// actions.js
import {
    NOT_MARK_ALL_READ,
    NOT_MARK_ALL_READ_SUCCESS,
    NOT_MARK_ALL_READ_FAILURE,
  } from "./actionTypes";
  
  export const allNotifsVisited = (requestData) => ({
    type: NOT_MARK_ALL_READ,
    payload: requestData,
  });
  
  export const allNotifsVisitedSuccess = (data) => ({
    type: NOT_MARK_ALL_READ_SUCCESS,
    payload: data,
  });
  
  export const allNotifsVisitedError = (error) => ({
    type: NOT_MARK_ALL_READ_FAILURE,
    payload: error,
  });