// saga.js
import { getCategories } from "@/config/helpers/backend_helper";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getSettingCategoriesError,
  getSettingCategoriesSuccess,
} from "./action";
import { GET_SETTING_CATEGORIES } from "./actionTypes";

function* fetchSetingsCategories() {
  try {
    const response = yield call(getCategories);
    yield put(getSettingCategoriesSuccess(response));
  } catch (error) {
    yield put(getSettingCategoriesError(error));
  }
}

export function* watchSettingsCategorie() {
  yield takeEvery(GET_SETTING_CATEGORIES, fetchSetingsCategories);
}

function* SettingsCategorieSaga() {
  yield all([fork(watchSettingsCategorie)]);
}

export default SettingsCategorieSaga;
