// actions.js
import {
  TESTIMONIAL_HOME,
  TESTIMONIAL_HOME_SUCCESS,
  TESTIMONIAL_HOME_ERROR,
} from "./actionTypes";

export const testimonialData = () => {
  return {
    type: TESTIMONIAL_HOME,
  };
};

export const testimonialDataSuccess = (data) => {
  return {
    type: TESTIMONIAL_HOME_SUCCESS,
    payload: data,
  };
};

export const testimonialDataError = (error) => {
  return {
    type: TESTIMONIAL_HOME_ERROR,
    payload: error,
  };
};
