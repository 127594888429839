import {
  COLLABORATION_NFTCARD_REQUEST,
  COLLABORATION_NFTCARD_SUCCESS,
  COLLABORATION_NFTCARD_FAILURE,
  RESET_COLLABORATION_NFTCARD_FAILURE
} from "./actionTypes";

export const CollaborationNftCardRequest = (requestData) => ({
  type: COLLABORATION_NFTCARD_REQUEST,
  payload: requestData,
});

export const CollaborationNftCardSuccess = (data) => ({
  type: COLLABORATION_NFTCARD_SUCCESS,
  payload: data,
});

export const CollaborationNftCardFailure = (error) => ({
  type: COLLABORATION_NFTCARD_FAILURE,
  payload: error,
});

export const ResetCollaborationNftCardFailure = () => ({
  type: RESET_COLLABORATION_NFTCARD_FAILURE,
});
