import {
  GET_MY_PROFILE,
  GET_MY_PROFILE_ERROR,
  GET_MY_PROFILE_SUCCESS,
  RESET_MY_PROFILE,
  RESET_ALL_MY_PROFILE_DATA_FLAG,
} from "./actionTypes";

const initialState = {
  error: null,
  success: null,
  MyProfileUser: [],
  message: null,
  loading: null,
};

const MyProfile = (state = initialState, action) => {
  switch (action.type) {
    //GET_MY_PROFILE
    case GET_MY_PROFILE:
      state = {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
      break;
    case GET_MY_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        error: false,
        MyProfileUser: action.payload,
      };
      break;
    case GET_MY_PROFILE_ERROR:
      state = {
        ...state,
        loading: false,
        success: false,
        error: true,
        MyProfileUser: null,
        message: action.payload,
      };
      break;
    //RESET_FLAGS
    case RESET_MY_PROFILE:
      state = {
        ...state,
        success: null,
        error: null,
        message: null,
        loading: null,
        MyProfileUser: [],
      };
      break;
    case RESET_ALL_MY_PROFILE_DATA_FLAG:
      state = {
        ...state,
        success: null,
        error: null,
        MyProfileUser: [],
        message: null,
        loading: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  // console.log("profile state:", state.user);
  return state;
};

export default MyProfile;
