export const NOTIFICATION_SETTINGS = "NOTIFICATION_SETTINGS";
export const NOTIFICATION_SETTINGS_SUCCESS = "NOTIFICATION_SETTINGS_SUCCESS";
export const NOTIFICATION_SETTINGS_ERROR = "NOTIFICATION_SETTINGS_ERROR";
export const RESET_NOTIFICATION_SETTINGS = "RESET_NOTIFICATION_SETTINGS";


export const UPDATE_NOTIFICATION_SETTINGS = "UPDATE_NOTIFICATION_SETTINGS";
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS =
  "UPDATE_NOTIFICATION_SETTINGS_SUCCESS";
export const UPDATE_NOTIFICATION_SETTINGS_ERROR =
  "UPDATE_NOTIFICATION_SETTINGS_ERROR";
