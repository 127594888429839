import {
  GET_USER_COLLECTION,
  GET_USER_COLLECTION_ERROR,
  GET_USER_COLLECTION_SUCCESS,
  RESET_GET_USER_COLLECTION,
  CREATE_USER_COLLECTION,
  CREATE_USER_COLLECTION_ERROR,
  CREATE_USER_COLLECTION_SUCCESS,
  RESET_CREATE_USER_COLLECTION,
  RESET_GET_USER_COLLECTION2,
} from "./actionTypes";

//getProfile
export const getUserCollection = (data) => {
  return {
    type: GET_USER_COLLECTION,
    payload: { data },
  };
};

export const getUserCollectionSuccess = (data) => {
  return {
    type: GET_USER_COLLECTION_SUCCESS,
    payload: data,
  };
};

export const getUserCollectionError = (error) => {
  return {
    type: GET_USER_COLLECTION_ERROR,
    payload: error,
  };
};
//reset states
export const resetGetUserCollection = () => {
  return {
    type: RESET_GET_USER_COLLECTION,
  };
};

export const resetGetUserCollection2 = () => {
  return {
    type: RESET_GET_USER_COLLECTION2,
  };
};

//CreateUserCollection
export const CreateUserCollection = (form) => {
  return {
    type: CREATE_USER_COLLECTION,
    payload: form,
  };
};

export const CreateUserCollectionSuccess = (data) => {
  return {
    type: CREATE_USER_COLLECTION_SUCCESS,
    payload: data,
  };
};

export const CreateUserCollectionError = (error) => {
  return {
    type: CREATE_USER_COLLECTION_ERROR,
    payload: error.data,
  };
};

//reset states
export const resetCreateUserCollection = () => {
  return {
    type: RESET_CREATE_USER_COLLECTION,
  };
};
