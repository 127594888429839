import {
  //GET_TWITTER_FARM_GET_MY_POINT
  GET_TWITTER_FARM_GET_MY_POINT,
  GET_TWITTER_FARM_GET_MY_POINT_ERROR,
  GET_TWITTER_FARM_GET_MY_POINT_SUCCESS,

  //POST_TWITTER_FARM_MY_ACTIVITIES_ACTION
  POST_TWITTER_FARM_MY_ACTIVITIES_ACTION,
  POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_ERROR,
  POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_SUCCESS,

  //POST_TWITTER_FARM_MY_POINTS
  POST_TWITTER_FARM_MY_POINTS,
  POST_TWITTER_FARM_MY_POINTS_ERROR,
  POST_TWITTER_FARM_MY_POINTS_SUCCESS,

  //POST_TWITTER_FARM_MY_SEED
  POST_TWITTER_FARM_MY_SEED,
  POST_TWITTER_FARM_MY_SEED_ERROR,
  POST_TWITTER_FARM_MY_SEED_SUCCESS,

  //POST_TWITTER_FARM_ALL_USER_POINTS
  POST_TWITTER_FARM_ALL_USER_POINTS,
  POST_TWITTER_FARM_ALL_USER_POINTS_ERROR,
  POST_TWITTER_FARM_ALL_USER_POINTS_SUCCESS,

  //POST_TWITTER_ALL_USER_LIKE_POINTS_V2
  POST_TWITTER_ALL_USER_LIKE_POINTS_V2,
  POST_TWITTER_ALL_USER_LIKE_POINTS_V2_ERROR,
  POST_TWITTER_ALL_USER_LIKE_POINTS_V2_SUCCESS,

  //GET_TWITTER_UPDATE_USERS_LEAGUE
  GET_TWITTER_UPDATE_USERS_LEAGUE,
  GET_TWITTER_UPDATE_USERS_LEAGUE_SUCCESS,
  GET_TWITTER_UPDATE_USERS_LEAGUE_ERROR,

  //GET_TWITTER_SETTING
  GET_TWITTER_SETTING,
  GET_TWITTER_SETTING_SUCCESS,
  GET_TWITTER_SETTING_ERROR,
  RESET_ALL_TWITTER_FARM_POINTS_FLAG,
} from "./actionTypes";

//GET_TWITTER_SETTING
export const getTwitterSetting = () => {
  return {
    type: GET_TWITTER_SETTING,
  };
};

export const getTwitterSettingSuccess = (data) => {
  return {
    type: GET_TWITTER_SETTING_SUCCESS,
    payload: data,
  };
};

export const getTwitterSettingError = (data) => {
  return {
    type: GET_TWITTER_SETTING_ERROR,
    payload: data,
  };
};

//getTwitterMyPoints
export const getTwitterMyPoint = () => {
  return {
    type: GET_TWITTER_FARM_GET_MY_POINT,
  };
};

export const getTwitterMyPointSuccess = (data) => {
  return {
    type: GET_TWITTER_FARM_GET_MY_POINT_SUCCESS,
    payload: data,
  };
};

export const getTwitterMyPointError = (data) => {
  return {
    type: GET_TWITTER_FARM_GET_MY_POINT_ERROR,
    payload: data,
  };
};

//postTwitterMyActivitiesPoints
export const postTwitterMyActivitiesPoints = (data) => {
  return {
    type: POST_TWITTER_FARM_MY_ACTIVITIES_ACTION,
    payload: data,
  };
};

export const postTwitterMyActivitiesPointsSuccess = (data) => {
  return {
    type: POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_SUCCESS,
    payload: data,
  };
};

export const postTwitterMyActivitiesPointsError = (data) => {
  return {
    type: POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_ERROR,
    payload: data,
  };
};

//postTwitterMyPoints
export const postTwitterMyPoint = (data) => {
  return {
    type: POST_TWITTER_FARM_MY_POINTS,
    payload: data,
  };
};

export const postTwitterMyPointSuccess = (data) => {
  return {
    type: POST_TWITTER_FARM_MY_POINTS_SUCCESS,
    payload: data,
  };
};

export const postTwitterMyPointError = (error) => {
  return {
    type: POST_TWITTER_FARM_MY_POINTS_ERROR,
    payload: error,
  };
};

//postTwitterMySeed
export const postTwitterMySeedActivities = (data) => {
  return {
    type: POST_TWITTER_FARM_MY_SEED,
    payload: data,
  };
};

export const postTwitterMySeedActivitiesSuccess = (data) => {
  return {
    type: POST_TWITTER_FARM_MY_SEED_SUCCESS,
    payload: data,
  };
};

export const postTwitterMySeedActivitiesError = (error) => {
  return {
    type: POST_TWITTER_FARM_MY_SEED_ERROR,
    payload: error,
  };
};

//postTwitterAllUserPoint
export const postTwitterAllUserPoint = (data) => {
  return {
    type: POST_TWITTER_FARM_ALL_USER_POINTS,
    payload: data,
  };
};

export const postTwitterAllUserPointSuccess = (data) => {
  return {
    type: POST_TWITTER_FARM_ALL_USER_POINTS_SUCCESS,
    payload: data,
  };
};

export const postTwitterAllUserPointError = (data) => {
  return {
    type: POST_TWITTER_FARM_ALL_USER_POINTS_ERROR,
    payload: data,
  };
};

//postTwitterAllUserLikePointV2
export const postTwitterAllUserLikePointV2 = (data) => {
  return {
    type: POST_TWITTER_ALL_USER_LIKE_POINTS_V2,
    payload: data,
  };
};

export const postTwitterAllUserLikePointV2Success = (data) => {
  return {
    type: POST_TWITTER_ALL_USER_LIKE_POINTS_V2_SUCCESS,
    payload: data,
  };
};

export const postTwitterAllUserLikePointV2Error = (data) => {
  return {
    type: POST_TWITTER_ALL_USER_LIKE_POINTS_V2_ERROR,
    payload: data,
  };
};

//getTwitterUpdateUsersLeague
export const getTwitterUpdateUsersLeague = () => {
  return {
    type: GET_TWITTER_UPDATE_USERS_LEAGUE,
  };
};

export const getTwitterUpdateUsersLeagueSuccess = (data) => {
  return {
    type: GET_TWITTER_UPDATE_USERS_LEAGUE_SUCCESS,
    payload: data,
  };
};

export const getTwitterUpdateUsersLeagueError = (data) => {
  return {
    type: GET_TWITTER_UPDATE_USERS_LEAGUE_ERROR,
    payload: data,
  };
};

//resetAllTwitterFarmActionFlag
export const resetAllTwitterFarmPointsFlag = () => {
  return {
    type: RESET_ALL_TWITTER_FARM_POINTS_FLAG,
  };
};
