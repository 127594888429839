import { call, put, takeEvery } from "redux-saga/effects";
import { getMuseumUpcomings } from "@/config/helpers/backend_helper";
import { museumUpcomingDataSuccess, museumUpcomingDataError } from "./actions";
import { MUSEUM_UPCOMING } from "./actionTypes";

function* fetchMuseumUpcomingData() {
  try {
    const response = yield call(getMuseumUpcomings);
    yield put(museumUpcomingDataSuccess(response));
  } catch (error) {
    yield put(museumUpcomingDataError(error));
  }
}

function* museumUpcomingSaga() {
  yield takeEvery(MUSEUM_UPCOMING, fetchMuseumUpcomingData);
}

export default museumUpcomingSaga;
