import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getHomeHotBids } from "@/config/helpers/backend_helper";
import { hotBidsDataSuccess, hotBidsDataError } from "./actions";
import { HOT_BIDS_HOME } from "./actionTypes";

function* fetchHotBidsData() {
  try {
    const response = yield call(getHomeHotBids);
    yield put(hotBidsDataSuccess(response));
  } catch (error) {
    yield put(hotBidsDataError(error));
  }
}

function* hotBidsSaga() {
  yield takeLatest(HOT_BIDS_HOME, fetchHotBidsData);
}

export default hotBidsSaga;
