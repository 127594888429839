import { takeLatest, call, put } from "redux-saga/effects";
import {
  ALL_NFTCARD_REQUEST,
  ALL_NFTCARD_SUCCESS,
  ALL_NFTCARD_FAILURE,
} from "./actionTypes";
import { AllNftCardSuccess, AllNftCardFailure } from "./action";
import { getNftCards } from "@/config/helpers/backend_helper";

function* AllNftCard(action) {
  try {
    //   console.log(action.payload);
    const response = yield call(getNftCards, action.payload);
    //  console.log(response);
    yield put(AllNftCardSuccess(response));
  } catch (error) {
    yield put(AllNftCardFailure(error));
  }
}

function* AllNftCardSaga() {
  yield takeLatest(ALL_NFTCARD_REQUEST, AllNftCard);
}

export default AllNftCardSaga;
