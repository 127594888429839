import {
  ASSET_PORTFOLIO,
  ASSET_PORTFOLIO_SUCCESS,
  ASSET_PORTFOLIO_ERROR,
  RESET_ASSET_PORTFOLIO_DATA,
} from "./actionTypes";

export const assetPortfolioRequest = (data) => {
  return {
    type: ASSET_PORTFOLIO,
    payload: { data },
  };
};

export const assetPortfolioSuccess = (data) => {
  return {
    type: ASSET_PORTFOLIO_SUCCESS,
    payload: data,
  };
};

export const assetPortfolioError = (data) => {
  return {
    type: ASSET_PORTFOLIO_ERROR,
    payload: data,
  };
};

export const resetAssetPortfolio = () => {
  return {
    type: RESET_ASSET_PORTFOLIO_DATA,
  };
};
