import {
  EDIT_GALLARY,
  EDIT_GALLARY_ERROR,
  EDIT_GALLARY_SUCCESS,
  RESET_EDIT_GALLARY,
  RESET_ALL_GALLARY_DATA,
} from "./actionTypes";

const initialState = {
  errorgallary: null,
  successGallary: null,
  Gallary: null,
  messagegallary: null,
  loadinggallary: null,
};

const editGallaryCover = (state = initialState, action) => {
  switch (action.type) {
    //EDIT_GALLARY
    case EDIT_GALLARY:
      state = {
        ...state,
        loadinggallary: true,
        successGallary: false,
        errorgallary: false,
      };
      break;
    case EDIT_GALLARY_SUCCESS:
      state = {
        ...state,
        loadinggallary: false,
        successGallary: true,
        errorgallary: false,
        Gallary: action.payload,
      };
      break;
    case EDIT_GALLARY_ERROR:
      state = {
        ...state,
        loadinggallary: false,
        successGallary: false,
        errorgallary: true,
        Gallary: null,
        messagegallary: action.payload.error,
      };
      break;
    //RESET_FLAGS
    case RESET_EDIT_GALLARY:
      state = {
        ...state,
        errorgallary: null,
        successGallary: null,
        messagegallary: null,
        loadinggallary: null,
      };
      break;
    case RESET_ALL_GALLARY_DATA:
      state = {
        ...state,
        errorgallary: null,
        successGallary: null,
        messagegallary: null,
        loadinggallary: null,
        Gallary: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default editGallaryCover;
