import {
  HOME_MARKET_PLACE_INFO,
  HOME_MARKET_PLACE_INFO_SUCCESS,
  HOME_MARKET_PLACE_INFO_ERROR,
  RESET_HOME_MARKET_PLACE_INFO,
} from "./actionTypes";

//HotCollections data
export const homeMarketPlaceInfo = () => {
  return {
    type: HOME_MARKET_PLACE_INFO,
  };
};

export const homeMarketPlaceInfoSuccess = (data) => {
  return {
    type: HOME_MARKET_PLACE_INFO_SUCCESS,
    payload: data,
  };
};

export const homeMarketPlaceInfoError = (data) => {
  return {
    type: HOME_MARKET_PLACE_INFO_ERROR,
    payload: data,
  };
};

//reset states
export const resetHomeMarketPlaceInfo = () => {
  return {
    type: RESET_HOME_MARKET_PLACE_INFO,
  };
};
