import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getHomeCommunities } from "@/config/helpers/backend_helper";
import { communitiesDataSuccess, communitiesDataError } from "./actions";
import { COMMUNITIES_HOME } from "./actionTypes";

function* fetchCommunitiesData() {
  try {
    const response = yield call(getHomeCommunities);

    yield put(communitiesDataSuccess(response));
  } catch (error) {
    yield put(communitiesDataError(error));
  }
}

function* communitiesSaga() {
  yield takeLatest(COMMUNITIES_HOME, fetchCommunitiesData);
}

export default communitiesSaga;
