// saga.js

import { postGeneralSearch } from "@/config/helpers/backend_helper";
import { call, put, takeLatest } from "redux-saga/effects";
import { generalSearchFailure, generalSearchSuccess } from "./action";
import { GENERAL_SEARCH } from "./actionTypes";

function* GeneralSearch({ payload: { GeneralSearch } }) {
  try {
    const response = yield call(postGeneralSearch, GeneralSearch);
    yield put(generalSearchSuccess(response));
  } catch (error) {
    yield put(generalSearchFailure(error.response.data.Errors[0].Message));
  }
}

function* GeneralSearchSaga() {
  yield takeLatest(GENERAL_SEARCH, GeneralSearch);
}

export default GeneralSearchSaga;
