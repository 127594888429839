import {
  ASSET_PORTFOLIO,
  ASSET_PORTFOLIO_SUCCESS,
  ASSET_PORTFOLIO_ERROR,
  RESET_ASSET_PORTFOLIO_DATA,
} from "./actionTypes";

const initialState = {
  errorMsgAssetPortfolio: "",
  loadingAssetPortfolio: false,
  errorAssetPortfolio: false,
  successAssetPortfolio: null,
  assetData: [],
};

const AssetPortfolio = (state = initialState, action) => {
  switch (action.type) {
    case ASSET_PORTFOLIO:
      state = {
        ...state,
        loadingAssetPortfolio: true,
        errorAssetPortfolio: false,
        successAssetPortfolio: false,
        errorMsgAssetPortfolio: null,
      };
      break;
    case ASSET_PORTFOLIO_SUCCESS:
      state = {
        ...state,
        loadingAssetPortfolio: false,
        errorAssetPortfolio: false,
        successAssetPortfolio: true,
        assetData: action.payload,
        errorMsgAssetPortfolio: null,
      };
      break;
    case ASSET_PORTFOLIO_ERROR:
      state = {
        ...state,
        errorMsgAssetPortfolio: action.payload.data,
        loadingAssetPortfolio: false,
        errorAssetPortfolio: true,
        successAssetPortfolio: false,
      };
      break;
    case RESET_ASSET_PORTFOLIO_DATA:
      state = {
        ...state,
        errorMsgAssetPortfolio: null,
        loadingAssetPortfolio: false,
        errorAssetPortfolio: false,
        successAssetPortfolio: false,
        assetData: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AssetPortfolio;
