import { call, put, takeEvery } from "redux-saga/effects";

import { postAccountVerificationForm } from "@/config/helpers/backend_helper";

import { verificationFormSuccess, verificationFormError } from "./action";

import { VERIFICATION_FORM } from "./actionTypes";

function* fetchVerificationFormData({ payload: { form } }) {
  try {
    const response = yield call(postAccountVerificationForm, form);
    yield put(verificationFormSuccess(response));
  } catch (error) {
    yield put(verificationFormError(error.response.data.Errors[0].Message));
  }
}

function* VerificationFormSaga() {
  yield takeEvery(VERIFICATION_FORM, fetchVerificationFormData);
}

export default VerificationFormSaga;
