import {
  HOT_COLLECTIONS_HOME,
  HOT_COLLECTIONS_HOME_SUCCESS,
  HOT_COLLECTIONS_HOME_ERROR,
  RESET_HOT_COLLECTIONS_HOME,
} from "./actionTypes";

//HotCollections data
export const HotData = () => {
  return {
    type: HOT_COLLECTIONS_HOME,
  };
};

export const HotDataSuccess = (data) => {
  return {
    type: HOT_COLLECTIONS_HOME_SUCCESS,
    payload: data,
  };
};

export const HotDataError = (data) => {
  return {
    type: HOT_COLLECTIONS_HOME_ERROR,
    payload: data,
  };
};

//reset states
export const resetHotCollection = () => {
  return {
    type: RESET_HOT_COLLECTIONS_HOME,
  };
};
