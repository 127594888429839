// actions.js DOWNLOAD_CERTIFICATE
import {
  DOWNLOAD_CERTIFICATE,
  DOWNLOAD_CERTIFICATE_SUCCESS,
  DOWNLOAD_CERTIFICATE_FAILURE,
  RESET_DOWNLOAD_CERTIFICATE,
} from "./actionTypes";

export const downloadCertificate = (Id) => ({
  type: DOWNLOAD_CERTIFICATE,
  payload: { Id },
});

export const downloadCertificateSuccess = (dataUrl) => ({
  type: DOWNLOAD_CERTIFICATE_SUCCESS,
  payload: dataUrl,
});

export const downloadCertificateFailure = (error) => ({
  type: DOWNLOAD_CERTIFICATE_FAILURE,
  payload: error,
});

export const resetDownloadCertificate = () => ({
  type: RESET_DOWNLOAD_CERTIFICATE,
});
