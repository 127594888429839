// actions.js
import {
  NFT_DELETE,
  NFT_DELETE_SUCCESS,
  NFT_DELETE_ERROR,
  RESET_NFT_DELETE,
} from "./actionTypes";

export const deleteNft = (Id) => {
  return {
    type: NFT_DELETE,
    payload: { Id },
  };
};

export const deleteNftSuccess = (data) => {
  return {
    type: NFT_DELETE_SUCCESS,
    payload: data,
  };
};

export const deleteNftError = (error) => {
  return {
    type: NFT_DELETE_ERROR,
    payload: error,
  };
};

export const resetDeleteNft = () => {
  return {
    type: RESET_NFT_DELETE,
  };
};
