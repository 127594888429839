// actions.js
import {
  NFTS_NFT_CID,
  NFTS_NFT_CID_SUCCESS,
  NFTS_NFT_CID_ERROR,
  NFTS_LOG_MINT_NFT,
  NFTS_LOG_MINT_NFT_SUCCESS,
  NFTS_LOG_MINT_NFT_ERROR,
  REST_MINT_STATES,
} from "./actionTypes";

//nftsNftCid
export const nftsNftCid = (data) => {
  return {
    type: NFTS_NFT_CID,
    payload: { data },
  };
};

export const nftsNftCidSuccess = (data) => {
  return {
    type: NFTS_NFT_CID_SUCCESS,
    payload: { data },
  };
};

export const nftsNftCidError = (error) => {
  return {
    type: NFTS_NFT_CID_ERROR,
    payload: error,
  };
};

//nftsLogMintNft
export const nftsLogMintNft = (data) => {
  return {
    type: NFTS_LOG_MINT_NFT,
    payload: { data },
  };
};

export const nftsLogMintNftSuccess = (data) => {
  return {
    type: NFTS_LOG_MINT_NFT_SUCCESS,
    payload: { data },
  };
};

export const nftsLogMintNftError = (error) => {
  return {
    type: NFTS_LOG_MINT_NFT_ERROR,
    payload: error,
  };
};

export const resetMintState = () => {
  return {
    type: REST_MINT_STATES,
  };
};
