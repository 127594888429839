// actions.js
import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
} from "./actionTypes";

export const getSettings = () => ({
  type: GET_SETTINGS,
});

export const getSettingsSuccess = (data) => ({
  type: GET_SETTINGS_SUCCESS,
  payload: data,
});

export const getSettingsError = (error) => ({
  type: GET_SETTINGS_ERROR,
  payload: error,
});
