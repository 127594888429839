// actions.js
import {
  HOT_BIDS_HOME,
  HOT_BIDS_HOME_SUCCESS,
  HOT_BIDS_HOME_ERROR,
  RESET_HOT_BIDS_HOME,
} from "./actionTypes";

export const hotBidsData = () => {
  return {
    type: HOT_BIDS_HOME,
  };
};

export const hotBidsDataSuccess = (data) => {
  return {
    type: HOT_BIDS_HOME_SUCCESS,
    payload: data,
  };
};

export const hotBidsDataError = (error) => {
  return {
    type: HOT_BIDS_HOME_ERROR,
    payload: error,
  };
};
export const ResetHotBids = () => {
  return {
    type: RESET_HOT_BIDS_HOME,
  };
};
