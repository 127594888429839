// saga.js
import { call, put, takeEvery } from "redux-saga/effects";
import { postAccountReportUser } from "@/config/helpers/backend_helper";
import { reportUserError, reportUserSuccess } from "./action";
import { ACCOUNT_REPORT_USER } from "./actionTypes";

function* fetchReportUser({ payload: { data } }) {
  try {
    const response = yield call(postAccountReportUser, data);
    yield put(reportUserSuccess(response));
  } catch (error) {
    yield put(reportUserError(error));
  }
}

function* ReportUserSaga() {
  yield takeEvery(ACCOUNT_REPORT_USER, fetchReportUser);
}

export default ReportUserSaga;
