// @flow
import { all, call, fork, takeEvery, takeLatest } from "redux-saga/effects";

import { CHANGE_LAYOUT_MODE, CHANGE_PRELOADER } from "./actionType";

/**
 * Changes the body attribute
 */
function changeHTMLAttribute(attribute, value) {
  if (document.documentElement)
    document.documentElement.setAttribute(attribute, value);
  return true;
}

/**
 * Changes the layout mode
 * @param {*} param0
 */
function* changeLayoutMode({ payload: mode }) {
  try {
    yield call(changeHTMLAttribute, "data-layout-mode", mode);
  } catch (error) {
    // console.log(error);
  }
}

/**
 * Changes the Preloader
 * @param {*} param0
 */
function* changePreloader({ payload: preloaderTypes }) {
  try {
    yield call(changeHTMLAttribute, "data-preloader", preloaderTypes);
  } catch (error) {
    // console.log(error);
  }
}

/**
 * Watchers
 */
export function* watchChangeLayoutMode() {
  yield takeLatest(CHANGE_LAYOUT_MODE, changeLayoutMode);
}

export function* watchchangePreloader() {
  yield takeLatest(CHANGE_PRELOADER, changePreloader);
}

function* LayoutSaga() {
  yield all([fork(watchChangeLayoutMode), fork(watchchangePreloader)]);
}

export default LayoutSaga;
