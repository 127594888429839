// actions.js
import {
  GET_NFT_HISTORY,
  GET_NFT_HISTORY_SUCCESS,
  GET_NFT_HISTORY_ERROR,
  RESRT_NFT_HISTORY,
} from "./actionTypes";

export const getNftHistory = (data) => ({
  type: GET_NFT_HISTORY,
  payload: { data },
});

export const getNftHistorySuccess = (data) => ({
  type: GET_NFT_HISTORY_SUCCESS,
  payload: data,
});

export const getNftHistoryError = (error) => ({
  type: GET_NFT_HISTORY_ERROR,
  payload: error,
});

export const resetNftHistoryData = () => ({
  type: RESRT_NFT_HISTORY,
});
