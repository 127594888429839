// saga.js
import { call, put, takeEvery } from "redux-saga/effects";
import {
  getUserCollectionNfts,
  getAccountDeleteCollection,
  postAccountEditCollection,
  getAccountDeleteNftCollection,
  postAccountAddNftCollection,
} from "@/config/helpers/backend_helper";
import {
  userCollectionNftDataSuccess,
  userCollectionNftDataError,
  userCollectionDeleteDataSuccess,
  userCollectionDeleteDataError,
  userCollectionEditDataSuccess,
  userCollectionEditDataError,
  userCollectionDeleteNftDataSuccess,
  userCollectionDeleteNftDataError,
  userAddCollectionNftSuccess,
  userAddCollectionNftError,
} from "./action";
import {
  USER_COLLECTION_NFTS,
  USER_COLLECTION_DELETE,
  USER_COLLECTION_EDIT,
  USER_COLLECTION_DELETE_NFT,
  USER_ADD_NFT_COLLECTION,
} from "./actionTypes";

function* fetchUserCollectionNftData(action) {
  try {
    const collectionId = action.payload;

    const response = yield call(getUserCollectionNfts, collectionId);

    yield put(userCollectionNftDataSuccess(response));
  } catch (error) {
    yield put(
      userCollectionNftDataError(error.response.data.Errors[0].Message)
    );
  }
}

function* fetchUserCollectionDeleteData(action) {
  try {
    const collectionId = action.payload;

    const response = yield call(getAccountDeleteCollection, collectionId);

    yield put(userCollectionDeleteDataSuccess(response));
  } catch (error) {
    yield put(
      userCollectionDeleteDataError(error.response.data.Errors[0].Message)
    );
  }
}

function* fetchUserCollectionEditData(action) {
  try {
    const FormData = action.payload;
    const response = yield call(postAccountEditCollection, FormData);
    yield put(userCollectionEditDataSuccess(response));
  } catch (error) {
    yield put(
      userCollectionEditDataError(error.response.data.Errors[0].Message)
    );
  }
}
function* fetchUserCollectionDeleteNftData(action) {
  try {
    const nftId = action.payload;

    const response = yield call(getAccountDeleteNftCollection, nftId);

    yield put(userCollectionDeleteNftDataSuccess(response));
  } catch (error) {
    yield put(
      userCollectionDeleteNftDataError(error.response.data.Errors[0].Message)
    );
  }
}

//Add Nft to Collection
function* fetchAddNftToCollection(action) {
  try {
    const response = yield call(postAccountAddNftCollection, action.payload);

    yield put(userAddCollectionNftSuccess(response));
  } catch (error) {
    yield put(userAddCollectionNftError(error.response.data.Errors[0].Message));
  }
}

function* UserCollectionNftSaga() {
  yield takeEvery(USER_COLLECTION_NFTS, fetchUserCollectionNftData);
  yield takeEvery(USER_COLLECTION_DELETE, fetchUserCollectionDeleteData);
  yield takeEvery(USER_COLLECTION_EDIT, fetchUserCollectionEditData);
  yield takeEvery(USER_COLLECTION_DELETE_NFT, fetchUserCollectionDeleteNftData);
  yield takeEvery(USER_ADD_NFT_COLLECTION, fetchAddNftToCollection);
}

export default UserCollectionNftSaga;
