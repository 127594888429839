import { takeEvery, put, call } from "redux-saga/effects";

import { GET_USER_COLLECTION, CREATE_USER_COLLECTION } from "./actionTypes";
import {
  getUserCollectionSuccess,
  getUserCollectionError,
  CreateUserCollectionError,
  CreateUserCollectionSuccess,
} from "./actions";
import {
  getAccountUserCollections,
  postAccountCreateCollection,
} from "@/config/helpers/backend_helper";

function* getUserCollection() {
  try {
    const response = yield call(getAccountUserCollections);
    console.log("editProfile", response);
    yield put(getUserCollectionSuccess(response));
  } catch (error) {
    yield put(getUserCollectionError(error));
    console.log("userCollection" + error);
    console.log("elitedays");
  }
}
//postAccountCreateCollection
function* CreateUserCollection(action) {
  try {
    const FormData = action.payload;
    const response = yield call(postAccountCreateCollection, FormData);
    yield put(CreateUserCollectionSuccess(response));
  } catch (error) {
    yield put(CreateUserCollectionError(error.response.data.Errors[0].Message));
  }
}

export function* GetUserCollectionSaga() {
  yield takeEvery(GET_USER_COLLECTION, getUserCollection);
  yield takeEvery(CREATE_USER_COLLECTION, CreateUserCollection);
}

export default GetUserCollectionSaga;
