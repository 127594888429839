import { call, put, takeLatest } from "redux-saga/effects";
import { eventsDataSuccess, eventsDataError } from "./actions";
import { EVENTS_DATA } from "./actionTypes";
import { stage } from "@/config/helpers/web3Utiles";

async function handleFetchDataEvent() {
  const data = await fetch("https://blog.seed.photo/wp-json/wp/v2/seed-events")
    .then(async (res) => {
      const getData = await res.json();
      return getData;
    })
    .catch((err) => console.log(err));

  // if (stage === "stage1") {
  //   console.log("data", data);
  // }

  return data;
}

function* fetchEventsData() {
  try {
    const response = yield call(handleFetchDataEvent);
    yield put(eventsDataSuccess(response));
  } catch (error) {
    yield put(eventsDataError(error));
  }
}

function* EventsSaga() {
  yield takeLatest(EVENTS_DATA, fetchEventsData);
}

export default EventsSaga;
