// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import {
  INSPIRE_REQUEST,
  INSPIRE_SUCCESS,
  INSPIRE_FAILURE,
} from "./actionTypes";
import { inspireSuccess, inspireFailure } from "./action";
import { getNftsInspire } from "@/config/helpers/backend_helper";

function* Inspire(action) {
  try {
    // console.log(action.payload);
    const response = yield call(getNftsInspire, action.payload);
    console.log(response);
    yield put(inspireSuccess(response));
  } catch (error) {
    yield put(inspireFailure(error));
  }
}

function* InspireSaga() {
  yield takeLatest(INSPIRE_REQUEST, Inspire);
}

export default InspireSaga;
