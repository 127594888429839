import {
  CREATED_NFTCARD_REQUEST,
  CREATED_NFTCARD_SUCCESS,
  CREATED_NFTCARD_FAILURE,
  RESET_CREATED_NFTCARD_FAILURE
} from "./actionTypes";

const initialState = {
  loadingCreatedNftCard: false,
  successCreatedNftCard: false,
  errorCreatedNftCard: null,
  nftsCreated: [],
};
const CreatedNftCard = (state = initialState, action) => {
  switch (action.type) {
    case CREATED_NFTCARD_REQUEST:
      return {
        ...state,
        loadingCreatedNftCard: true,
        successCreatedNftCard: false,
        errorCreatedNftCard: null,
      };
    case CREATED_NFTCARD_SUCCESS:
      return {
        ...state,
        loadingCreatedNftCard: false,
        successCreatedNftCard: true,
        errorCreatedNftCard: null,
        nftsCreated: action.payload,
      };
    case CREATED_NFTCARD_FAILURE:
      return {
        ...state,
        loadingCreatedNftCard: false,
        successCreatedNftCard: false,
        errorCreatedNftCard: action.payload,
      };
    case RESET_CREATED_NFTCARD_FAILURE:
      return {
        ...state,
        successCreatedNftCard: false,
        nftsCreated: [],
      };
    default:
      return state;
  }
};

export default CreatedNftCard;
