import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { getHomeFeaturedArtists } from "@/config/helpers/backend_helper";

import { featuredDataSuccess, featuredDataError } from "./actions";

import { FEATURED_ARTIST_HOME } from "./actionTypes";

function* fetchFeaturedArtistsData() {
  try {
    const response = yield call(getHomeFeaturedArtists);
    yield put(featuredDataSuccess(response));
  } catch (error) {
    yield put(featuredDataError(error));
  }
}

function* FeaturedArtistsSaga() {
  yield takeLatest(FEATURED_ARTIST_HOME, fetchFeaturedArtistsData);
}

export default FeaturedArtistsSaga;
