//POST_GET_MY_STAKES
export const GET_MY_STAKES = "GET_MY_STAKES";
export const GET_MY_STAKES_SUCCESS = "GET_MY_STAKES_SUCCESS";
export const GET_MY_STAKES_ERROR = "GET_MY_STAKES_ERROR";

//REQUEST_STAKES
export const REQUEST_STAKES = "REQUEST_STAKES";
export const REQUEST_STAKES_SUCCESS = "REQUEST_STAKES_SUCCESS";
export const REQUEST_STAKES_ERROR = "REQUEST_STAKES_ERROR";

//CANCEL_STAKES
export const CANCEL_STAKES = "CANCEL_STAKES";
export const CANCEL_STAKES_SUCCESS = "CANCEL_STAKES";
export const CANCEL_STAKES_ERROR = "CANCEL_STAKES";

//RESET FLAG
export const RESET_STAKES_FLAG = "RESET_STAKES_FLAG";
