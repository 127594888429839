// actions.js
import {
  GET_ENUMS,
  GET_ENUMS_SUCCESS,
  GET_ENUMS_ERROR,
} from "./actionTypes";

export const GetEnums = (Enums) => ({
  type: GET_ENUMS,
  payload: { Enums },
});

export const GetEnumsSuccess = (data) => ({
  type: GET_ENUMS_SUCCESS,
  payload: data,
});

export const GetEnumsError = (error) => ({
  type: GET_ENUMS_ERROR,
  payload: error,
});
