// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import {
  HOME_TOP_ARTISTS,
  HOME_TOP_ARTISTS_SUCCESS,
  HOME_TOP_ARTISTS_FAILURE,
} from "./actionTypes";
import { topArtistsSuccess, topArtistsFailure } from "./action";
import { getHomeTopArtists } from "@/config/helpers/backend_helper";

function* TopArtists(action) {
  try {
    // console.log(action.payload);
    const response = yield call(getHomeTopArtists, action.payload);
    //   console.log(response);
    yield put(topArtistsSuccess(response));
  } catch (error) {
    yield put(topArtistsFailure(error));
  }
}

function* TopArtistsSaga() {
  yield takeLatest(HOME_TOP_ARTISTS, TopArtists);
}

export default TopArtistsSaga;
