import { CHANGE_LAYOUT_MODE, CHANGE_PRELOADER } from "./actionType";

//constants
import { layoutModeTypes, preloaderTypes } from "@/components/constants/layout";

const layoutMode =
  typeof window !== "undefined" && localStorage.getItem("ThemeMode");

const INIT_STATE = {
  layoutModeType: layoutMode ? layoutMode : layoutModeTypes.DARKMODE,
  preloader: preloaderTypes.DISABLE,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutModeType: action.payload,
      };

    case CHANGE_PRELOADER:
      return {
        ...state,
        preloader: action.payload,
      };
    default:
      return state;
  }
};

export default Layout;
