import {
  NEW_LISTING_HOME,
  NEW_LISTING_HOME_SUCCESS,
  NEW_LISTING_HOME_ERROR,
  RESET_NEW_LISTING_HOME,
} from "./actionTypes";

export const NewlistingData = () => {
  return {
    type: NEW_LISTING_HOME,
  };
};

export const NewlistingDataSuccess = (data) => {
  return {
    type: NEW_LISTING_HOME_SUCCESS,
    payload: data,
  };
};

export const NewlistingDataError = (data) => {
  return {
    type: NEW_LISTING_HOME_ERROR,
    payload: data,
  };
};

export const resetNewlistingStates = () => {
  return {
    type: RESET_NEW_LISTING_HOME,
  };
};
