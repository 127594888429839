import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { getHomeHotCollections } from "@/config/helpers/backend_helper";

import { HotDataSuccess, HotDataError } from "./actions";

import { HOT_COLLECTIONS_HOME } from "./actionTypes";

function* fetchHotCollectionsData() {
  try {
    const response = yield call(getHomeHotCollections);
    yield put(HotDataSuccess(response));
  } catch (error) {
    yield put(HotDataError(error));
  }
}

function* HotCollectionsSaga() {
  yield takeLatest(HOT_COLLECTIONS_HOME, fetchHotCollectionsData);
}

export default HotCollectionsSaga;
