import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
} from "redux-saga/effects";

// Login Redux States
import { CHECK_FOLLOW_USER, EDIT_PROFILE, GET_PROFILE } from "./actionTypes";
import {
  getProfileSuccess,
  getProfileError,
  editProfileSuccess,
  editProfileError,
  checkFollowUserSuccess,
  checkFollowUserError,
} from "./actions";
import {
  PostAccountEditProfile,
  getAccountCheckFollowUser,
  getAccountProfile,
} from "@/config/helpers/backend_helper";

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(PostAccountEditProfile, user);
    // console.log("editProfile", response);
    yield put(editProfileSuccess(response));
  } catch (error) {
    yield put(editProfileError(error.response.data.Errors[0].Message));
    // console.log("editProfile1", error.response.data.Errors[0].Message);
  }
}

function* fetchProfile({ payload: { user } }) {
  try {
    const response = yield call(getAccountProfile, user);
    console.log("fetchProfile", response);
    yield put(getProfileSuccess(response));
  } catch (error) {
    yield put(getProfileError(error.response.data.Errors[0].Message));
  }
}

function* fetchCheckFollow({ payload: { Id } }) {
  try {
    const response = yield call(getAccountCheckFollowUser, Id);
    yield put(checkFollowUserSuccess(response));
  } catch (error) {
    yield put(checkFollowUserError(error.response.data.Errors[0].Message));
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeLatest(GET_PROFILE, fetchProfile);
  yield takeLatest(CHECK_FOLLOW_USER, fetchCheckFollow);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
