import {
  NOTIFICATION_SETTINGS,
  NOTIFICATION_SETTINGS_SUCCESS,
  NOTIFICATION_SETTINGS_ERROR,
  RESET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgNotificationSettings: "",
  loadingNotificationSettings: false,
  errorNotificationSettings: false,
  successNotificationSettings: null,
  notifs: [],
};

const NotificationSettings = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_SETTINGS:
      return {
        ...state,
        loadingNotificationSettings: true,
        errorNotificationSettings: false,
        successNotificationSettings: false,
        errorMsgNotificationSettings: null,
      };
    case NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingNotificationSettings: false,
        errorNotificationSettings: false,
        successNotificationSettings: true,
        notifs: action.payload,
        errorMsgNotificationSettings: null,
      };
    case NOTIFICATION_SETTINGS_ERROR:
      return {
        ...state,
        errorMsgNotificationSettings: action.payload.data,
        loadingNotificationSettings: false,
        errorNotificationSettings: true,
        successNotificationSettings: false,
      };
    case RESET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        errorMsgNotificationSettings: "",
        loadingNotificationSettings: false,
        errorNotificationSettings: false,
        successNotificationSettings: null,
        notifs: [],
      };
    case UPDATE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        loadingUpdateNotificationSettings: true,
        errorUpdateNotificationSettings: false,
        successUpdateNotificationSettings: false,
        errorMsgUpdateNotificationSettings: null,
      };
    case UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingUpdateNotificationSettings: false,
        errorUpdateNotificationSettings: false,
        successUpdateNotificationSettings: true,
        errorMsgUpdateNotificationSettings: null,
      };
    case UPDATE_NOTIFICATION_SETTINGS_ERROR:
      return {
        ...state,
        errorMsgUpdateNotificationSettings: action.payload,
        loadingUpdateNotificationSettings: false,
        errorUpdateNotificationSettings: true,
        successUpdateNotificationSettings: false,
      };
    default:
      return state;
  }
};

export default NotificationSettings;
