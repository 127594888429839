import {
  postAccountAddSuiWallet,
  postAccountMergeSuiWallet,
} from "@/config/helpers/backend_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { getMyProfile } from "../myprofile/actions";
import { POST_ADD_SUI_WALLET, POST_MERGE_SUI_WALLET } from "./actionTypes";
import {
  postAddSuiWalletError,
  postAddSuiWalletSuccess,
  postMergeSuiWalletError,
  postMergeSuiWalletSuccess,
} from "./actions";

function* fetchAddSuiWallet({ payload: { data } }) {
  try {
    const response = yield call(postAccountAddSuiWallet, data);
    yield put(postAddSuiWalletSuccess(response));
  } catch (error) {
    yield put(postAddSuiWalletError(error.response.data.Errors[0].Message));
  }
}

function* fetchMergeSuiWallet({ payload: { data } }) {
  try {
    const response = yield call(postAccountMergeSuiWallet, data);
    yield put(postMergeSuiWalletSuccess(response));
    if (response !== null && response !== undefined) {
      yield put(getMyProfile(response.UserName));
    }
  } catch (error) {
    yield put(postMergeSuiWalletError(error.response.data.Errors[0].Message));
  }
}

export function* SuiWalletAccountSaga() {
  yield takeEvery(POST_ADD_SUI_WALLET, fetchAddSuiWallet);
  yield takeEvery(POST_MERGE_SUI_WALLET, fetchMergeSuiWallet);
}

export default SuiWalletAccountSaga;
