// actions.js
import {
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILURE,
  RESET_NOTIFICATION_REQUEST
} from "./actionTypes";

export const sendNotificationRequest = (requestData) => ({
  type: SEND_NOTIFICATION_REQUEST,
  payload: requestData,
});

export const sendNotificationSuccess = (data) => ({
  type: SEND_NOTIFICATION_SUCCESS,
  payload: data,
});

export const sendNotificationFailure = (error) => ({
  type: SEND_NOTIFICATION_FAILURE,
  payload: error,
});

//reset states
export const resetNotificationRequest = () => {
  return {
    type: RESET_NOTIFICATION_REQUEST,
  };
};