import {
  MUSEUM_CURRENTWEEK,
  MUSEUM_CURRENTWEEK_SUCCESS,
  MUSEUM_CURRENTWEEK_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgCurrentWeek: "",
  loadingCurrentWeek: false,
  errorCurrentWeek: false,
  successCurrentWeek: null,
  week: [],
};

const MuseumCurrentWeek = (state = initialState, action) => {
  switch (action.type) {
    //current week
    case MUSEUM_CURRENTWEEK:
      return {
        ...state,
        loadingCurrentWeek: true,
        errorCurrentWeek: false,
        successCurrentWeek: false,
        errorMsgCurrentWeek: null,
      };
    case MUSEUM_CURRENTWEEK_SUCCESS:
      return {
        ...state,
        loadingCurrentWeek: false,
        errorCurrentWeek: false,
        successCurrentWeek: true,
        week: action.payload,
        errorMsgCurrentWeek: null,
      };
    case MUSEUM_CURRENTWEEK_ERROR:
      return {
        ...state,
        errorMsgCurrentWeek: action.payload.data,
        loadingCurrentWeek: false,
        errorCurrentWeek: true,
        successCurrentWeek: false,
      };

    default:
      return state;
  }
};

export default MuseumCurrentWeek;
