import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getHomeMuseum } from "@/config/helpers/backend_helper";
import { MuseumDataSuccess, MuseumDataError } from "./action";
import { MUSEUM_HOME } from "./actionTypes";

function* fetchMuseumData() {
  try {
    const response = yield call(getHomeMuseum);
    yield put(MuseumDataSuccess(response));
  } catch (error) {
    yield put(MuseumDataError(error));
  }
}

function* museumSaga() {
  yield takeLatest(MUSEUM_HOME, fetchMuseumData);
}

export default museumSaga;
