import {
  COLLECTED_NFTCARD_REQUEST,
  COLLECTED_NFTCARD_SUCCESS,
  COLLECTED_NFTCARD_FAILURE,
  RESET_COLLECTED_NFTCARD_FAILURE
} from "./actionTypes";

const initialState = {
  loadingCollectedNftCard: false,
  successCollectedNftCard: false,
  errorCollectedNftCard: null,
  nftsCollected: [],
};
const CollectedNftCard = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTED_NFTCARD_REQUEST:
      return {
        ...state,
        loadingCollectedNftCard: true,
        successCollectedNftCard: false,
        errorCollectedNftCard: null,
      };
    case COLLECTED_NFTCARD_SUCCESS:
      return {
        ...state,
        loadingCollectedNftCard: false,
        successCollectedNftCard: true,
        errorCollectedNftCard: null,
        nftsCollected: action.payload,
      };
    case COLLECTED_NFTCARD_FAILURE:
      return {
        ...state,
        loadingCollectedNftCard: false,
        successCollectedNftCard: false,
        errorCollectedNftCard: action.payload,
      };
    case RESET_COLLECTED_NFTCARD_FAILURE:
      return {
        ...state,
        successCollectedNftCard: false,
        nftsCollected: [],
      };
    default:
      return state;
  }
};

export default CollectedNftCard;
