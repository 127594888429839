import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//
import { EDIT_GALLARY } from "./actionTypes";
import { editGallarySuccess, editGallaryError } from "./actions";
import { getUserGallary } from "@/config/helpers/backend_helper";

function* EditCover({ payload: { coverType } }) {
  try {
    const response = yield call(getUserGallary, coverType);
    // console.log("Gallary", response);
    yield put(editGallarySuccess(response));
  } catch (error) {
    yield put(editGallaryError(error));
  }
}

export function* watcheditGallary() {
  // yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(EDIT_GALLARY, EditCover);
}

function* EditGallarySaga() {
  yield all([fork(watcheditGallary)]);
}

export default EditGallarySaga;
