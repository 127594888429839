import { call, put, takeEvery } from "redux-saga/effects";
import { getHomeReferralDashboard } from "@/config/helpers/backend_helper";
import {
  referralDashboardDataSuccess,
  referralDashboardDataError,
} from "./action";
import { REFERRAL_DASHBOARD_ARTIST } from "./actionTypes";

function* fetchReferralDashboardData() {
  try {
    const response = yield call(getHomeReferralDashboard);

    yield put(referralDashboardDataSuccess(response));
  } catch (error) {
    yield put(referralDashboardDataError(error));
  }
}

function* referralDashboardSaga() {
  yield takeEvery(REFERRAL_DASHBOARD_ARTIST, fetchReferralDashboardData);
}

export default referralDashboardSaga;
