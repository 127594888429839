import {
  FEATURED_ARTIST_HOME,
  FEATURED_ARTIST_HOME_SUCCESS,
  FEATURED_ARTIST_HOME_ERROR,
  RESET_FEATURED_ARTIST_HOME,
} from "./actionTypes";

const initialState = {
  errorMsgFeaturedNfts: "",
  loadingFeaturedNfts: false,
  errorFeaturedNfts: false,
  successFeaturedNfts: null,
  nfts: null,
};

const FeaturedArtworks = (state = initialState, action) => {
  switch (action.type) {
    //FEATURED_ARTIST_HOME
    case FEATURED_ARTIST_HOME:
      state = {
        ...state,
        loadingFeaturedNfts: true,
        errorFeaturedNfts: false,
        successFeaturedNfts: false,
        errorMsgFeaturedNfts: null,
      };
      break;
    case FEATURED_ARTIST_HOME_SUCCESS:
      state = {
        ...state,
        loadingFeaturedNfts: false,
        errorMsgFeaturedNfts: false,
        successFeaturedNfts: true,
        nfts: action.payload,
      };
      break;
    case FEATURED_ARTIST_HOME_ERROR:
      state = {
        ...state,
        errorMsgFeaturedNfts: action.payload.data,
        loadingFeaturedNfts: false,
        errorFeaturedNfts: true,
        successFeaturedNfts: false,
      };
      break;
    case RESET_FEATURED_ARTIST_HOME:
      state = {
        ...state,
        errorMsgFeaturedNfts: null,
        loadingFeaturedNfts: false,
        errorFeaturedNfts: false,
        successFeaturedNfts: null,
        nfts: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default FeaturedArtworks;
