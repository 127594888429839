// actions.js
import {
  EDIT_NFT,
  EDIT_NFT_SUCCESS,
  EDIT_NFT_ERROR,
  RESET_EDIT_NFT,
} from "./actionTypes";

export const EditNft = (form) => {
  return {
    type: EDIT_NFT,
    payload: form,
  };
};

export const EditNftSuccess = (data) => {
  return {
    type: EDIT_NFT_SUCCESS,
    payload: data,
  };
};

export const EditNftError = (error) => {
  return {
    type: EDIT_NFT_ERROR,
    payload: error,
  };
};

export const ResetEditNft = () => {
  return {
    type: RESET_EDIT_NFT,
  };
};
