import {
  NFTS_NFT_CID,
  NFTS_NFT_CID_SUCCESS,
  NFTS_NFT_CID_ERROR,
  NFTS_LOG_MINT_NFT,
  NFTS_LOG_MINT_NFT_SUCCESS,
  NFTS_LOG_MINT_NFT_ERROR,
  REST_MINT_STATES,
} from "./actionTypes";

const initialState = {
  errorMsgMint: "",
  loadingMint: false,
  errorMint: false,
  successMint: null,
  mint: null,
};

const Web3Action = (state = initialState, action) => {
  switch (action.type) {
    //NFTS_NFT_CID
    case NFTS_NFT_CID:
      state = {
        ...state,
        loadingMint: true,
        errorMsgMint: false,
        successMint: false,
        errorMint: null,
      };
      break;
    case NFTS_NFT_CID_SUCCESS:
      state = {
        ...state,
        loadingMint: false,
        errorMint: false,
        successMint: true,
        mint: action.payload,
      };
      break;
    case NFTS_NFT_CID_ERROR:
      state = {
        ...state,
        errorMint: action.payload,
        loadingMint: false,
        errorMsgMint: true,
        successMint: false,
      };
      break;

    //NFTS_LOG_MINT_NFT
    case NFTS_LOG_MINT_NFT:
      state = {
        ...state,
        loadingMint: true,
        errorMsgMint: false,
        successMint: false,
        errorMint: null,
      };
      break;
    case NFTS_LOG_MINT_NFT_SUCCESS:
      state = {
        ...state,
        loadingMint: false,
        errorMint: false,
        successMint: true,
        mint: action.payload,
      };
      break;
    case NFTS_LOG_MINT_NFT_ERROR:
      state = {
        ...state,
        errorMint: action.payload,
        loadingMint: false,
        errorMsgMint: true,
        successMint: false,
      };
      break;

    //reset
    case REST_MINT_STATES:
      state = {
        ...state,
        errorMsgMint: null,
        loadingMint: false,
        errorMint: false,
        successMint: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Web3Action;
