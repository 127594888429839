import {
  GET_USER_COLLECTION,
  GET_USER_COLLECTION_ERROR,
  GET_USER_COLLECTION_SUCCESS,
  RESET_GET_USER_COLLECTION,
  CREATE_USER_COLLECTION,
  CREATE_USER_COLLECTION_ERROR,
  CREATE_USER_COLLECTION_SUCCESS,
  RESET_CREATE_USER_COLLECTION,
  RESET_GET_USER_COLLECTION2,
} from "./actionTypes";

const initialState = {
  error: null,
  success: null,
  user: [],
  message: null,
  loading: null,
  errorMsgCreateCollection: "",
  loadingCreateCollection: false,
  errorCreateCollection: false,
  successCreateCollection: null,
  form: [],
};

const UserCollection = (state = initialState, action) => {
  switch (action.type) {
    //GET_USER_COLLECTION
    case GET_USER_COLLECTION:
      state = {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
      console.log("its get to reducer");
      break;
    case GET_USER_COLLECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        error: false,
        user: action.payload,
      };
      break;
    case GET_USER_COLLECTION_ERROR:
      state = {
        ...state,
        loading: false,
        success: false,
        error: true,
        user: null,
        message: action.payload.error,
      };
      break;
    case RESET_GET_USER_COLLECTION:
      state = {
        ...state,
        error: null,
        success: null,
        user: [],
        message: null,
        loading: null,
      };
      break;

    case RESET_GET_USER_COLLECTION2:
      state = {
        ...state,
        error: null,
        success: null,
        message: null,
        loading: null,
      };
      break;

    //CREATE_USER_COLLECTION
    case CREATE_USER_COLLECTION:
      state = {
        ...state,
        loadingCreateCollection: true,
        successCreateCollection: false,
        errorCreateCollection: false,
      };
      break;
    case CREATE_USER_COLLECTION_SUCCESS:
      state = {
        ...state,
        loadingCreateCollection: false,
        successCreateCollection: true,
        errorCreateCollection: false,
        form: action.payload,
      };
      console.log("in success create");
      break;
    case CREATE_USER_COLLECTION_ERROR:
      state = {
        ...state,
        loadingCreateCollection: false,
        successCreateCollection: false,
        errorCreateCollection: true,
        errorMsgCreateCollection: action.payload.error,
      };
      break;
    case RESET_CREATE_USER_COLLECTION:
      state = {
        ...state,
        errorMsgCreateCollection: "",
        loadingCreateCollection: false,
        errorCreateCollection: false,
        successCreateCollection: null,
        form: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UserCollection;
