//GET_TWITTER_FARM_EXTRACT_POSTS
export const GET_TWITTER_FARM_EXTRACT_POSTS_ACTION =
  "GET_TWITTER_FARM_EXTRACT_POSTS_ACTION";
export const GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_SUCCESS =
  "GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_SUCCESS";
export const GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_ERROR =
  "GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_ERROR";

//GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION";
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_SUCCESS =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_SUCCESS";
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_ERROR =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_ERROR";

//GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION";
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_SUCCESS =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_SUCCESS";
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_ERROR =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_ERROR";

//GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION";
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_SUCCESS =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_SUCCESS";
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_ERROR =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_ERROR";

//GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION";
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_SUCCESS =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_SUCCESS";
export const GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_ERROR =
  "GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_ERROR";

// actionTypes.js
export const POST_TWITTER_FARM_MY_POINTS = "POST_TWITTER_FARM_MY_POINTS";
export const POST_TWITTER_FARM_MY_POINTS_SUCCESS =
  "POST_TWITTER_FARM_MY_POINTS_SUCCESS";
export const POST_TWITTER_FARM_MY_POINTS_ERROR =
  "POST_TWITTER_FARM_MY_POINTS_ERROR";

//RESET FLAG
export const RESET_ALL_TWITTER_FARM_FLAG = "RESET_ALL_TWITTER_FARM_FLAG";
