import {
  NFT_RATING,
  NFT_RATING_SUCCESS,
  NFT_RATING_ERROR,
  RESET_NFT_RATING,
} from "./actionTypes";

const initialState = {
  errorMsgRatingNft: "",
  loadingRatingNft: false,
  errorRatingNft: false,
  successRatingNft: null,
  RatingNftText: [],
};

const RatingNft = (state = initialState, action) => {
  switch (action.type) {
    case NFT_RATING:
      state = {
        ...state,
        loadingRatingNft: true,
        errorRatingNft: false,
        successRatingNft: false,
        errorMsgRatingNft: null,
      };
      break;
    case NFT_RATING_SUCCESS:
      state = {
        ...state,
        loadingRatingNft: false,
        errorMsgRatingNft: false,
        successRatingNft: true,
        RatingNftText: action.payload,
      };
      break;
    case NFT_RATING_ERROR:
      state = {
        ...state,
        errorMsgRatingNft: action.payload,
        loadingRatingNft: false,
        errorRatingNft: true,
        successRatingNft: false,
      };
      break;
    case RESET_NFT_RATING:
      state = {
        ...state,
        errorMsgRatingNft: "",
        loadingRatingNft: false,
        errorRatingNft: false,
        successRatingNft: null,
        RatingNftText: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default RatingNft;
