import { call, put, takeLatest } from "redux-saga/effects";

import { getHomeMarketPlaceInfo } from "@/config/helpers/backend_helper";

import {
  homeMarketPlaceInfoError,
  homeMarketPlaceInfoSuccess,
} from "./actions";
import { HOME_MARKET_PLACE_INFO } from "./actionTypes";

function* fetchHomeMarketPlaceInfo() {
  try {
    const response = yield call(getHomeMarketPlaceInfo);
    yield put(homeMarketPlaceInfoSuccess(response));
  } catch (error) {
    yield put(homeMarketPlaceInfoError(error));
  }
}

function* HomeMarketPlaceInfoSaga() {
  yield takeLatest(HOME_MARKET_PLACE_INFO, fetchHomeMarketPlaceInfo);
}

export default HomeMarketPlaceInfoSaga;
