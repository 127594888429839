// reducer.js
import {
  GET_MY_TRANSACTIONS,
  GET_MY_TRANSACTIONS_SUCCESS,
  GET_MY_TRANSACTIONS_FAILURE,
} from "./actionTypes";

const initialState = {
  loadingMyTransactions: false,
  successMyTransactions: false,
  errorMyTransactions: null,
  transaction: [],
};
const MyTransactions = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_TRANSACTIONS:
      return {
        ...state,
        loadingMyTransactions: true,
        successMyTransactions: false,
        errorMyTransactions: null,
      };
    case GET_MY_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadingMyTransactions: false,
        successMyTransactions: true,
        errorMyTransactions: null,
        transaction: action.payload,
      };
    case GET_MY_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loadingMyTransactions: false,
        successMyTransactions: false,
        errorMyTransactions: action.payload,
      };
    default:
      return state;
  }
};

export default MyTransactions;
