import {
  TOP_COLLECTORS_HOME,
  TOP_COLLECTORS_HOME_SUCCESS,
  TOP_COLLECTORS_HOME_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgTopCollectors: "",
  loadingTopCollectors: false,
  errorTopCollectors: false,
  successTopCollectors: null,
  users: null,
};

const TopCollectors = (state = initialState, action) => {
  switch (action.type) {
    case TOP_COLLECTORS_HOME:
      return {
        ...state,
        loadingTopCollectors: true,
        errorTopCollectors: false,
        successTopCollectors: false,
        errorMsgTopCollectors: null,
      };
    case TOP_COLLECTORS_HOME_SUCCESS:
      return {
        ...state,
        loadingTopCollectors: false,
        errorTopCollectors: false,
        successTopCollectors: true,
        users: action.payload,
        errorMsgTopCollectors: null,
      };
    case TOP_COLLECTORS_HOME_ERROR:
      return {
        ...state,
        errorMsgTopCollectors: action.payload,
        loadingTopCollectors: false,
        errorTopCollectors: true,
        successTopCollectors: false,
      };
    default:
      return state;
  }
};

export default TopCollectors;
