// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import { CHECK_AVAILABLE_USERNAME } from "./actionTypes";
import { checkUserNameSuccess, checkUserNameFailure } from "./action";
import { postCheckAvailableUserName } from "@/config/helpers/backend_helper";

function* CheckUserName(action) {
  try {
    const response = yield call(postCheckAvailableUserName, action.payload);
    yield put(checkUserNameSuccess(response));
  } catch (error) {
    yield put(checkUserNameFailure(error));
  }
}

function* CheckUserNameSaga() {
  yield takeLatest(CHECK_AVAILABLE_USERNAME, CheckUserName);
}

export default CheckUserNameSaga;
