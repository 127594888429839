// actions.js
import {
  NFT_RATING,
  NFT_RATING_SUCCESS,
  NFT_RATING_ERROR,
  RESET_NFT_RATING,
} from "./actionTypes";

export const ratingNft = (data) => {
  return {
    type: NFT_RATING,
    payload: { data },
  };
};

export const ratingNftSuccess = (data) => {
  return {
    type: NFT_RATING_SUCCESS,
    payload: data,
  };
};

export const ratingNftError = (error) => {
  return {
    type: NFT_RATING_ERROR,
    payload: error,
  };
};

export const ResetRatingNft = () => {
  return {
    type: RESET_NFT_RATING,
  };
};
