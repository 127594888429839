import {
  FOLLOW_MEMBER,
  FOLLOW_MEMBER_ERROR,
  FOLLOW_MEMBER_SUCCESS,
  RESET_FOLLOW_MEMBER,
} from "./actionTypes";

const initialState = {
  errorfollow: null,
  successfollow: null,
  follow: null,
  messagefollow: null,
  loadingfollow: null,
};

const followtoggle = (state = initialState, action) => {
  switch (action.type) {
    //FOLLOW_MEMBER
    case FOLLOW_MEMBER:
      state = {
        ...state,
        loadingfollow: true,
      };
      break;
    case FOLLOW_MEMBER_SUCCESS:
      state = {
        ...state,
        loadingfollow: false,
        successfollow: true,
        errorfollow: false,
        follow: action.payload,
      };
      break;
    case FOLLOW_MEMBER_ERROR:
      state = {
        ...state,
        loadingfollow: false,
        successfollow: false,
        errorfollow: true,
        follow: null,
        messagefollow: action.payload.error,
      };
      break;
    //RESET_FLAGS
    case RESET_FOLLOW_MEMBER:
      state = {
        ...state,
        errorfollow: null,
        successfollow: null,
        follow: null,
        messagefollow: null,
        loadingfollow: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default followtoggle;
