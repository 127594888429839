// actions.js
import {
  TOP_CURATORS_HOME,
  TOP_CURATORS_HOME_SUCCESS,
  TOP_CURATORS_HOME_ERROR,
} from "./actionTypes";

export const topCuratorsData = () => {
  return {
    type: TOP_CURATORS_HOME,
  };
};

export const topCuratorsDataSuccess = (data) => {
  return {
    type: TOP_CURATORS_HOME_SUCCESS,
    payload: data,
  };
};

export const topCuratorsDataError = (data) => {
  return {
    type: TOP_CURATORS_HOME_ERROR,
    payload: data,
  };
};
