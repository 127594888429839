import {
  GET_PROFILE,
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  EDIT_PROFILE,
  EDIT_PROFILE_ERROR,
  EDIT_PROFILE_SUCCESS,
  CHECK_FOLLOW_USER,
  CHECK_FOLLOW_USER_SUCCESS,
  CHECK_FOLLOW_USER_ERROR,
  RESET_EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  RESET_ALL_PROFILE_DATA_FLAG,
  RESET_CHECK_FOLLOW_DATA_FLAG,
} from "./actionTypes";

const initialState = {
  error: null,
  success: null,
  user: [],
  message: null,
  loading: null,

  edit: null,
  loadingedit: false,
  successedit: false,
  erroredit: false,
  messageedit: null,

  //check-follow
  errorMsgCheckFollow: "",
  loadingCheckFollow: false,
  errorCheckFollow: false,
  successCheckFollow: null,
  checkFollow: null,
};

const Profile = (state = initialState, action) => {
  switch (action.type) {
    //EDIT_PROFILE
    case EDIT_PROFILE:
      state = {
        ...state,
        loadingedit: true,
        successedit: false,
        erroredit: false,
      };
      break;
    case EDIT_PROFILE_SUCCESS:
      state = {
        ...state,
        loadingedit: false,
        successedit: true,
        erroredit: false,
        edit: action.payload,
      };
      break;
    case EDIT_PROFILE_ERROR:
      state = {
        ...state,
        loadingedit: false,
        successedit: false,
        erroredit: true,
        edit: null,
        messageedit: action.payload,
      };
      break;
    case RESET_EDIT_PROFILE:
      state = {
        ...state,
        edit: null,
        loadingedit: false,
        successedit: false,
        erroredit: false,
        messageedit: null,
      };
      break;

    //GET_PROFILE
    case GET_PROFILE:
      state = {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
      break;
    case GET_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        error: false,
        user: action.payload,
      };
      break;
    case GET_PROFILE_ERROR:
      state = {
        ...state,
        loading: false,
        success: false,
        error: true,
        user: null,
        message: action.payload,
      };
      break;

    //CHECK_FOLLOW
    case CHECK_FOLLOW_USER:
      state = {
        ...state,
        loadingCheckFollow: true,
        successCheckFollow: false,
        errorCheckFollow: false,
      };
      break;
    case CHECK_FOLLOW_USER_SUCCESS:
      state = {
        ...state,
        loadingCheckFollow: false,
        successCheckFollow: true,
        errorCheckFollow: false,
        checkFollow: action.payload,
      };
      break;
    case CHECK_FOLLOW_USER_ERROR:
      state = {
        ...state,
        loadingCheckFollow: false,
        successCheckFollow: false,
        errorCheckFollow: true,
        checkFollow: null,
        errorMsgCheckFollow: action.payload,
      };
      break;
    case RESET_CHECK_FOLLOW_DATA_FLAG:
      state = {
        ...state,
        errorMsgCheckFollow: null,
        loadingCheckFollow: false,
        errorCheckFollow: false,
        successCheckFollow: false,
        checkFollow: null,
      };

    //RESET_FLAGS
    case RESET_PROFILE_FLAG:
      state = {
        ...state,
        successedit: false,
      };
      break;
    case RESET_ALL_PROFILE_DATA_FLAG:
      state = {
        ...state,
        success: null,
        error: null,
        user: null,
        message: null,
        loading: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  // console.log("profile state:", state.user);
  return state;
};

export default Profile;
