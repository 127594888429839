// saga.js
import { call, put, takeEvery } from "redux-saga/effects";
import { postNftRating } from "@/config/helpers/backend_helper";
import { NFT_RATING } from "./actionTypes";
import { ratingNftError, ratingNftSuccess } from "./action";

function* fetchRatingNft({ payload: { data } }) {
  try {
    const response = yield call(postNftRating, data);
    yield put(ratingNftSuccess(response));
  } catch (error) {
    yield put(ratingNftError(error.response.data.Errors[0].Message));
  }
}

function* RatingNftSaga() {
  yield takeEvery(NFT_RATING, fetchRatingNft);
}

export default RatingNftSaga;
