import {
  HERO_SECTION_HOME,
  HERO_SECTION_HOME_SUCCESS,
  HERO_SECTION_HOME_ERROR,
  MINTED_NFT_COUNT_HOME,
  MINTED_NFT_COUNT_HOME_SUCCESS,
  MINTED_NFT_COUNT_HOME_ERROR,
  RESET_HERO_SECTION_HOME,
} from "./actionTypes";

//heroSectionData
export const heroSectionData = () => {
  return {
    type: HERO_SECTION_HOME,
  };
};

export const heroSectionDataSuccess = (data) => {
  return {
    type: HERO_SECTION_HOME_SUCCESS,
    payload: data,
  };
};

export const heroSectionDataError = (data) => {
  return {
    type: HERO_SECTION_HOME_ERROR,
    payload: data,
  };
};

//mintedNftCountData
export const mintedNftCountData = () => {
  return {
    type: MINTED_NFT_COUNT_HOME,
  };
};

export const mintedNftCountDataSuccess = (data) => {
  return {
    type: MINTED_NFT_COUNT_HOME_SUCCESS,
    payload: data,
  };
};

export const mintedNftCountDataError = (data) => {
  return {
    type: MINTED_NFT_COUNT_HOME_ERROR,
    payload: data,
  };
};

//reset states
export const resetHeroSectionStates = () => {
  return {
    type: RESET_HERO_SECTION_HOME,
  };
};
