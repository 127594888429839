// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import {
  EXPLORE_REQUEST,
  EXPLORE_SUCCESS,
  EXPLORE_FAILURE,
  EXPLORE_SEARCH_REQUEST,
  EXPLORE_SEARCH_REDIRECT,
} from "./actionTypes";
import {
  exploreSuccess,
  exploreFailure,
  exploreSearchSuccess,
  exploreSearchFailure,
  exploreSearchRedirect,
} from "./action";
import {
  getNftsExplore,
  getSearchNftsExplore,
} from "@/config/helpers/backend_helper";

function* Explore(action) {
  try {
    // console.log(action.payload);

    const response = yield call(getNftsExplore, action.payload);
    console.log(response);
    yield put(exploreSuccess(response));
  } catch (error) {
    yield put(exploreFailure(error));
  }
}
function* SearchExplore(action) {
  try {
    const response = yield call(getSearchNftsExplore, action.payload);
    console.log(response);
    yield put(exploreSearchSuccess(response));
    yield put(exploreSearchRedirect(response));
  } catch (error) {
    yield put(exploreSearchFailure(error));
  }
}

function* ExploreSaga() {
  yield takeLatest(EXPLORE_REQUEST, Explore);
  yield takeLatest(EXPLORE_SEARCH_REQUEST, SearchExplore);
}

export default ExploreSaga;
