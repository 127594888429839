import {
  //GET_TWITTER_FARM_EXTRACT_POSTS
  GET_TWITTER_FARM_EXTRACT_POSTS_ACTION,
  GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_ERROR,
  GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_SUCCESS,
  //GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED
  GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_ERROR,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_SUCCESS,
  //GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED
  GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_ERROR,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_SUCCESS,
  //GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED
  GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_ERROR,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_SUCCESS,
  //GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS
  GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_ERROR,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_SUCCESS,
  RESET_ALL_TWITTER_FARM_FLAG,

  //POST_TWITTER_FARM_MY_POINTS
  POST_TWITTER_FARM_MY_POINTS_SUCCESS,
  POST_TWITTER_FARM_MY_POINTS_ERROR,
  POST_TWITTER_FARM_MY_POINTS,
} from "./actionTypes";

const initialState = {
  //GET_TWITTER_FARM_EXTRACT_POSTS
  extractPostsSuccessMsg: null,
  extractPosts: null,
  extractPostsError: false,
  extractPostsSuccess: false,
  extractPostsLoading: null,
  extractPostsMessage: null,
  //GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED
  extractPostUserRetweetedSuccessMsg: null,
  extractPostUserRetweeted: null,
  extractPostUserRetweetedError: false,
  extractPostUserRetweetedSuccess: false,
  extractPostUserRetweetedLoading: null,
  extractPostUserRetweetedMessage: null,
  //GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED
  extractPostUserLikedSuccessMsg: null,
  extractPostUserLiked: null,
  extractPostUserLikedError: false,
  extractPostUserLikedSuccess: false,
  extractPostUserLikedLoading: null,
  extractPostUserLikedMessage: null,
  // GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED
  extractPostUserReplyedSuccessMsg: null,
  extractPostUserReplyed: null,
  extractPostUserReplyedError: false,
  extractPostUserReplyedSuccess: false,
  extractPostUserReplyedLoading: null,
  extractPostUserReplyedMessage: null,
  // GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS
  extractPostUsersFollowersSuccessMsg: null,
  extractPostUsersFollowers: null,
  extractPostUsersFollowersError: false,
  extractPostUsersFollowersSuccess: false,
  extractPostUsersFollowersLoading: null,
  extractPostUsersFollowersMessage: null,
  //POST_TWITTER_FARM_MY_POINTS
  postTwitterMyPointsData: null,
  postTwitterMyPointsMsg: null,
  postTwitterMyPointsError: false,
  postTwitterMyPointsSuccess: false,
  postTwitterMyPointsLoading: false,
};

const TwitterFarm = (state = initialState, action) => {
  switch (action.type) {
    // GET_TWITTER_FARM_EXTRACT_POSTS
    case GET_TWITTER_FARM_EXTRACT_POSTS_ACTION:
      state = {
        ...state,
        extractPostsSuccessMsg: null,
        extractPostsError: null,
        extractPostsSuccess: null,
        extractPostsLoading: true,
        extractPostsMessage: null,
      };
      break;
    case GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_SUCCESS:
      state = {
        ...state,
        extractPostsLoading: false,
        extractPosts: action.payload,
        extractPostsSuccess: true,
        extractPostsError: false,
      };
      break;
    case GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_ERROR:
      state = {
        ...state,
        extractPostsLoading: false,
        extractPostsError: true,
        extractPostsMessage: action.payload,
      };
      break;

    // GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION:
      state = {
        ...state,
        extractPostUserRetweetedSuccessMsg: null,
        extractPostUserRetweetedError: null,
        extractPostUserRetweetedSuccess: null,
        extractPostUserRetweetedLoading: true,
        extractPostUserRetweetedMessage: null,
      };
      break;
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_SUCCESS:
      state = {
        ...state,
        extractPostUserRetweetedLoading: false,
        extractPostUserRetweeted: action.payload,
        extractPostUserRetweetedSuccess: true,
        extractPostUserRetweetedError: false,
      };
      break;
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_ERROR:
      state = {
        ...state,
        extractPostUserRetweetedLoading: false,
        extractPostUserRetweetedError: true,
        extractPostUserRetweetedMessage: action.payload,
      };
      break;

    // GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION:
      state = {
        ...state,
        extractPostUserLikedSuccessMsg: null,
        extractPostUserLikedError: null,
        extractPostUserLikedSuccess: null,
        extractPostUserLikedLoading: true,
        extractPostUserLikedMessage: null,
      };
      break;
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_SUCCESS:
      state = {
        ...state,
        extractPostUserLikedLoading: false,
        extractPostUserLiked: action.payload,
        extractPostUserLikedSuccess: true,
        extractPostUserLikedError: false,
      };
      break;
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_ERROR:
      state = {
        ...state,
        extractPostUserLikedLoading: false,
        extractPostUserLikedError: true,
        extractPostUserLikedMessage: action.payload,
      };
      break;

    // GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION:
      state = {
        ...state,
        extractPostUserReplyedSuccessMsg: null,
        extractPostUserReplyedError: null,
        extractPostUserReplyedSuccess: null,
        extractPostUserReplyedLoading: true,
        extractPostUserReplyedMessage: null,
      };
      break;
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_SUCCESS:
      state = {
        ...state,
        extractPostUserReplyedLoading: false,
        extractPostUserReplyed: action.payload,
        extractPostUserReplyedSuccess: true,
        extractPostUserReplyedError: false,
      };
      break;
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_ERROR:
      state = {
        ...state,
        extractPostUserReplyedLoading: false,
        extractPostUserReplyedError: true,
        extractPostUserReplyedMessage: action.payload,
      };
      break;

    // GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION:
      state = {
        ...state,
        extractPostUsersFollowersSuccessMsg: null,
        extractPostUsersFollowersError: null,
        extractPostUsersFollowersSuccess: null,
        extractPostUsersFollowersLoading: true,
        extractPostUsersFollowersMessage: null,
      };
      break;
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_SUCCESS:
      state = {
        ...state,
        extractPostUsersFollowersLoading: false,
        extractPostUsersFollowers: action.payload,
        extractPostUsersFollowersSuccess: true,
        extractPostUsersFollowersError: false,
      };
      break;
    case GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_ERROR:
      state = {
        ...state,
        extractPostUsersFollowersLoading: false,
        extractPostUsersFollowersError: true,
        extractPostUsersFollowersMessage: action.payload,
      };
      break;

    //postTwitterMyPoints
    case POST_TWITTER_FARM_MY_POINTS:
      state = {
        ...state,
        postTwitterMyPointsError: false,
        postTwitterMyPointsSuccess: false,
        postTwitterMyPointsLoading: true,
      };
      break;
    case POST_TWITTER_FARM_MY_POINTS_SUCCESS:
      state = {
        ...state,
        postTwitterMyPointsData: action.payload,
        postTwitterMyPointsMsg: null,
        postTwitterMyPointsError: false,
        postTwitterMyPointsSuccess: true,
        postTwitterMyPointsLoading: false,
      };
      break;
    case POST_TWITTER_FARM_MY_POINTS_ERROR:
      state = {
        ...state,
        postTwitterMyPointsData: null,
        postTwitterMyPointsMsg: action.payload,
        postTwitterMyPointsError: true,
        postTwitterMyPointsSuccess: false,
        postTwitterMyPointsLoading: false,
      };
      break;

    //RESET_ALL_TWITTER_FARM_FLAG
    case RESET_ALL_TWITTER_FARM_FLAG:
      state = {
        ...state,
        extractPostsLoading: false,
        extractPostsSuccess: false,
        extractPostsError: false,
        extractPostsMessage: null,
        extractPostsSuccessMsg: null,
        //GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED
        extractPostUserRetweetedSuccessMsg: null,
        extractPostUserRetweetedError: false,
        extractPostUserRetweetedSuccess: false,
        extractPostUserRetweetedLoading: null,
        extractPostUserRetweetedMessage: null,
        //GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED
        extractPostUserLikedSuccessMsg: null,
        extractPostUserLikedError: false,
        extractPostUserLikedSuccess: false,
        extractPostUserLikedLoading: null,
        extractPostUserLikedMessage: null,
        // GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED
        extractPostUserReplyedSuccessMsg: null,
        extractPostUserReplyedError: false,
        extractPostUserReplyedSuccess: false,
        extractPostUserReplyedLoading: null,
        extractPostUserReplyedMessage: null,
        // GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS
        extractPostUsersFollowersSuccessMsg: null,
        extractPostUsersFollowersError: false,
        extractPostUsersFollowersSuccess: false,
        extractPostUsersFollowersLoading: null,
        extractPostUsersFollowersMessage: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default TwitterFarm;
