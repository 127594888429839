import {
  RANDOM_USERNAME,
  RANDOM_USERNAME_ERROR,
  RANDOM_USERNAME_SUCCESS,
} from "./actionTypes";

//random
export const RandomUserName = (user) => {
  return {
    type: RANDOM_USERNAME,
    payload: { user },
  };
};

export const RandomUserNameSuccess = (user) => {
  return {
    type: RANDOM_USERNAME_SUCCESS,
    payload: user,
  };
};

export const RandomUserNameError = (error) => {
  return {
    type: RANDOM_USERNAME_ERROR,
    payload: error,
  };
};

