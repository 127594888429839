import {
  GET_MY_PROFILE,
  GET_MY_PROFILE_ERROR,
  GET_MY_PROFILE_SUCCESS,
  RESET_ALL_MY_PROFILE_DATA_FLAG,
  RESET_MY_PROFILE,
} from "./actionTypes";

//getMyProfile
export const getMyProfile = (user) => {
  return {
    type: GET_MY_PROFILE,
    payload: { user },
  };
};

export const getMyProfileSuccess = (user) => {
  return {
    type: GET_MY_PROFILE_SUCCESS,
    payload: user,
  };
};

export const getMyProfileError = (error) => {
  return {
    type: GET_MY_PROFILE_ERROR,
    payload: error,
  };
};

//resetFlags
export const resetMyProfile = () => {
  return {
    type: RESET_MY_PROFILE,
  };
};

export const resetAllMyProfileDataFlag = () => {
  return {
    type: RESET_ALL_MY_PROFILE_DATA_FLAG,
  };
};
