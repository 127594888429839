import {
  ALL_NFTCARD_REQUEST,
  ALL_NFTCARD_SUCCESS,
  ALL_NFTCARD_FAILURE,
  RESET_ALL_NFTCARD_FAILURE,
} from "./actionTypes";

export const AllNftCardRequest = (requestData) => ({
  type: ALL_NFTCARD_REQUEST,
  payload: requestData,
});

export const AllNftCardSuccess = (data) => ({
  type: ALL_NFTCARD_SUCCESS,
  payload: data,
});

export const AllNftCardFailure = (error) => ({
  type: ALL_NFTCARD_FAILURE,
  payload: error,
});

export const ResetAllNftCardFailure = () => ({
  type: RESET_ALL_NFTCARD_FAILURE,
});
