// actionTypes.js
export const TUTORIALS_LIST = "TUTORIALS_LIST";
export const TUTORIALS_LIST_SUCCESS = "TUTORIALS_LIST_SUCCESS";
export const TUTORIALS_LIST_ERROR = "TUTORIALS_LIST_ERROR";

export const TUTORIALS_LIST2 = "TUTORIALS_LIST2";
export const TUTORIALS_LIST_SUCCESS2 = "TUTORIALS_LIST_SUCCESS2";
export const TUTORIALS_LIST_ERROR2 = "TUTORIALS_LIST_ERROR2";

export const TUTORIALS_INCREASE_VIEW_COUNT = "TUTORIALS_INCREASE_VIEW_COUNT";
export const TUTORIALS_INCREASE_VIEW_COUNT_SUCCESS =
  "TUTORIALS_INCREASE_VIEW_COUNT_SUCCESS";
export const TUTORIALS_INCREASE_VIEW_COUNT_ERROR =
  "TUTORIALS_INCREASE_VIEW_COUNT_ERROR";

export const RESET_INCREASE_VIEW_COUNT = "RESET_INCREASE_VIEW_COUNT";
