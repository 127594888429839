// actions.js
import {
  NSFW_NFTS_REQUEST,
  NSFW_NFTS_SUCCESS,
  NSFW_NFTS_FAILURE,
} from "./actionTypes";

export const nsfwNftsRequest = (requestData) => ({
  type: NSFW_NFTS_REQUEST,
  payload: requestData,
});

export const nsfwNftsSuccess = (data) => ({
  type: NSFW_NFTS_SUCCESS,
  payload: data,
});

export const nsfwNftsFailure = (error) => ({
  type: NSFW_NFTS_FAILURE,
  payload: error,
});
