//HERO_SECTION_HOME
export const HERO_SECTION_HOME = "HERO_SECTION_HOME";
export const HERO_SECTION_HOME_SUCCESS = "HERO_SECTION_HOME_SUCCESS";
export const HERO_SECTION_HOME_ERROR = "HERO_SECTION_HOME_ERROR";

//MINTED_NFT_COUNT_HOME
export const MINTED_NFT_COUNT_HOME = "MINTED_NFT_COUNT_HOME";
export const MINTED_NFT_COUNT_HOME_SUCCESS = "MINTED_NFT_COUNT_HOME_SUCCESS";
export const MINTED_NFT_COUNT_HOME_ERROR = "MINTED_NFT_COUNT_HOME_ERROR";

export const RESET_HERO_SECTION_HOME = "RESET_HERO_SECTION_HOME";
