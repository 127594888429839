import {
  ARCHIVE_NFTCARD_REQUEST,
  ARCHIVE_NFTCARD_SUCCESS,
  ARCHIVE_NFTCARD_FAILURE,
  RESET_ARCHIVE_NFTCARD_FAILURE
} from "./actionTypes";

export const ArchiveNftCardRequest = (requestData) => ({
  type: ARCHIVE_NFTCARD_REQUEST,
  payload: requestData,
});

export const ArchiveNftCardSuccess = (data) => ({
  type: ARCHIVE_NFTCARD_SUCCESS,
  payload: data,
});

export const ArchiveNftCardFailure = (error) => ({
  type: ARCHIVE_NFTCARD_FAILURE,
  payload: error,
});

export const ResetArchiveNftCardFailure = () => ({
  type: RESET_ARCHIVE_NFTCARD_FAILURE,
});
