// saga.js  DOWNLOAD_CERTIFICATE
import { call, put, takeLatest } from "redux-saga/effects";
import { DOWNLOAD_CERTIFICATE } from "./actionTypes";
import {
  downloadCertificateSuccess,
  downloadCertificateFailure,
} from "./action";
import { getDownloadCertificateNft } from "@/config/helpers/backend_helper";
import { api_stage } from "@/config/config";

async function downloadCertificate(id) {
  let baseApi =
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage1") ||
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("localhost")
      ? api_stage.API_URL_STAGE1
      : process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage2")
      ? api_stage.API_URL_STAGE2
      : process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage3")
      ? api_stage.API_URL_STAGE3
      : api_stage.API_URL_MAIN;
  const data = await fetch(`${baseApi}api/Nfts/DownloadCertificate/${id}`)
    .then((r) => r.blob())
    .then((d) => {
      console.log(window.URL.createObjectURL(d));
      //   fileDownload(d, "certificate.png");
      return window.URL.createObjectURL(d);
    });

  return data;
}

function* DownloadCertificate({ payload: { Id } }) {
  try {
    const response = yield call(downloadCertificate, Id);
    console.log(response);
    yield put(downloadCertificateSuccess(response));
    // console.log("response: " + response);
    // console.log("response2: " + url);
  } catch (error) {
    yield put(
      downloadCertificateFailure(error.response.data.Errors[0].Message)
    );
  }
}

function* DownloadCertificateSaga() {
  yield takeLatest(DOWNLOAD_CERTIFICATE, DownloadCertificate);
}

export default DownloadCertificateSaga;
