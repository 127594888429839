// saga.js
import { call, put, takeEvery } from "redux-saga/effects";
import { postAccountReportNft } from "@/config/helpers/backend_helper";
import { ReportNftError, ReportNftSuccess } from "./action";
import { ACCOUNT_REPORT_NFT } from "./actionTypes";

function* fetchReportNft({ payload: { text } }) {
  try {
    const response = yield call(postAccountReportNft, text);
    yield put(ReportNftSuccess(response));
  } catch (error) {
    yield put(ReportNftError(error.response.data.Errors[0].Message));
  }
}

function* ReportNftSaga() {
  yield takeEvery(ACCOUNT_REPORT_NFT, fetchReportNft);
}

export default ReportNftSaga;
