import {
  MUSEUM_UPCOMING,
  MUSEUM_UPCOMING_SUCCESS,
  MUSEUM_UPCOMING_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgMuseumUpcomingNfts: "",
  loadingMuseumUpcomingNfts: false,
  errorMuseumUpcomingNfts: false,
  successMuseumUpcomingNfts: null,
  nftsUpcoming: [],
};

const MuseumUpcoming = (state = initialState, action) => {
  switch (action.type) {
    case MUSEUM_UPCOMING:
      return {
        ...state,
        loadingMuseumUpcomingNfts: true,
        errorMuseumUpcomingNfts: false,
        successMuseumUpcomingNfts: false,
        errorMsgMuseumUpcomingNfts: null,
      };
    case MUSEUM_UPCOMING_SUCCESS:
      return {
        ...state,
        loadingMuseumUpcomingNfts: false,
        errorMuseumUpcomingNfts: false,
        successMuseumUpcomingNfts: true,
        nftsUpcoming: action.payload,
        errorMsgMuseumUpcomingNfts: null,
      };
    case MUSEUM_UPCOMING_ERROR:
      return {
        ...state,
        errorMsgMuseumUpcomingNfts: action.payload.data,
        loadingMuseumUpcomingNfts: false,
        errorMuseumUpcomingNfts: true,
        successMuseumUpcomingNfts: false,
      };
    default:
      return state;
  }
};

export default MuseumUpcoming;
