// saga.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getCancelPromotionNft,
  getNftCheckDetails,
  getNftDetail,
  getNftDetailStep2,
  getNftToggleArchive,
} from "@/config/helpers/backend_helper";
import {
  NftDetailDataSuccess,
  NftDetailDataError,
  NftDetailDataStep2Success,
  NftDetailDataStep2Error,
  nftToggleArchiveSuccess,
  nftToggleArchiveError,
  nftCancelPromotionSuccess,
  nftCancelPromotionError,
  nftCheckDetailsSuccess,
  nftCheckDetailsError,
} from "./action";
import {
  NFT_CANCEL_PROMOTION,
  NFT_CHECK_DETAILS,
  NFT_DETAILS,
  NFT_DETAILS_STEP2,
  NFT_TOGGLE_ARCHIVE,
} from "./actionTypes";

function* fetchNftDetailData({ payload: { Id } }) {
  try {
    const response = yield call(getNftDetail, Id);
    yield put(NftDetailDataSuccess(response));
  } catch (error) {
    yield put(NftDetailDataError(error.response.data.Errors[0].Message));
  }
}

function* fetchNftDetailStep2Data({ payload: { Id } }) {
  try {
    const response = yield call(getNftDetailStep2, Id);
    yield put(NftDetailDataStep2Success(response));
  } catch (error) {
    yield put(NftDetailDataStep2Error(error.response.data.Errors[0].Message));
  }
}

function* archiveNft({ payload: { Id } }) {
  try {
    const response = yield call(getNftToggleArchive, Id);
    yield put(nftToggleArchiveSuccess(response));
  } catch (error) {
    yield put(nftToggleArchiveError(error.response.data.Errors[0].Message));
  }
}

function* cancelPromotionNft({ payload: { Id } }) {
  try {
    const response = yield call(getCancelPromotionNft, Id);
    yield put(nftCancelPromotionSuccess(response));
  } catch (error) {
    yield put(nftCancelPromotionError(error.response.data.Errors[0].Message));
  }
}

function* fetchCheckDetail({ payload: { Id } }) {
  try {
    const response = yield call(getNftCheckDetails, Id);
    yield put(nftCheckDetailsSuccess(response));
  } catch (error) {
    yield put(nftCheckDetailsError(error.response.data.Errors[0].Message));
  }
}

function* NftDetailSaga() {
  yield takeLatest(NFT_DETAILS, fetchNftDetailData);
  yield takeLatest(NFT_DETAILS_STEP2, fetchNftDetailStep2Data);
  yield takeLatest(NFT_TOGGLE_ARCHIVE, archiveNft);
  yield takeLatest(NFT_CANCEL_PROMOTION, cancelPromotionNft);
  yield takeLatest(NFT_CHECK_DETAILS, fetchCheckDetail);
}

export default NftDetailSaga;
