// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import { GET_NFT_HISTORY } from "./actionTypes";
import { getNftHistorySuccess, getNftHistoryError } from "./action";
import { getNftHistory } from "@/config/helpers/backend_helper";

function* fetchNftHistory({ payload: { data } }) {
  try {
    const response = yield call(getNftHistory, data);
    // console.log(response);
    yield put(getNftHistorySuccess(response));
  } catch (error) {
    yield put(getNftHistoryError(error));
  }
}

function* getNftHistorySaga() {
  yield takeLatest(GET_NFT_HISTORY, fetchNftHistory);
}

export default getNftHistorySaga;
