import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import {
  getTwitterMyPointError,
  getTwitterMyPointSuccess,
  getTwitterSettingError,
  getTwitterSettingSuccess,
  getTwitterUpdateUsersLeagueError,
  getTwitterUpdateUsersLeagueSuccess,
  postTwitterAllUserLikePointV2Error,
  postTwitterAllUserLikePointV2Success,
  postTwitterAllUserPointError,
  postTwitterAllUserPointSuccess,
  postTwitterMyActivitiesPointsError,
  postTwitterMyActivitiesPointsSuccess,
  postTwitterMyPointError,
  postTwitterMyPointSuccess,
  postTwitterMySeedActivitiesError,
  postTwitterMySeedActivitiesSuccess,
} from "./actions";

import {
  getTwitterMyPoints,
  getTwitterSettings,
  getTwitterUpdateUserLeague,
  postTwitterAllUserLikePointsV2,
  postTwitterAllUserPoints,
  postTwitterMyActivities,
  postTwitterMyPoints,
  postTwitterMySeed,
} from "@/config/helpers/backend_helper";

import {
  GET_TWITTER_FARM_GET_MY_POINT,
  GET_TWITTER_SETTING,
  GET_TWITTER_UPDATE_USERS_LEAGUE,
  POST_TWITTER_ALL_USER_LIKE_POINTS_V2,
  POST_TWITTER_FARM_ALL_USER_POINTS,
  POST_TWITTER_FARM_MY_ACTIVITIES_ACTION,
  POST_TWITTER_FARM_MY_POINTS,
  POST_TWITTER_FARM_MY_SEED,
} from "./actionTypes";

function* fetchTwitterSetting() {
  try {
    const response = yield call(getTwitterSettings);
    yield put(getTwitterSettingSuccess(response));
  } catch (error) {
    yield put(getTwitterSettingError(error));
  }
}

function* fetchGetTwitterMyPoints() {
  try {
    const response = yield call(getTwitterMyPoints);
    yield put(getTwitterMyPointSuccess(response));
  } catch (error) {
    yield put(getTwitterMyPointError(error));
  }
}

function* fetchTwitterMyActivities(action) {
  try {
    const response = yield call(postTwitterMyActivities, action.payload);
    yield put(postTwitterMyActivitiesPointsSuccess(response));
  } catch (error) {
    yield put(postTwitterMyActivitiesPointsError(error));
  }
}

function* fetchTwitterMyPoints(action) {
  try {
    const response = yield call(postTwitterMyPoints, action.payload);
    yield put(postTwitterMyPointSuccess(response));
  } catch (error) {
    yield put(postTwitterMyPointError(error));
  }
}

function* fetchTwitterMySeed(action) {
  try {
    const response = yield call(postTwitterMySeed, action.payload);
    yield put(postTwitterMySeedActivitiesSuccess(response));
  } catch (error) {
    yield put(postTwitterMySeedActivitiesError(error));
  }
}

function* fetchTwitterAllUserPoints(action) {
  try {
    const response = yield call(postTwitterAllUserPoints, action.payload);
    yield put(postTwitterAllUserPointSuccess(response));
  } catch (error) {
    yield put(postTwitterAllUserPointError(error));
  }
}

function* fetchTwitterAllUserLikePointsV2(action) {
  try {
    const response = yield call(postTwitterAllUserLikePointsV2, action.payload);
    yield put(postTwitterAllUserLikePointV2Success(response));
  } catch (error) {
    yield put(postTwitterAllUserLikePointV2Error(error));
  }
}

function* fetchTwitterUpdateUserLeague(action) {
  try {
    const response = yield call(getTwitterUpdateUserLeague, action.payload);
    yield put(getTwitterUpdateUsersLeagueSuccess(response));
  } catch (error) {
    yield put(getTwitterUpdateUsersLeagueError(error));
  }
}

export function* watchTwitterPointsChange() {
  yield takeLatest(GET_TWITTER_SETTING, fetchTwitterSetting);
  yield takeLatest(GET_TWITTER_FARM_GET_MY_POINT, fetchGetTwitterMyPoints);
  yield takeLatest(
    POST_TWITTER_FARM_MY_ACTIVITIES_ACTION,
    fetchTwitterMyActivities
  );
  yield takeLatest(POST_TWITTER_FARM_MY_POINTS, fetchTwitterMyPoints);
  yield takeLatest(POST_TWITTER_FARM_MY_SEED, fetchTwitterMySeed);

  yield takeLatest(
    POST_TWITTER_FARM_ALL_USER_POINTS,
    fetchTwitterAllUserPoints
  );

  yield takeLatest(
    POST_TWITTER_ALL_USER_LIKE_POINTS_V2,
    fetchTwitterAllUserLikePointsV2
  );
  yield takeLatest(
    GET_TWITTER_UPDATE_USERS_LEAGUE,
    fetchTwitterUpdateUserLeague
  );
}

function* TwitterPointsSaga() {
  yield all([fork(watchTwitterPointsChange)]);
}

export default TwitterPointsSaga;
