import {
  COMMUNITIES_HOME,
  COMMUNITIES_HOME_SUCCESS,
  COMMUNITIES_HOME_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgCommunities: "",
  loadingCommunities: false,
  errorCommunities: false,
  successCommunities: null,
  communities: null,
};

const Communities = (state = initialState, action) => {
  switch (action.type) {
    case COMMUNITIES_HOME:
      return {
        ...state,
        loadingCommunities: true,
        errorCommunities: false,
        successCommunities: false,
        errorMsgCommunities: null,
      };
    case COMMUNITIES_HOME_SUCCESS:
      return {
        ...state,
        loadingCommunities: false,
        errorCommunities: false,
        successCommunities: true,
        communities: action.payload,
        errorMsgCommunities: null,
      };
    case COMMUNITIES_HOME_ERROR:
      return {
        ...state,
        errorMsgCommunities: action.payload,
        loadingCommunities: false,
        errorCommunities: true,
        successCommunities: false,
      };
    default:
      return state;
  }
};

export default Communities;
