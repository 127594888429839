// actionTypes.js
export const TOP_PHOTOGRAPHERS_HOME = "TOP_PHOTOGRAPHERS_HOME";
export const TOP_PHOTOGRAPHERS_HOME_SUCCESS = "TOP_PHOTOGRAPHERS_HOME_SUCCESS";
export const TOP_PHOTOGRAPHERS_HOME_ERROR = "TOP_PHOTOGRAPHERS_HOME_ERROR";

export const TOP_PHOTOGRAPHERS_PAGE_SELL = "TOP_PHOTOGRAPHERS_PAGE_SELL";
export const TOP_PHOTOGRAPHERS_PAGE_SELL_SUCCESS =
  "TOP_PHOTOGRAPHERS_PAGE_SELL_SUCCESS";
export const TOP_PHOTOGRAPHERS_PAGE_SELL_ERROR =
  "TOP_PHOTOGRAPHERS_PAGE_SELL_ERROR";

export const TOP_PHOTOGRAPHERS_PAGE_REFERRALS =
  "TOP_PHOTOGRAPHERS_PAGE_REFERRALS";
export const TOP_PHOTOGRAPHERS_PAGE_REFERRALS_SUCCESS =
  "TOP_PHOTOGRAPHERS_PAGE_REFERRALS_SUCCESS";
export const TOP_PHOTOGRAPHERS_PAGE_REFERRALS_ERROR =
  "TOP_PHOTOGRAPHERS_PAGE_REFERRALS_ERROR";

export const TOP_PHOTOGRAPHERS_PAGE_MINTED = "TOP_PHOTOGRAPHERS_PAGE_MINTED";
export const TOP_PHOTOGRAPHERS_PAGE_MINTED_SUCCESS =
  "TOP_PHOTOGRAPHERS_PAGE_MINTED_SUCCESS";
export const TOP_PHOTOGRAPHERS_PAGE_MINTED_ERROR =
  "TOP_PHOTOGRAPHERS_PAGE_MINTED_ERROR";

export const TOP_PHOTOGRAPHERS_PAGE_LISTED = "TOP_PHOTOGRAPHERS_PAGE_LISTED";
export const TOP_PHOTOGRAPHERS_PAGE_LISTED_SUCCESS =
  "TOP_PHOTOGRAPHERS_PAGE_LISTED_SUCCESS";
export const TOP_PHOTOGRAPHERS_PAGE_LISTED_ERROR =
  "TOP_PHOTOGRAPHERS_PAGE_LISTED_ERROR";
