import {
  VERIFICATION_FORM,
  VERIFICATION_FORM_SUCCESS,
  VERIFICATION_FORM_ERROR,
  RESET_VERIFICATION_FORM,
} from "./actionTypes";

//verification-form Data
export const verificationFormData = (form) => {
  return {
    type: VERIFICATION_FORM,
    payload: { form },
  };
};

export const verificationFormSuccess = (data) => {
  return {
    type: VERIFICATION_FORM_SUCCESS,
    payload: data,
  };
};

export const verificationFormError = (data) => {
  return {
    type: VERIFICATION_FORM_ERROR,
    payload: data,
  };
};

//reset states
export const resetVerificationFormStates = () => {
  return {
    type: RESET_VERIFICATION_FORM,
  };
};
