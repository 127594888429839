// reducer.js
import {
  TOP_PHOTOGRAPHERS_HOME,
  TOP_PHOTOGRAPHERS_HOME_SUCCESS,
  TOP_PHOTOGRAPHERS_HOME_ERROR,
  TOP_PHOTOGRAPHERS_PAGE_SELL,
  TOP_PHOTOGRAPHERS_PAGE_SELL_SUCCESS,
  TOP_PHOTOGRAPHERS_PAGE_SELL_ERROR,
  TOP_PHOTOGRAPHERS_PAGE_REFERRALS,
  TOP_PHOTOGRAPHERS_PAGE_REFERRALS_SUCCESS,
  TOP_PHOTOGRAPHERS_PAGE_REFERRALS_ERROR,
  TOP_PHOTOGRAPHERS_PAGE_MINTED,
  TOP_PHOTOGRAPHERS_PAGE_MINTED_SUCCESS,
  TOP_PHOTOGRAPHERS_PAGE_MINTED_ERROR,
  TOP_PHOTOGRAPHERS_PAGE_LISTED,
  TOP_PHOTOGRAPHERS_PAGE_LISTED_SUCCESS,
  TOP_PHOTOGRAPHERS_PAGE_LISTED_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgTopPhotographers: "",
  loadingTopPhotographers: false,
  errorTopPhotographers: false,
  successTopPhotographers: null,
  photographers: null,

  errorMsgTopPhotographersReferrals: "",
  loadingTopPhotographersReferrals: false,
  errorTopPhotographersReferrals: false,
  successTopPhotographersReferrals: null,
  photographersReferrals: null,

  errorMsgTopPhotographersSell: "",
  loadingTopPhotographersSell: false,
  errorTopPhotographersSell: false,
  successTopPhotographersSell: null,
  photographersSell: null,

  errorMsgTopPhotographersMinted: "",
  loadingTopPhotographersMinted: false,
  errorTopPhotographersMinted: false,
  successTopPhotographersMinted: null,
  photographersMinted: null,

  errorMsgTopPhotographersListed: "",
  loadingTopPhotographersListed: false,
  errorTopPhotographersListed: false,
  successTopPhotographersListed: null,
  photographersListed: null,
};

const TopPhotographers = (state = initialState, action) => {
  switch (action.type) {
    case TOP_PHOTOGRAPHERS_HOME:
      return {
        ...state,
        loadingTopPhotographers: true,
        errorTopPhotographers: false,
        successTopPhotographers: false,
        errorMsgTopPhotographers: null,
      };
    case TOP_PHOTOGRAPHERS_HOME_SUCCESS:
      return {
        ...state,
        loadingTopPhotographers: false,
        errorTopPhotographers: false,
        successTopPhotographers: true,
        photographers: action.payload,
        errorMsgTopPhotographers: null,
      };
    case TOP_PHOTOGRAPHERS_HOME_ERROR:
      return {
        ...state,
        errorMsgTopPhotographers: action.payload.data,
        loadingTopPhotographers: false,
        errorTopPhotographers: true,
        successTopPhotographers: false,
      };
    case TOP_PHOTOGRAPHERS_PAGE_SELL:
      return {
        ...state,
        loadingTopPhotographersSell: true,
        errorTopPhotographersSell: false,
        successTopPhotographersSell: false,
        errorMsgTopPhotographersSell: null,
      };
    case TOP_PHOTOGRAPHERS_PAGE_SELL_SUCCESS:
      return {
        ...state,
        loadingTopPhotographersSell: false,
        errorTopPhotographersSell: false,
        successTopPhotographersSell: true,
        photographersSell: action.payload,
        errorMsgTopPhotographersSell: null,
      };
    case TOP_PHOTOGRAPHERS_PAGE_SELL_ERROR:
      return {
        ...state,
        errorMsgTopPhotographersSell: action.payload.data,
        loadingTopPhotographersSell: false,
        errorTopPhotographersSell: true,
        successTopPhotographersSell: false,
      };
    case TOP_PHOTOGRAPHERS_PAGE_REFERRALS:
      return {
        ...state,
        loadingTopPhotographersReferrals: true,
        errorTopPhotographersReferrals: false,
        successTopPhotographersReferrals: false,
        errorMsgTopPhotographersReferrals: null,
      };
    case TOP_PHOTOGRAPHERS_PAGE_REFERRALS_SUCCESS:
      return {
        ...state,
        loadingTopPhotographersReferrals: false,
        errorTopPhotographersReferrals: false,
        successTopPhotographersReferrals: true,
        photographersReferrals: action.payload,
        errorMsgTopPhotographersReferrals: null,
      };
    case TOP_PHOTOGRAPHERS_PAGE_REFERRALS_ERROR:
      return {
        ...state,
        errorMsgTopPhotographersReferrals: action.payload.data,
        loadingTopPhotographersReferrals: false,
        errorTopPhotographersReferrals: true,
        successTopPhotographersReferrals: false,
      };
    case TOP_PHOTOGRAPHERS_PAGE_MINTED:
      return {
        ...state,
        loadingTopPhotographersMinted: true,
        errorTopPhotographersMinted: false,
        successTopPhotographersMinted: false,
        errorMsgTopPhotographersMinted: null,
      };
    case TOP_PHOTOGRAPHERS_PAGE_MINTED_SUCCESS:
      return {
        ...state,
        loadingTopPhotographersMinted: false,
        errorTopPhotographersMinted: false,
        successTopPhotographersMinted: true,
        photographersMinted: action.payload,
        errorMsgTopPhotographersMinted: null,
      };
    case TOP_PHOTOGRAPHERS_PAGE_MINTED_ERROR:
      return {
        ...state,
        errorMsgTopPhotographersMinted: action.payload.data,
        loadingTopPhotographersMinted: false,
        errorTopPhotographersMinted: true,
        successTopPhotographersMinted: false,
      };
    case TOP_PHOTOGRAPHERS_PAGE_LISTED:
      return {
        ...state,
        loadingTopPhotographersListed: true,
        errorTopPhotographersListed: false,
        successTopPhotographersListed: false,
        errorMsgTopPhotographersListed: null,
      };
    case TOP_PHOTOGRAPHERS_PAGE_LISTED_SUCCESS:
      return {
        ...state,
        loadingTopPhotographersListed: false,
        errorTopPhotographersListed: false,
        successTopPhotographersListed: true,
        photographersListed: action.payload,
        errorMsgTopPhotographersListed: null,
      };
    case TOP_PHOTOGRAPHERS_PAGE_LISTED_ERROR:
      return {
        ...state,
        errorMsgTopPhotographersListed: action.payload.data,
        loadingTopPhotographersListed: false,
        errorTopPhotographersListed: true,
        successTopPhotographersListed: false,
      };
    default:
      return state;
  }
};

export default TopPhotographers;
