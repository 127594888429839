// actions.js
import {
  CHECK_AVAILABLE_USERNAME,
  CHECK_AVAILABLE_USERNAME_FAILURE,
  CHECK_AVAILABLE_USERNAME_SUCCESS,
} from "./actionTypes";

export const checkUserName = (userName, requestData) => ({
  type: CHECK_AVAILABLE_USERNAME,
  payload: { userName, requestData },
});
export const checkUserNameSuccess = (data) => ({
  type: CHECK_AVAILABLE_USERNAME_SUCCESS,
  payload: data,
});

export const checkUserNameFailure = (error) => ({
  type: CHECK_AVAILABLE_USERNAME_FAILURE,
  payload: error,
});
