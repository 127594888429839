// actions.js
import {
  USER_COLLECTION_NFTS,
  USER_COLLECTION_NFTS_SUCCESS,
  USER_COLLECTION_NFTS_ERROR,
  USER_COLLECTION_DELETE,
  USER_COLLECTION_DELETE_SUCCESS,
  USER_COLLECTION_DELETE_ERROR,
  USER_COLLECTION_EDIT,
  USER_COLLECTION_EDIT_SUCCESS,
  USER_COLLECTION_EDIT_ERROR,
  USER_COLLECTION_EDIT_RESET,
  USER_COLLECTION_DELETE_NFT,
  USER_COLLECTION_DELETE_NFT_SUCCESS,
  USER_COLLECTION_DELETE_NFT_ERROR,
  USER_COLLECTION_NFTS_RESET,
  USER_COLLECTION_DELETE_NFT_RESET,
  USER_ADD_NFT_COLLECTION,
  USER_ADD_NFT_COLLECTION_ERROR,
  USER_ADD_NFT_COLLECTION_SUCCESS,
  USER_ADD_NFT_COLLECTION_RESET,
} from "./actionTypes";

export const userCollectionNftData = (collectionId) => {
  return {
    type: USER_COLLECTION_NFTS,
    payload: collectionId,
  };
};

export const userCollectionNftDataSuccess = (data) => {
  return {
    type: USER_COLLECTION_NFTS_SUCCESS,
    payload: data,
  };
};

export const userCollectionNftDataError = (error) => {
  return {
    type: USER_COLLECTION_NFTS_ERROR,
    payload: error,
  };
};

export const userCollectionNftDataReset = () => {
  return {
    type: USER_COLLECTION_NFTS_RESET,
  };
};

export const userCollectionDeleteData = (collectionId) => {
  return {
    type: USER_COLLECTION_DELETE,
    payload: collectionId,
  };
};

export const userCollectionDeleteDataSuccess = (data) => {
  return {
    type: USER_COLLECTION_DELETE_SUCCESS,
    payload: data,
  };
};

export const userCollectionDeleteDataError = (error) => {
  return {
    type: USER_COLLECTION_DELETE_ERROR,
    payload: error,
  };
};

export const userCollectionEditData = (form) => {
  return {
    type: USER_COLLECTION_EDIT,
    payload: form,
  };
};

export const userCollectionEditDataSuccess = (data) => {
  return {
    type: USER_COLLECTION_EDIT_SUCCESS,
    payload: data,
  };
};

export const userCollectionEditDataError = (error) => {
  return {
    type: USER_COLLECTION_EDIT_ERROR,
    payload: error,
  };
};

export const userCollectionEditDataReset = () => {
  return {
    type: USER_COLLECTION_EDIT_RESET,
  };
};

export const userCollectionDeleteNftData = (nftId) => {
  return {
    type: USER_COLLECTION_DELETE_NFT,
    payload: nftId,
  };
};

export const userCollectionDeleteNftDataSuccess = (data) => {
  return {
    type: USER_COLLECTION_DELETE_NFT_SUCCESS,
    payload: data,
  };
};

export const userCollectionDeleteNftDataError = (error) => {
  return {
    type: USER_COLLECTION_DELETE_NFT_ERROR,
    payload: error,
  };
};

export const userCollectionDeleteNftDataReset = (error) => {
  return {
    type: USER_COLLECTION_DELETE_NFT_RESET,
    payload: error,
  };
};

//Add Nft to Collection
export const userAddCollectionNft = (NftData) => {
  return {
    type: USER_ADD_NFT_COLLECTION,
    payload: NftData,
  };
};

export const userAddCollectionNftSuccess = (data) => {
  return {
    type: USER_ADD_NFT_COLLECTION_SUCCESS,
    payload: data,
  };
};

export const userAddCollectionNftError = (error) => {
  return {
    type: USER_ADD_NFT_COLLECTION_ERROR,
    payload: error,
  };
};

export const ResetAddCollectionNft = () => {
  return {
    type: USER_ADD_NFT_COLLECTION_RESET,
  };
};
