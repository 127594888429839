// actions.js
import {
  UPLOAD_NFT,
  UPLOAD_NFT_SUCCESS,
  UPLOAD_NFT_ERROR,
  RESET_UPLOAD_NFT,
  GENERATE_DESCRIPTION,
  GENERATE_DESCRIPTION_SUCCESS,
  GENERATE_DESCRIPTION_ERROR,
  SHORTEREN_DESCRIPTION,
  SHORTEREN_DESCRIPTION_SUCCESS,
  SHORTEREN_DESCRIPTION_ERROR,
  GET_USER_REFERRAL_PROMOTION,
  GET_USER_REFERRAL_PROMOTION_SUCCESS,
  GET_USER_REFERRAL_PROMOTION_ERROR,
  RESET_USER_REFERRAL_PROMOTION,
} from "./actionTypes";
//upload data
export const uploadNftData = (form) => {
  return {
    type: UPLOAD_NFT,
    payload: form,
  };
};
export const uploadNftDataSuccess = (data) => {
  return {
    type: UPLOAD_NFT_SUCCESS,
    payload: data,
  };
};
export const uploadNftDataError = (error) => {
  return {
    type: UPLOAD_NFT_ERROR,
    payload: error,
  };
};

export const ResetUploadNftData = () => {
  return {
    type: RESET_UPLOAD_NFT,
  };
};

//GENERATE_DESCRIPTION
export const Generate = (name) => {
  return {
    type: GENERATE_DESCRIPTION,
    payload: { name },
  };
};
export const GenerateSuccess = (data) => {
  return {
    type: GENERATE_DESCRIPTION_SUCCESS,
    payload: { name: data },
  };
};
export const GenerateError = (error) => {
  return {
    type: GENERATE_DESCRIPTION_ERROR,
    payload: error,
  };
};

//SHORTEREN_DESCRIPTION
export const Shorteren = (story) => {
  return {
    type: SHORTEREN_DESCRIPTION,
    payload: { story },
  };
};
export const ShorterenSuccess = (data) => {
  return {
    type: SHORTEREN_DESCRIPTION_SUCCESS,
    payload: data,
  };
};
export const ShorterenError = (error) => {
  return {
    type: SHORTEREN_DESCRIPTION_ERROR,
    payload: error,
  };
};

//Get User Referral Promotion
export const getUserReferralPromotion = () => {
  return {
    type: GET_USER_REFERRAL_PROMOTION,
  };
};
export const getUserReferralPromotionSuccess = (data) => {
  return {
    type: GET_USER_REFERRAL_PROMOTION_SUCCESS,
    payload: data,
  };
};
export const getUserReferralPromotionError = (error) => {
  return {
    type: GET_USER_REFERRAL_PROMOTION_ERROR,
    payload: error,
  };
};

export const resetUserReferralPromotion = () => {
  return {
    type: RESET_USER_REFERRAL_PROMOTION,
  };
};
