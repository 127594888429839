import {
  NEWS_LETTER_HOME,
  NEWS_LETTER_HOME_SUCCESS,
  NEWS_LETTER_HOME_ERROR,
  RESET_NEWS_LETTER_HOME,
} from "./actionTypes";

const initialState = {
  errorMsg: "",
  loading: false,
  error: false,
  success: null,
  news: [],
};

const NewsLetter = (state = initialState, action) => {
  switch (action.type) {
    case NEWS_LETTER_HOME:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        errorMsg: null,
      };
    case NEWS_LETTER_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        news: action.payload,
        errorMsg: null,
      };
    case NEWS_LETTER_HOME_ERROR:
      return {
        ...state,
        errorMsg: action.payload.data,
        loading: false,
        error: true,
        success: false,
      };
    case RESET_NEWS_LETTER_HOME:
      return {
        ...state,
        errorMsg: "",
        loading: false,
        error: false,
        success: null,
        news: [],
      };
    default:
      return state;
  }
};

export default NewsLetter;
