import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {  COVER_PROFILE } from "./actionTypes";
import { coverProfileSuccess, coverProfileError } from "./actions";
import { getCoverProfile } from "@/config/helpers/backend_helper";


function* CoverProfile({ payload: { userName } }) {
  try {
    const response = yield call(getCoverProfile, userName);
    console.log("Cover", response);
    yield put(coverProfileSuccess(response));
  } catch (error) {
    yield put(coverProfileError(error));
  }
}

export function* watchCoverProfile() {
  // yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(COVER_PROFILE, CoverProfile);
}

function* CoverProfileSaga() {
  yield all([fork(watchCoverProfile)]);
}

export default CoverProfileSaga;
