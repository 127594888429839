import { postAccountAddEthWallet } from "@/config/helpers/backend_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { postAddEthWalletError, postAddEthWalletSuccess } from "./actions";
import { POST_ADD_ETH_WALLET } from "./actionTypes";
// import { getMyProfile } from "../myprofile/actions";

function* fetchAddEThWallet({ payload: { data } }) {
  try {
    const response = yield call(postAccountAddEthWallet, data);
    yield put(postAddEthWalletSuccess(response));
  } catch (error) {
    yield put(postAddEthWalletError(error.response.data.Errors[0].Message));
  }
}

// function* fetchMergeEthWallet({ payload: { data } }) {
//   try {
//     const response = yield call(postAccountMergeEthWallet, data);
//     yield put(postMergeEthWalletSuccess(response));
//     if (response !== null && response !== undefined) {
//       yield put(getMyProfile(response.UserName));
//     }
//   } catch (error) {
//     yield put(postMergeEthWalletError(error));
//   }
// }

export function* EthWalletAccountSaga() {
  yield takeEvery(POST_ADD_ETH_WALLET, fetchAddEThWallet);
}

export default EthWalletAccountSaga;
