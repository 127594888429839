import {
  ACCOUNT_REPORT_NFT,
  ACCOUNT_REPORT_NFT_SUCCESS,
  ACCOUNT_REPORT_NFT_ERROR,
  RESET_ACCOUNT_REPORT_NFT,
} from "./actionTypes";

const initialState = {
  errorMsgReportNft: "",
  loadingReportNft: false,
  errorReportNft: false,
  successReportNft: null,
  ReportNftText: [],
};

const ReportNft = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_REPORT_NFT:
      state = {
        ...state,
        loadingReportNft: true,
        errorReportNft: false,
        successReportNft: false,
        errorMsgReportNft: null,
      };
      break;
    case ACCOUNT_REPORT_NFT_SUCCESS:
      state = {
        ...state,
        loadingReportNft: false,
        errorMsgReportNft: false,
        successReportNft: true,
        ReportNftText: action.payload,
      };
      break;
    case ACCOUNT_REPORT_NFT_ERROR:
      state = {
        ...state,
        errorMsgReportNft: action.payload.data,
        loadingReportNft: false,
        errorReportNft: true,
        successReportNft: false,
      };
      break;
    case RESET_ACCOUNT_REPORT_NFT:
      state = {
        ...state,
        errorMsgReportNft: "",
        loadingReportNft: false,
        errorReportNft: false,
        successReportNft: null,
        ReportNftText: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ReportNft;
