import {
  COVER_PROFILE,
  COVER_PROFILE_ERROR,
  COVER_PROFILE_SUCCESS,
  RESET_COVER_PROFILE,
} from "./actionTypes";

const initialState = {
  error: null,
  success: null,
  user: null,
  message: null,
  loading: null,
};

const coverprofile = (state = initialState, action) => {
  switch (action.type) {
    //COVER_PROFILE
    case COVER_PROFILE:
      state = {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
      break;
    case COVER_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        error: false,
        user: action.payload,
      };
      break;
    case COVER_PROFILE_ERROR:
      state = {
        ...state,
        loading: false,
        success: false,
        error: true,
        user: null,
        message: action.payload.error,
      };
      break;
    //RESET_FLAGS
    case RESET_COVER_PROFILE:
      state = {
        ...state,
        error: null,
        success: null,
        message: null,
        loading: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  // console.log("profile state:", state.user);
  return state;
};

export default coverprofile;
