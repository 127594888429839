// reducer.js
import {
  EXPLORE_REQUEST,
  EXPLORE_SUCCESS,
  EXPLORE_FAILURE,
  EXPLORE_SEARCH_REQUEST,
  EXPLORE_SEARCH_SUCCESS,
  EXPLORE_SEARCH_FAILURE,
  RESET_EXPLORE_SEARCH_REQUEST,
} from "./actionTypes";

const initialState = {
  loadingExplore: false,
  successExplore: false,
  errorExplore: null,
  nfts: [],
  loadingSearchExplore: false,
  successSearchExplore: false,
  errorSearchExplore: null,
  SearchNfts: [],
};
const Explore = (state = initialState, action) => {
  switch (action.type) {
    case EXPLORE_REQUEST:
      return {
        ...state,
        loadingExplore: true,
        successExplore: false,
        errorExplore: null,
      };
    case EXPLORE_SUCCESS:
      return {
        ...state,
        loadingExplore: false,
        successExplore: true,
        errorExplore: null,
        nfts: action.payload,
      };
    case EXPLORE_FAILURE:
    //search explore
    case EXPLORE_SEARCH_REQUEST:
      return {
        ...state,
        loadingSearchExplore: true,
        successSearchExplore: false,
        errorSearchExplore: null,
      };
    case EXPLORE_SEARCH_SUCCESS:
      return {
        ...state,
        loadingSearchExplore: false,
        successSearchExplore: true,
        errorSearchExplore: null,
        SearchNfts: action.payload,
      };
    case EXPLORE_SEARCH_FAILURE:
      return {
        ...state,
        loadingSearchExplore: false,
        successSearchExplore: false,
        errorSearchExplore: action.payload,
      };
    case RESET_EXPLORE_SEARCH_REQUEST:
      state = {
        ...state,
        loadingSearchExplore: false,
        errorSearchExplore: false,
        successSearchExplore: null,
        SearchNfts: [],
      };
    default:
      return state;
  }
};

export default Explore;
