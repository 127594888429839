// reducer.js
import {
    NOT_MARK_ALL_READ,
    NOT_MARK_ALL_READ_SUCCESS,
    NOT_MARK_ALL_READ_FAILURE,
  } from "./actionTypes";
  
  const initialState = {
    loadingMarkAllRead: false,
    successMarkAllRead: false,
    errorMarkAllRead: null,
    NotifsVisited: [],
  };
  const MarkAllRead = (state = initialState, action) => {
    switch (action.type) {
      case NOT_MARK_ALL_READ:
        return {
          ...state,
          loadingMarkAllRead: true,
          successMarkAllRead: false,
          errorMarkAllRead: null,
        };
      case NOT_MARK_ALL_READ_SUCCESS:
        return {
          ...state,
          loadingMarkAllRead: false,
          successMarkAllRead: true,
          errorMarkAllRead: null,
          NotifsVisited: action.payload,
        };
      case NOT_MARK_ALL_READ_FAILURE:
        return {
          ...state,
          loadingMarkAllRead: false,
          successMarkAllRead: false,
          errorMarkAllRead: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default MarkAllRead;
  