// actions.js
import {
  ACCOUNT_REPORT_NFT,
  ACCOUNT_REPORT_NFT_SUCCESS,
  ACCOUNT_REPORT_NFT_ERROR,
  RESET_ACCOUNT_REPORT_NFT,
} from "./actionTypes";

export const ReportNft = (text) => {
  return {
    type: ACCOUNT_REPORT_NFT,
    payload: { text },
  };
};

export const ReportNftSuccess = (data) => {
  return {
    type: ACCOUNT_REPORT_NFT_SUCCESS,
    payload: data,
  };
};

export const ReportNftError = (error) => {
  return {
    type: ACCOUNT_REPORT_NFT_ERROR,
    payload: error,
  };
};

export const ResetReportNft = () => {
  return {
    type: RESET_ACCOUNT_REPORT_NFT,
  };
};
