import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getHomeTestimonials } from "@/config/helpers/backend_helper";
import { testimonialDataSuccess, testimonialDataError } from "./actions";
import { TESTIMONIAL_HOME } from "./actionTypes";

function* fetchtesTestimonialData() {
  try {
    const response = yield call(getHomeTestimonials);

    yield put(testimonialDataSuccess(response));
  } catch (error) {
    yield put(testimonialDataError(error));
  }
}

function* TestimonialSaga() {
  yield takeLatest(TESTIMONIAL_HOME, fetchtesTestimonialData);
}

export default TestimonialSaga;
