import {
  EXCLUSIVE_COLLECTIONS_HOME,
  EXCLUSIVE_COLLECTIONS_HOME_ERROR,
  EXCLUSIVE_COLLECTIONS_HOME_SUCCESS,
  POST_EXCLUSIVE_COLLECTIONS_HOME,
  POST_EXCLUSIVE_COLLECTIONS_HOME_ERROR,
  POST_EXCLUSIVE_COLLECTIONS_HOME_SUCCESS,
  RESET_EXCLUSIVE_COLLECTIONS_HOME,
  RESET_POST_EXCLUSIVE_COLLECTIONS_HOME,
} from "./actionTypes";

//ExclusiveCollections data
export const ExclusiveData = () => {
  return {
    type: EXCLUSIVE_COLLECTIONS_HOME,
  };
};

export const ExclusiveDataSuccess = (data) => {
  return {
    type: EXCLUSIVE_COLLECTIONS_HOME_SUCCESS,
    payload: data,
  };
};

export const ExclusiveDataError = (data) => {
  return {
    type: EXCLUSIVE_COLLECTIONS_HOME_ERROR,
    payload: data,
  };
};

//ExclusiveCollections data (Post)
export const postExclusiveData = (data) => {
  return {
    type: POST_EXCLUSIVE_COLLECTIONS_HOME,
    payload: { data },
  };
};

export const postExclusiveDataSuccess = (data) => {
  return {
    type: POST_EXCLUSIVE_COLLECTIONS_HOME_SUCCESS,
    payload: data,
  };
};

export const postExclusiveDataError = (data) => {
  return {
    type: POST_EXCLUSIVE_COLLECTIONS_HOME_ERROR,
    payload: data,
  };
};

//reset states Post
export const resetPostExclusiveStates = () => {
  return {
    type: RESET_POST_EXCLUSIVE_COLLECTIONS_HOME,
  };
};

//reset states
export const resetExclusiveStates = () => {
  return {
    type: RESET_EXCLUSIVE_COLLECTIONS_HOME,
  };
};
