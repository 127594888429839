// actions.js
import {
  NOTIFICATION_SETTINGS,
  NOTIFICATION_SETTINGS_SUCCESS,
  NOTIFICATION_SETTINGS_ERROR,
  RESET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_ERROR,
} from "./actionTypes";

export const NotificationSettingsData = () => {
  return {
    type: NOTIFICATION_SETTINGS,
  };
};

export const NotificationSettingsDataSuccess = (data) => {
  return {
    type: NOTIFICATION_SETTINGS_SUCCESS,
    payload: data,
  };
};

export const NotificationSettingsDataError = (data) => {
  return {
    type: NOTIFICATION_SETTINGS_ERROR,
    payload: data,
  };
};

export const ResetNotificationSettings = () => {
  return {
    type: RESET_NOTIFICATION_SETTINGS,
  };
};

export const updateNotificationSettingsData = (data) => {
  return {
    type: UPDATE_NOTIFICATION_SETTINGS,
    payload: data,
  };
};

export const updateNotificationSettingsDataSuccess = () => {
  return {
    type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  };
};

export const updateNotificationSettingsDataError = (error) => {
  return {
    type: UPDATE_NOTIFICATION_SETTINGS_ERROR,
    payload: error,
  };
};
