import {
  GET_PROFILE,
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  EDIT_PROFILE,
  EDIT_PROFILE_ERROR,
  EDIT_PROFILE_SUCCESS,
  CHECK_FOLLOW_USER,
  CHECK_FOLLOW_USER_SUCCESS,
  CHECK_FOLLOW_USER_ERROR,
  RESET_EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  RESET_ALL_PROFILE_DATA_FLAG,
  RESET_CHECK_FOLLOW_DATA_FLAG,
} from "./actionTypes";

//editProfile
export const editProfile = (user) => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  };
};

export const editProfileSuccess = (user) => {
  return {
    type: EDIT_PROFILE_SUCCESS,
    payload: { user },
  };
};

export const editProfileError = (user) => {
  return {
    type: EDIT_PROFILE_ERROR,
    payload: { user },
  };
};

//GET_NFT_CHECK_DETAILS
export const checkFollowUser = (Id) => {
  return {
    type: CHECK_FOLLOW_USER,
    payload: { Id },
  };
};

export const checkFollowUserSuccess = (data) => {
  return {
    type: CHECK_FOLLOW_USER_SUCCESS,
    payload: data,
  };
};

export const checkFollowUserError = (error) => {
  return {
    type: CHECK_FOLLOW_USER_ERROR,
    payload: error,
  };
};

export const ResetEditProfile = () => {
  return {
    type: RESET_EDIT_PROFILE,
  };
};

//getProfile
export const getProfile = (user) => {
  return {
    type: GET_PROFILE,
    payload: { user },
  };
};

export const getProfileSuccess = (user) => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: user,
  };
};

export const getProfileError = (error) => {
  return {
    type: GET_PROFILE_ERROR,
    payload: error,
  };
};

//resetFlags
export const resetProfileFlag = () => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};

export const resetAllProfileDataFlag = () => {
  return {
    type: RESET_ALL_PROFILE_DATA_FLAG,
  };
};

export const resetCheckFollowDataFlag = () => {
  return {
    type: RESET_CHECK_FOLLOW_DATA_FLAG,
  };
};
