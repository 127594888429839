// actions.js
import {
  MUSEUM_AUCTION,
  MUSEUM_AUCTION_SUCCESS,
  MUSEUM_AUCTION_ERROR,
} from "./actionTypes";

//current week
export const museumAuction = () => {
  return {
    type: MUSEUM_AUCTION,
  };
};

export const museumAuctionSuccess = (data) => {
  return {
    type: MUSEUM_AUCTION_SUCCESS,
    payload: data,
  };
};

export const museumAuctionError = (data) => {
  return {
    type: MUSEUM_AUCTION_ERROR,
    payload: data,
  };
};
