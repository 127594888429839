import {
  ACCOUNT_COLLECTION_NFTS_REQUEST,
  ACCOUNT_COLLECTION_NFTS_SUCCESS,
  ACCOUNT_COLLECTION_NFTS_FAILURE,
  RESET_ACCOUNT_COLLECTION_NFTS_FAILURE
} from "./actionTypes";

export const AccountCollectionNftsRequest = (requestData) => ({
  type: ACCOUNT_COLLECTION_NFTS_REQUEST,
  payload: requestData,
});

export const AccountCollectionNftsSuccess = (data) => ({
  type: ACCOUNT_COLLECTION_NFTS_SUCCESS,
  payload: data,
});

export const AccountCollectionNftsFailure = (error) => ({
  type: ACCOUNT_COLLECTION_NFTS_FAILURE,
  payload: error,
});

export const ResetAccountCollectionNftsFailure = () => ({
  type: RESET_ACCOUNT_COLLECTION_NFTS_FAILURE,
});
