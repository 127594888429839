// actions.js
import {
  NEWS_LETTER_HOME,
  NEWS_LETTER_HOME_SUCCESS,
  NEWS_LETTER_HOME_ERROR,
  RESET_NEWS_LETTER_HOME
} from "./actionTypes";

export const NewsLetterData = (Email) => {
  return {
    type: NEWS_LETTER_HOME,
    payload: Email,
  };
};

export const NewsLetterDataSuccess = (data) => {
  return {
    type: NEWS_LETTER_HOME_SUCCESS,
    payload: data,
  };
};

export const NewsLetterDataError = (data) => {
  return {
    type: NEWS_LETTER_HOME_ERROR,
    payload: data,
  };
};
export const ResetNewsLetterData = () => {
  return {
    type: RESET_NEWS_LETTER_HOME,
  };
};
