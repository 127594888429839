import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  getHomeHeroSection,
  getHomeMintedNftCount,
} from "@/config/helpers/backend_helper";

import {
  heroSectionDataSuccess,
  heroSectionDataError,
  mintedNftCountDataSuccess,
  mintedNftCountDataError,
} from "./actions";

import { HERO_SECTION_HOME, MINTED_NFT_COUNT_HOME } from "./actionTypes";

function* fetchHeroSectionData() {
  try {
    // if (process.env.REACT_APP_API_URL) {
    const response = yield call(getHomeHeroSection);
    yield put(heroSectionDataSuccess(response));
  } catch (error) {
    yield put(heroSectionDataError(error));
  }
}

function* fetchMintedNftCountData() {
  try {
    // if (process.env.REACT_APP_API_URL) {
    const response = yield call(getHomeMintedNftCount);
    yield put(mintedNftCountDataSuccess(response));
  } catch (error) {
    yield put(mintedNftCountDataError(error));
  }
}

function* heroSectionSaga() {
  yield takeLatest(HERO_SECTION_HOME, fetchHeroSectionData);
  yield takeLatest(MINTED_NFT_COUNT_HOME, fetchMintedNftCountData);
}

export default heroSectionSaga;
