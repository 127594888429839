import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getNFTCID, postLogMintNft } from "@/config/helpers/backend_helper";
import {
  nftsNftCidSuccess,
  nftsNftCidError,
  nftsLogMintNftSuccess,
  nftsLogMintNftError,
} from "./action";
import { NFTS_NFT_CID, NFTS_LOG_MINT_NFT } from "./actionTypes";

function* fetchNftCid({ payload: { data } }) {
  try {
    const response = yield call(getNFTCID, data);
    yield put(nftsNftCidSuccess(response));
  } catch (error) {
    yield put(nftsNftCidError(error.response.data.Errors[0].Message));
  }
}

function* logMintNft({ payload: { data } }) {
  try {
    const response = yield call(postLogMintNft, data);
    yield put(nftsLogMintNftSuccess(response));
  } catch (error) {
    yield put(nftsLogMintNftError(error.response.data.Errors[0].Message));
  }
}

function* web3ActionSaga() {
  yield takeLatest(NFTS_NFT_CID, fetchNftCid);
  yield takeLatest(NFTS_LOG_MINT_NFT, logMintNft);
}

export default web3ActionSaga;
