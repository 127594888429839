import { call, put, takeEvery } from "redux-saga/effects";
import { getMuseumHeroSection } from "@/config/helpers/backend_helper";
import { museumWelcomeDataSuccess, museumWelcomeDataError } from "./actions";
import { MUSEUM_WELCOME } from "./actionTypes";

function* fetchMuseumWelcomeData() {
  try {
    const response = yield call(getMuseumHeroSection);

    yield put(museumWelcomeDataSuccess(response));
  } catch (error) {
    yield put(museumWelcomeDataError(error));
  }
}

function* museumWelcomeSaga() {
  yield takeEvery(MUSEUM_WELCOME, fetchMuseumWelcomeData);
}

export default museumWelcomeSaga;
