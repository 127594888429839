// actionTypes.js
export const USER_COLLECTION_NFTS = "USER_COLLECTION_NFTS";
export const USER_COLLECTION_NFTS_SUCCESS = "USER_COLLECTION_NFTS_SUCCESS";
export const USER_COLLECTION_NFTS_ERROR = "USER_COLLECTION_NFTS_ERROR";
export const USER_COLLECTION_NFTS_RESET = "USER_COLLECTION_NFTS_RESET";

export const USER_COLLECTION_DELETE = "USER_COLLECTION_DELETE";
export const USER_COLLECTION_DELETE_SUCCESS = "USER_COLLECTION_DELETE_SUCCESS";
export const USER_COLLECTION_DELETE_ERROR = "USER_COLLECTION_DELETE_ERROR";

export const USER_COLLECTION_EDIT = "USER_COLLECTION_EDIT";
export const USER_COLLECTION_EDIT_SUCCESS = "USER_COLLECTION_EDIT_SUCCESS";
export const USER_COLLECTION_EDIT_ERROR = "USER_COLLECTION_EDIT_ERROR";
export const USER_COLLECTION_EDIT_RESET = "USER_COLLECTION_EDIT_RESET";

export const USER_COLLECTION_DELETE_NFT = "USER_COLLECTION_DELETE_NFT";
export const USER_COLLECTION_DELETE_NFT_SUCCESS =
  "USER_COLLECTION_DELETE_NFT_SUCCESS";
export const USER_COLLECTION_DELETE_NFT_ERROR =
  "USER_COLLECTION_DELETE_NFT_ERROR";

export const USER_COLLECTION_DELETE_NFT_RESET =
  "USER_COLLECTION_DELETE_NFT_RESET";

export const USER_ADD_NFT_COLLECTION = "USER_ADD_NFT_COLLECTION";
export const USER_ADD_NFT_COLLECTION_SUCCESS =
  "USER_ADD_NFT_COLLECTION_SUCCESS";
export const USER_ADD_NFT_COLLECTION_ERROR = "USER_ADD_NFT_COLLECTION_ERROR";
export const USER_ADD_NFT_COLLECTION_RESET = "USER_ADD_NFT_COLLECTION_RESET";
