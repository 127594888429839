import {
  HOT_COLLECTIONS_HOME,
  HOT_COLLECTIONS_HOME_SUCCESS,
  HOT_COLLECTIONS_HOME_ERROR,
  RESET_HOT_COLLECTIONS_HOME,
} from "./actionTypes";

const initialState = {
  errorMsgHotNfts: "",
  loadingHotNfts: false,
  errorHotNfts: false,
  successHotNfts: null,
  nfts: null,
};

const HotCollections = (state = initialState, action) => {
  switch (action.type) {
    //HOT_COLLECTIONS_HOME
    case HOT_COLLECTIONS_HOME:
      state = {
        ...state,
        loadingHotNfts: true,
        errorHotNfts: false,
        successHotNfts: false,
        errorMsgHotNfts: null,
      };
      break;
    case HOT_COLLECTIONS_HOME_SUCCESS:
      state = {
        ...state,
        loadingHotNfts: false,
        errorMsgHotNfts: false,
        successHotNfts: true,
        nfts: action.payload,
      };
      break;
    case HOT_COLLECTIONS_HOME_ERROR:
      state = {
        ...state,
        errorMsgHotNfts: action.payload.data,
        loadingHotNfts: false,
        errorHotNfts: true,
        successHotNfts: false,
      };
      break;
    case RESET_HOT_COLLECTIONS_HOME:
      state = {
        ...state,
        errorMsgHotNfts: "",
        loadingHotNfts: false,
        errorHotNfts: false,
        successHotNfts: null,
        nfts: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default HotCollections;
