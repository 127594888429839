// actions.js
import {
  MUSEUM_HOME,
  MUSEUM_HOME_SUCCESS,
  MUSEUM_HOME_ERROR,
  RESET_MUSEUM_HOME,
} from "./actionTypes";

export const MuseumData = () => {
  return {
    type: MUSEUM_HOME,
  };
};

export const MuseumDataSuccess = (data) => {
  return {
    type: MUSEUM_HOME_SUCCESS,
    payload: data,
  };
};

export const MuseumDataError = (data) => {
  return {
    type: MUSEUM_HOME_ERROR,
    payload: data,
  };
};

export const ResetMuseum = () => {
  return {
    type: RESET_MUSEUM_HOME,
  };
};
