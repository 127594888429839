// actions.js
import {
  EVENTS_DATA,
  EVENTS_DATA_SUCCESS,
  EVENTS_DATA_ERROR,
  RESET_EVENTS_DATA,
} from "./actionTypes";

export const eventsData = () => {
  return {
    type: EVENTS_DATA,
  };
};

export const eventsDataSuccess = (data) => {
  return {
    type: EVENTS_DATA_SUCCESS,
    payload: data,
  };
};

export const eventsDataError = (error) => {
  return {
    type: EVENTS_DATA_ERROR,
    payload: error,
  };
};

export const ResetEventsData = () => {
  return {
    type: RESET_EVENTS_DATA,
  };
};
