import { takeLatest, call, put } from "redux-saga/effects";
import {
  CREATED_NFTCARD_REQUEST,
  CREATED_NFTCARD_SUCCESS,
  CREATED_NFTCARD_FAILURE,
} from "./actionTypes";
import { CreatedNftCardSuccess, CreatedNftCardFailure } from "./action";
import { getNftCards } from "@/config/helpers/backend_helper";

function* CreatedNftCard(action) {
  try {
    //   console.log(action.payload);
    const response = yield call(getNftCards, action.payload);
    //  console.log(response);
    yield put(CreatedNftCardSuccess(response));
  } catch (error) {
    yield put(CreatedNftCardFailure(error));
  }
}

function* CreatedNftCardSaga() {
  yield takeLatest(CREATED_NFTCARD_REQUEST, CreatedNftCard);
}

export default CreatedNftCardSaga;
