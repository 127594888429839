// actions.js
import {
  INSPIRE_REQUEST,
  INSPIRE_SUCCESS,
  INSPIRE_FAILURE,
} from "./actionTypes";

export const inspireRequest = (requestData) => ({
  type: INSPIRE_REQUEST,
  payload: requestData,
});

export const inspireSuccess = (data) => ({
  type: INSPIRE_SUCCESS,
  payload: data,
});

export const inspireFailure = (error) => ({
  type: INSPIRE_FAILURE,
  payload: error,
});
