// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import {
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILURE,
} from "./actionTypes";
import { sendNotificationSuccess, sendNotificationFailure } from "./action";
import { getAllNotificationList } from "@/config/helpers/backend_helper";

function* sendNotification(action) {
  try {
    const response = yield call(getAllNotificationList, action.payload);
    // console.log(response);
    yield put(sendNotificationSuccess(response));
  } catch (error) {
    yield put(sendNotificationFailure(error));
  }
}

function* notificationSaga() {
  yield takeLatest(SEND_NOTIFICATION_REQUEST, sendNotification);
}

export default notificationSaga;
