// actions.js
import {
  GET_SETTING_CATEGORIES,
  GET_SETTING_CATEGORIES_SUCCESS,
  GET_SETTING_CATEGORIES_ERROR,
} from "./actionTypes";

export const getSettingCategories = () => ({
  type: GET_SETTING_CATEGORIES,
});

export const getSettingCategoriesSuccess = (data) => ({
  type: GET_SETTING_CATEGORIES_SUCCESS,
  payload: data,
});

export const getSettingCategoriesError = (error) => ({
  type: GET_SETTING_CATEGORIES_ERROR,
  payload: error,
});
