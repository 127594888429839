// reducer.js
import {
  GET_ENUMS,
  GET_ENUMS_SUCCESS,
  GET_ENUMS_ERROR,
} from "./actionTypes";

const initialState = {
  loadingEnums: false,
  successEnums: false,
  errorEnums: false,
  MessageEnums : null,
  Enums: [],
};
const GetEnums = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENUMS:
      return {
        ...state,
        loadingEnums: true,
        successEnums: false,
        errorEnums: false,
      };
    case GET_ENUMS_SUCCESS:
      return {
        ...state,
        loadingEnums: false,
        successEnums: true,
        errorEnums: false,
        Enums: action.payload,
      };
    case GET_ENUMS_ERROR:
      return {
        ...state,
        loadingEnums: false,
        successEnums: false,
        errorEnums: true,
        MessageEnums:action.payload,
      };
    default:
      return state;
  }
};

export default GetEnums;
