// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_MY_TRANSACTIONS,
  GET_MY_TRANSACTIONS_SUCCESS,
  GET_MY_TRANSACTIONS_FAILURE,
} from "./actionTypes";
import { myTransactionsSuccess, myTransactionsFailure } from "./action";
import { getAccountGetMyTransaction } from "@/config/helpers/backend_helper";

function* MyTransactions(action) {
  try {
    // console.log(action.payload);
    const response = yield call(getAccountGetMyTransaction, action.payload);
    //   console.log(response);
    yield put(myTransactionsSuccess(response));
  } catch (error) {
    yield put(myTransactionsFailure(error));
  }
}

function* MyTransactionsSaga() {
  yield takeLatest(GET_MY_TRANSACTIONS, MyTransactions);
}

export default MyTransactionsSaga;
