// saga.js
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getNftOrderConfirmation } from "@/config/helpers/backend_helper";
import {
  orderconfirmationDataSuccess,
  orderconfirmationDataError,
} from "./action";
import { ORDER_CONFIRMATION } from "./actionTypes";

function* fetchUserCollectionNftData({ payload: { slagId } }) {
  try {
    const response = yield call(getNftOrderConfirmation, slagId);

    yield put(orderconfirmationDataSuccess(response));
  } catch (error) {
    yield put(orderconfirmationDataError(error));
  }
}

function* OrderConfirmationSaga() {
  yield takeLatest(ORDER_CONFIRMATION, fetchUserCollectionNftData);
}

export default OrderConfirmationSaga;
