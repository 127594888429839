import axios from "axios";
import { api_stage } from "../config";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const setItem = (key, value) => {
  return new Promise((resolve) => {
    localStorage.setItem(key, JSON.stringify(value));
    resolve();
  });
};

const setItemCookie = (name, value, remove = false) => {
  var myDate = new Date();
  myDate.setDate(myDate.getDate() + 1);
  // console.log("myDate", myDate);
  document.cookie = `${name} = ${
    remove ? value : JSON.stringify(value)
  };expires=${remove ? 0 : myDate};domain=${
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("localhost")
      ? "localhost"
      : //  ? "192.168.234.104"
      process.env.NEXT_PUBLIC_URL.toLowerCase().includes("seedphoto")
      ? ".seedphoto.dev"
      : ".seed.photo"
  };path=/`;
};

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// const removeCookie = (name) => {
//   document.cookie = `${name}=;${0};`
// };

class APIClient {
  constructor() {
    // default
    // axios.defaults.baseURL = api_stage.API_URL_STAGE1;
    if (
      process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage1") ||
      process.env.NEXT_PUBLIC_URL.toLowerCase().includes("localhost")
    ) {
      axios.defaults.baseURL = api_stage.API_URL_STAGE1;
    } else if (process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage2")) {
      axios.defaults.baseURL = api_stage.API_URL_STAGE2;
    } else if (process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage3")) {
      axios.defaults.baseURL = api_stage.API_URL_STAGE3;
    } else {
      axios.defaults.baseURL = api_stage.API_URL_MAIN;
    }

    // content type
    axios.defaults.headers.post["Content-Type"] = "application/json";

    if (typeof window !== "undefined") {
      this.setToken();
    }

    // intercepting to capture errors
    axios.interceptors.response.use(function (response) {
      return response.data ? response.data : response;
    });
  }

  // setToken() {
  //   this.token = JSON.parse(localStorage.getItem("authUser"))
  //     ? JSON.parse(localStorage.getItem("authUser")).Token
  //     : null;
  //   if (this.token)
  //     axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
  //   console.log("authentication", getCookie("authentication"));
  //   this.config = {
  //     headers: {
  //       "Cache-Control": "no-cache",
  //       "Content-Type": "application/json",
  //       "Content-Security-Policy": `default-src 'self' http: https: ${process.env.NEXT_PUBLIC_URL.toLowerCase()} form.typeform.com verify.walletconnect.org api.web3modal.com; script-src 'self' ${process.env.NEXT_PUBLIC_URL.toLowerCase()} cdnjs.cloudflare.com static.cloudflareinsights.com cdn.jsdelivr.net/ghost/ form.typeform.com verify.walletconnect.org api.web3modal.com; style-src 'self' 'report-sample' 'unsafe-inline' fonts.googleapis.com cdnjs.cloudflare.com form.typeform.com verify.walletconnect.org; img-src 'self' data: www.gravatar.com links.services.disqus.com referrer.disqus.com c.disquscdn.com cdn.syndication.twimg.com syndication.twitter.com pbs.twimg.com platform.twitter.com abs.twimg.com www.google-analytics.com; child-src www.instagram.com twitter.com fusiontables.googleusercontent.com fusiontables.google.com www.google.com disqus.com www.youtube.com syndication.twitter.com platform.twitter.com www.youtube-nocookie.com js.stripe.com; connect-src 'self' syndication.twitter.com links.services.disqus.com scotthelme.ghost.io cloudflareinsights.com *.privacymanager.io; font-src 'self' cdnjs.cloudflare.com fonts.gstatic.com fonts.googleapis.com; form-action 'self' syndication.twitter.com; frame-ancestors 'none'; object-src 'none'; base-uri 'none';`,
  //       "Permissions-Policy": `geolocation=(self), accelerometer=(), camera=(), gyroscope=(), magnetometer=(), microphone=(), payment=(), usb=(), interest-cohort=()`,
  //       Authorization: "Bearer " + this.token,
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Credentials": true,
  //       "Access-Control-Allow-Methods": ["GET", "POST", "OPTIONS"],
  //     },
  //   };
  // }

  setToken() {
    this.token =
      getCookie("authentication") !== ""
        ? JSON.parse(getCookie("authentication")).Token
        : null;
    if (this.token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;
    // console.log(
    //   "authentication",
    //   getCookie("authentication") !== "" &&
    //     JSON.parse(getCookie("authentication"))
    // );
    this.config = {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Content-Security-Policy": `default-src 'self' http: https: ${process.env.NEXT_PUBLIC_URL.toLowerCase()} form.typeform.com verify.walletconnect.org api.web3modal.com; script-src 'self' ${process.env.NEXT_PUBLIC_URL.toLowerCase()} cdnjs.cloudflare.com static.cloudflareinsights.com cdn.jsdelivr.net/ghost/ form.typeform.com verify.walletconnect.org api.web3modal.com; style-src 'self' 'report-sample' 'unsafe-inline' fonts.googleapis.com cdnjs.cloudflare.com form.typeform.com verify.walletconnect.org; img-src 'self' data: www.gravatar.com links.services.disqus.com referrer.disqus.com c.disquscdn.com cdn.syndication.twimg.com syndication.twitter.com pbs.twimg.com platform.twitter.com abs.twimg.com www.google-analytics.com; child-src www.instagram.com twitter.com fusiontables.googleusercontent.com fusiontables.google.com www.google.com disqus.com www.youtube.com syndication.twitter.com platform.twitter.com www.youtube-nocookie.com js.stripe.com; connect-src 'self' syndication.twitter.com links.services.disqus.com scotthelme.ghost.io cloudflareinsights.com *.privacymanager.io; font-src 'self' cdnjs.cloudflare.com fonts.gstatic.com fonts.googleapis.com; form-action 'self' syndication.twitter.com; frame-ancestors 'none'; object-src 'none'; base-uri 'none';`,
        "Permissions-Policy": `geolocation=(self), accelerometer=(), camera=(), gyroscope=(), magnetometer=(), microphone=(), payment=(), usb=(), interest-cohort=()`,
        Authorization: "Bearer " + this.token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": ["GET", "POST", "OPTIONS"],
      },
    };
  }

  setTokenMimeImage() {
    this.token =
      getCookie("authentication") !== ""
        ? JSON.parse(getCookie("authentication")).Token
        : null;
    if (this.token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

    this.configMime = {
      headers: {
        "content-type": "image/png",
        Authorization: "Bearer " + this.token,
      },
    };
  }

  setTokenMultiPart() {
    this.token =
      getCookie("authentication") !== ""
        ? JSON.parse(getCookie("authentication")).Token
        : null;
    if (this.token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

    this.config2 = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + this.token,
      },
    };
  }

  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  async get(url, params) {
    let response;

    let paramKeys = [];
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      // console.log("this token", this.token);
      if (this.token != null) {
        response = axios.get(`${url}`, this.config);
      } else {
        if (this.token != null) {
          response = axios.get(`${url}`, this.config);
        } else {
          this.setToken();
          response = axios.get(`${url}`, this.config);
        }
      }
    }

    return response;
  }

  async getWithNoToken(url, params) {
    let response;

    let paramKeys = [];
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios
        .get(`${url}?${queryString}`, params)
        .then((res) => {
          try {
            return res;
          } catch (error) {
            console.error(error);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      response = axios
        .get(`${url}`)
        .then((res) => {
          try {
            return res;
          } catch (error) {
            console.error(error);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    return response;
  }

  getBlob = (url, params) => {
    this.setTokenMimeImage();
    if (this.token !== null) {
      return axios
        .get(`${url}`, this.configMime, { responseType: "arraybuffer" })
        .then((res) => {
          try {
            // console.log("responseType", res);
            return res;
          } catch (error) {
            console.error(error);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      this.setToken();
      return axios
        .get(`${url}`, this.configMime, { responseType: "arraybuffer" })
        .then((res) => {
          try {
            // console.log("responseType", res.headers);
            return res;
          } catch (error) {
            console.error(error);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    // console.log(data);
    if (typeof window !== "undefined") {
      this.setToken();
    }
    return axios.post(url, data, this.config);
    // .then((res) => {
    //   try {
    //     return res;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // })
    // .catch((err) => {
    //   console.log("err", err);
    // });
  };

  //web3
  async create2(url, data, onSuccess, onFailure = null) {
    // console.log(data);
    if (typeof window !== "undefined") {
      this.setToken();
    }
    return axios
      .post(url, data, this.config)
      .then((res) => {
        try {
          onSuccess();
          return res;
        } catch (error) {
          console.error(error);
        }
      })
      .catch((err) => {
        console.log("err", err);
        if (onFailure !== null) {
          onFailure();
        }
      });
  }

  createMultiPart = (url, data) => {
    this.setTokenMultiPart();
    return axios.post(url, data, this.config2);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    this.setToken();
    return axios.delete(url, this.config);
  };
}
const getLoggedinUser = () => {
  const user = getCookie("authentication");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export {
  APIClient,
  setAuthorization,
  getLoggedinUser,
  setItem,
  setItemCookie,
  getCookie,
};
