// actions.js
import {
  GET_ACCOUNT_FOLLOWNIGS,
  GET_ACCOUNT_FOLLOWNIGS_SUCCESS,
  GET_ACCOUNT_FOLLOWNIGS_FAILURE,
} from "./actionTypes";

export const followingRequest = (userName, requestData) => ({
  type: GET_ACCOUNT_FOLLOWNIGS,
  payload: { userName, requestData },
});
export const followingSuccess = (data) => ({
  type: GET_ACCOUNT_FOLLOWNIGS_SUCCESS,
  payload: data,
});

export const followingFailure = (error) => ({
  type: GET_ACCOUNT_FOLLOWNIGS_FAILURE,
  payload: error,
});
