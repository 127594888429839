// reducer.js
import {
  GET_ACCOUNT_REFERRALS,
  GET_ACCOUNT_REFERRALS_SUCCESS,
  GET_ACCOUNT_REFERRALS_FAILURE,
} from "./actionTypes";

const initialState = {
  loadingReferral: false,
  successReferral: false,
  errorReferral: null,
  Referral: [],
};
const Referral = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_REFERRALS:
      return {
        ...state,
        loadingReferral: true,
        successReferral: false,
        errorReferral: null,
      };
    case GET_ACCOUNT_REFERRALS_SUCCESS:
      return {
        ...state,
        loadingReferral: false,
        successReferral: true,
        errorReferral: null,
        Referral: action.payload,
      };
    case GET_ACCOUNT_REFERRALS_FAILURE:
      return {
        ...state,
        loadingReferral: false,
        successReferral: false,
        errorReferral: action.payload,
      };
    default:
      return state;
  }
};

export default Referral;
