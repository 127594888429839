import {
  COMMUNITIES_HOME,
  COMMUNITIES_HOME_SUCCESS,
  COMMUNITIES_HOME_ERROR,
} from "./actionTypes";

export const communitiesData = () => {
  return {
    type: COMMUNITIES_HOME,
  };
};

export const communitiesDataSuccess = (data) => {
  return {
    type: COMMUNITIES_HOME_SUCCESS,
    payload: data,
  };
};

export const communitiesDataError = (error) => {
  return {
    type: COMMUNITIES_HOME_ERROR,
    payload: error,
  };
};
