// reducer.js
import {
  GET_NFT_HISTORY,
  GET_NFT_HISTORY_SUCCESS,
  GET_NFT_HISTORY_ERROR,
  RESRT_NFT_HISTORY,
} from "./actionTypes";

const initialState = {
  loading: false,
  success: false,
  error: null,
  history: null,
};
const GetNftHistory = (state = initialState, action) => {
  switch (action.type) {
    case GET_NFT_HISTORY:
      state = {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
      break;
    case GET_NFT_HISTORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        error: null,
        history: action.payload,
      };
      break;
    case GET_NFT_HISTORY_ERROR:
      state = {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
      break;
    case RESRT_NFT_HISTORY:
      state = {
        ...state,
        loading: false,
        success: false,
        error: null,
        history: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GetNftHistory;
