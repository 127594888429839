import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { getHomeRecentlySold } from "@/config/helpers/backend_helper";

import { RecentlyDataSuccess, RecentlyDataError } from "./actions";

import { RECENTLY_SOLD_HOME } from "./actionTypes";

function* fetchRecentlySoldData() {
  try {
    const response = yield call(getHomeRecentlySold);
    yield put(RecentlyDataSuccess(response));
  } catch (error) {
    yield put(RecentlyDataError(error));
  }
}

function* RecentlySoldSaga() {
  yield takeLatest(RECENTLY_SOLD_HOME, fetchRecentlySoldData);
}

export default RecentlySoldSaga;
