// reducer.js
import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
} from "./actionTypes";

const initialState = {
  loadingSettings: false,
  successSettings: false,
  errorSettings: false,
  MessageSettings: null,
  Settings: [],
};
const GetSettings = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        loadingSettings: true,
        successSettings: false,
        errorSettings: null,
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        successSettings: true,
        errorSettings: null,
        Settings: action.payload,
      };
    case GET_SETTINGS_ERROR:
      return {
        ...state,
        loadingSettings: false,
        successSettings: false,
        errorSettings: true,
        MessageSettings: action.payload,
      };
    default:
      return state;
  }
};

export default GetSettings;
