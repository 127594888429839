// actions.js
import {
  TOP_PHOTOGRAPHERS_HOME,
  TOP_PHOTOGRAPHERS_HOME_SUCCESS,
  TOP_PHOTOGRAPHERS_HOME_ERROR,
  TOP_PHOTOGRAPHERS_PAGE_REFERRALS,
  TOP_PHOTOGRAPHERS_PAGE_REFERRALS_SUCCESS,
  TOP_PHOTOGRAPHERS_PAGE_REFERRALS_ERROR,
  TOP_PHOTOGRAPHERS_PAGE_SELL,
  TOP_PHOTOGRAPHERS_PAGE_SELL_SUCCESS,
  TOP_PHOTOGRAPHERS_PAGE_SELL_ERROR,
  TOP_PHOTOGRAPHERS_PAGE_MINTED,
  TOP_PHOTOGRAPHERS_PAGE_MINTED_SUCCESS,
  TOP_PHOTOGRAPHERS_PAGE_MINTED_ERROR,
  TOP_PHOTOGRAPHERS_PAGE_LISTED,
  TOP_PHOTOGRAPHERS_PAGE_LISTED_SUCCESS,
  TOP_PHOTOGRAPHERS_PAGE_LISTED_ERROR,
} from "./actionTypes";

export const topPhotographersData = (type) => {
  return {
    type: TOP_PHOTOGRAPHERS_HOME,
    payload: { type },
  };
};

export const topPhotographersDataSuccess = (data) => {
  return {
    type: TOP_PHOTOGRAPHERS_HOME_SUCCESS,
    payload: data,
  };
};

export const topPhotographersDataError = (error) => {
  return {
    type: TOP_PHOTOGRAPHERS_HOME_ERROR,
    payload: error,
  };
};
export const topPhotographersReferralsData = (type) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_REFERRALS,
    payload: { type },
  };
};

export const topPhotographersReferralsDataSuccess = (data) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_REFERRALS_SUCCESS,
    payload: data,
  };
};

export const topPhotographersReferralsDataError = (error) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_REFERRALS_ERROR,
    payload: error,
  };
};
export const topPhotographersSellData = (type) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_SELL,
    payload: { type },
  };
};

export const topPhotographersSellDataSuccess = (data) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_SELL_SUCCESS,
    payload: data,
  };
};

export const topPhotographersSellDataError = (error) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_SELL_ERROR,
    payload: error,
  };
};

export const topPhotographersMintedData = (type) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_MINTED,
    payload: { type },
  };
};

export const topPhotographersMintedDataSuccess = (data) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_MINTED_SUCCESS,
    payload: data,
  };
};

export const topPhotographersMintedDataError = (error) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_MINTED_ERROR,
    payload: error,
  };
};

export const topPhotographersListedData = (type) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_LISTED,
    payload: { type },
  };
};

export const topPhotographersListedDataSuccess = (data) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_LISTED_SUCCESS,
    payload: data,
  };
};

export const topPhotographersListedDataError = (error) => {
  return {
    type: TOP_PHOTOGRAPHERS_PAGE_LISTED_ERROR,
    payload: error,
  };
};
