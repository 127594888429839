//GET_TWITTER_FARM_GET_MY_POINTS
export const GET_TWITTER_FARM_GET_MY_POINT = "GET_TWITTER_FARM_GET_MY_POINT";
export const GET_TWITTER_FARM_GET_MY_POINT_SUCCESS =
  "GET_TWITTER_FARM_GET_MY_POINT_SUCCESS";
export const GET_TWITTER_FARM_GET_MY_POINT_ERROR =
  "GET_TWITTER_FARM_GET_MY_POINT_ERROR";

//POST_TWITTER_FARM_GET_MY_ACTIVITIES
export const POST_TWITTER_FARM_MY_ACTIVITIES_ACTION =
  "POST_TWITTER_FARM_MY_ACTIVITIES_ACTION";
export const POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_SUCCESS =
  "POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_SUCCESS";
export const POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_ERROR =
  "POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_ERROR";

//POST_TWITTER_FARM_MY_POINTS
export const POST_TWITTER_FARM_MY_POINTS = "POST_TWITTER_FARM_MY_POINTS";
export const POST_TWITTER_FARM_MY_POINTS_SUCCESS =
  "POST_TWITTER_FARM_MY_POINTS_SUCCESS";
export const POST_TWITTER_FARM_MY_POINTS_ERROR =
  "POST_TWITTER_FARM_MY_POINTS_ERROR";

//POST_TWITTER_FARM_MY_SEED
export const POST_TWITTER_FARM_MY_SEED = "POST_TWITTER_FARM_MY_SEED";
export const POST_TWITTER_FARM_MY_SEED_SUCCESS =
  "POST_TWITTER_FARM_MY_SEED_SUCCESS";
export const POST_TWITTER_FARM_MY_SEED_ERROR =
  "POST_TWITTER_FARM_MY_SEED_ERROR";

//POST_TWITTER_FARM_ALL_USER_POINTS
export const POST_TWITTER_FARM_ALL_USER_POINTS =
  "POST_TWITTER_FARM_ALL_USER_POINTS";
export const POST_TWITTER_FARM_ALL_USER_POINTS_SUCCESS =
  "POST_TWITTER_FARM_ALL_USER_POINTS";
export const POST_TWITTER_FARM_ALL_USER_POINTS_ERROR =
  "POST_TWITTER_FARM_ALL_USER_POINTS";

//POST_TWITTER_ALL_USER_LIKE_POINTS_V2
export const POST_TWITTER_ALL_USER_LIKE_POINTS_V2 =
  "POST_TWITTER_ALL_USER_LIKE_POINTS_V2";
export const POST_TWITTER_ALL_USER_LIKE_POINTS_V2_SUCCESS =
  "POST_TWITTER_ALL_USER_LIKE_POINTS_V2_SUCCESS";
export const POST_TWITTER_ALL_USER_LIKE_POINTS_V2_ERROR =
  "POST_TWITTER_ALL_USER_LIKE_POINTS_V2_ERROR";

//GET_TWITTER_FARM_UPDATE_USERS_LEAGUE
export const GET_TWITTER_UPDATE_USERS_LEAGUE =
  "GET_TWITTER_UPDATE_USERS_LEAGUE";
export const GET_TWITTER_UPDATE_USERS_LEAGUE_SUCCESS =
  "GET_TWITTER_UPDATE_USERS_LEAGUE_SUCCESS";
export const GET_TWITTER_UPDATE_USERS_LEAGUE_ERROR =
  "GET_TWITTER_UPDATE_USERS_LEAGUE_ERROR";

//getTwitterSettings
export const GET_TWITTER_SETTING = "GET_TWITTER_SETTING";
export const GET_TWITTER_SETTING_SUCCESS = "GET_TWITTER_SETTING_SUCCESS";
export const GET_TWITTER_SETTING_ERROR = "GET_TWITTER_SETTING_ERROR";

//RESET FLAG
export const RESET_ALL_TWITTER_FARM_POINTS_FLAG =
  "RESET_ALL_TWITTER_FARM_POINTS_FLAG";
