import {
  TRENDING_ARTWORKS_HOME,
  TRENDING_ARTWORKS_HOME_SUCCESS,
  TRENDING_ARTWORKS_HOME_ERROR,
  RESET_TRENDING_ARTWORKS_HOME,
} from "./actionTypes";

const initialState = {
  errorMsgTrendingNfts: "",
  loadingTrendingNfts: false,
  errorTrendingNfts: false,
  successTrendingNfts: null,
  nfts: null,
};

const TrendingArtworks = (state = initialState, action) => {
  switch (action.type) {
    //TRENDING_ARTWORKS_HOME
    case TRENDING_ARTWORKS_HOME:
      state = {
        ...state,
        loadingTrendingNfts: true,
        errorTrendingNfts: false,
        successTrendingNfts: false,
        errorMsgTrendingNfts: null,
      };
      break;
    case TRENDING_ARTWORKS_HOME_SUCCESS:
      state = {
        ...state,
        loadingTrendingNfts: false,
        errorMsgTrendingNfts: false,
        successTrendingNfts: true,
        nfts: action.payload,
      };
      break;
    case TRENDING_ARTWORKS_HOME_ERROR:
      state = {
        ...state,
        errorMsgTrendingNfts: action.payload.data,
        loadingTrendingNfts: false,
        errorTrendingNfts: true,
        successTrendingNfts: false,
      };
      break;
    case RESET_TRENDING_ARTWORKS_HOME:
      state = {
        ...state,
        errorMsgTrendingNfts: null,
        loadingTrendingNfts: false,
        errorTrendingNfts: false,
        successTrendingNfts: null,
        nfts: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default TrendingArtworks;
