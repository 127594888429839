import {
  //GET_MY_STAKES
  GET_MY_STAKES,
  GET_MY_STAKES_ERROR,
  GET_MY_STAKES_SUCCESS,

  //REQUEST_STAKES
  REQUEST_STAKES,
  REQUEST_STAKES_ERROR,
  REQUEST_STAKES_SUCCESS,

  //CANCEL_STAKES
  CANCEL_STAKES,
  CANCEL_STAKES_ERROR,
  CANCEL_STAKES_SUCCESS,
  RESET_STAKES_FLAG,
} from "./actionTypes";

//postGetMyStakes
export const getMyStakes = (data) => {
  return {
    type: GET_MY_STAKES,
    payload: data,
  };
};
export const getMyStakesSuccess = (data) => {
  return {
    type: GET_MY_STAKES_SUCCESS,
    payload: data,
  };
};
export const getMyStakesError = (data) => {
  return {
    type: GET_MY_STAKES_ERROR,
    payload: data,
  };
};

//requestStakess
export const requestStakes = (data) => {
  return {
    type: REQUEST_STAKES,
    payload: data,
  };
};

export const requestStakesSuccess = (data) => {
  return {
    type: REQUEST_STAKES_SUCCESS,
    payload: data,
  };
};

export const requestStakesError = (error) => {
  return {
    type: REQUEST_STAKES_ERROR,
    payload: error,
  };
};

//cancelStakes
export const cancelStakes = (data) => {
  return {
    type: CANCEL_STAKES,
    payload: { data },
  };
};

export const cancelStakesSuccess = (data) => {
  return {
    type: CANCEL_STAKES_SUCCESS,
    payload: data,
  };
};

export const cancelStakesError = (error) => {
  return {
    type: CANCEL_STAKES_ERROR,
    payload: error,
  };
};

//resetStakesFlag
export const resetStakesFlag = () => {
  return {
    type: RESET_STAKES_FLAG,
  };
};
