// reducer.js
import {
  GENERAL_SEARCH,
  GENERAL_SEARCH_SUCCESS,
  GENERAL_SEARCH_FAILURE,
  RESET_GENERAL_SEARCH,
} from "./actionTypes";

const initialState = {
  generalSearchsLoading: false,
  generalSearchsSuccess: false,
  generalSearchsError: false,
  generalSearchs: [],
  generalSearchsMsg: [],
};
const GeneralSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERAL_SEARCH:
      return {
        ...state,
        generalSearchsLoading: true,
        generalSearchsSuccess: false,
        generalSearchsError: false,
      };
    case GENERAL_SEARCH_SUCCESS:
      return {
        ...state,
        generalSearchsLoading: false,
        generalSearchsSuccess: true,
        generalSearchsError: false,
        generalSearchs: action.payload,
        generalSearchsMsg: [],
      };
    case GENERAL_SEARCH_FAILURE:
      return {
        ...state,
        generalSearchsLoading: false,
        generalSearchsSuccess: false,
        generalSearchsError: false,
        generalSearchs: [],
        generalSearchsMsg: action.payload,
      };
    case RESET_GENERAL_SEARCH:
      state = {
        ...state,
        generalSearchsLoading: false,
        generalSearchsSuccess: false,
        generalSearchsError: false,
        generalSearchs: [],
        generalSearchsMsg: [],
      };
    default:
      return state;
  }
};

export default GeneralSearchReducer;
