// reducer.js
import {
  GET_ACCOUNT_FOLLOWNIGS,
  GET_ACCOUNT_FOLLOWNIGS_SUCCESS,
  GET_ACCOUNT_FOLLOWNIGS_FAILURE,
} from "./actionTypes";

const initialState = {
  loadingFollowing: false,
  successFollowing: false,
  errorFollowing: null,
  Following: [],
};
const Following = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_FOLLOWNIGS:
      return {
        ...state,
        loadingFollowing: true,
        successFollowing: false,
        errorFollowing: null,
      };
    case GET_ACCOUNT_FOLLOWNIGS_SUCCESS:
      return {
        ...state,
        loadingFollowing: false,
        successFollowing: true,
        errorFollowing: null,
        Following: action.payload,
      };
    case GET_ACCOUNT_FOLLOWNIGS_FAILURE:
      return {
        ...state,
        loadingFollowing: false,
        successFollowing: false,
        errorFollowing: action.payload,
      };
    default:
      return state;
  }
};

export default Following;
