import {
  TRENDING_ARTWORKS_HOME,
  TRENDING_ARTWORKS_HOME_SUCCESS,
  TRENDING_ARTWORKS_HOME_ERROR,
  RESET_TRENDING_ARTWORKS_HOME,
} from "./actionTypes";

//trendingArtworks data
export const trendingData = () => {
  return {
    type: TRENDING_ARTWORKS_HOME,
  };
};

export const trendingDataSuccess = (data) => {
  return {
    type: TRENDING_ARTWORKS_HOME_SUCCESS,
    payload: data,
  };
};

export const trendingDataError = (data) => {
  return {
    type: TRENDING_ARTWORKS_HOME_ERROR,
    payload: data,
  };
};

//reset states
export const resetTrendingArtworksStates = () => {
  return {
    type: RESET_TRENDING_ARTWORKS_HOME,
  };
};
