import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import { postAccountLogin } from "@/config/helpers/backend_helper";
import {
  getMyProfile,
  resetAllMyProfileDataFlag,
  resetAllProfileDataFlag,
} from "../myprofile/actions";
import { setItem, setItemCookie } from "@/config/helpers/api_helper";
import { resetAssetPortfolio } from "../assetPortfolio/action";

function* loginUser({ payload: { user } }) {
  try {
    // if (process.env.REACT_APP_API_URL) {
    const response = yield call(postAccountLogin, {
      EthAddress: user.EthAddress,
      SuiAddress: user.SuiAddress,
      SignMessage: user.SignMessage,
      Signature: user.Signature,
      ReferralEthAddress: user.ReferralEthAddress,
      Email: user.Email,
    });
    if (typeof window !== "undefined") {
      if (response.Token === null || response.Token === "") {
        yield put(apiError(response));
      } else {
        yield put(loginSuccess(response));
        // history("/dashboard");
        setItem("authUser", response);
        setItemCookie("authentication", response);
        yield put(getMyProfile(response.UserName));
        // localStorage.setItem("authUser", JSON.stringify(response));
        var now = new Date();
        var expireTime = now.getTime() + 86400000;
        // var expireTime = now.getTime() + 86400;
        localStorage.setItem("expireTime", expireTime);
      }
    }
    // }
  } catch (error) {
    yield put(apiError(error.response.data.Errors[0].Message));
  }
}

function* logoutUser() {
  try {
    setItemCookie("authentication", "", true);
    localStorage.removeItem("authUser");
    localStorage.removeItem("settingsData");
    localStorage.removeItem("loginType");
    localStorage.removeItem("chainIdd");
    localStorage.removeItem("expireTime");
    yield put(resetAllProfileDataFlag());
    yield put(resetAllMyProfileDataFlag());
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
