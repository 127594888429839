// actions.js
import {
  GET_MY_TRANSACTIONS,
  GET_MY_TRANSACTIONS_SUCCESS,
  GET_MY_TRANSACTIONS_FAILURE,
} from "./actionTypes";

export const myTransactionsRequest = (requestData) => ({
  type: GET_MY_TRANSACTIONS,
  payload: requestData,
});

export const myTransactionsSuccess = (data) => ({
  type: GET_MY_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const myTransactionsFailure = (error) => ({
  type: GET_MY_TRANSACTIONS_FAILURE,
  payload: error,
});
