import {
  HERO_SECTION_HOME,
  HERO_SECTION_HOME_SUCCESS,
  HERO_SECTION_HOME_ERROR,
  MINTED_NFT_COUNT_HOME,
  MINTED_NFT_COUNT_HOME_SUCCESS,
  MINTED_NFT_COUNT_HOME_ERROR,
  RESET_HERO_SECTION_HOME,
} from "./actionTypes";

const initialState = {
  errorMsgHeroNfts: "",
  loadingHeroNfts: false,
  errorHeroNfts: false,
  successHeroNfts: null,
  nfts: null,
  errorMsgMintedCount: "",
  loadingMintedCount: false,
  errorMintedCount: false,
  successMintedCount: null,
  mintedCount: null,
};

const HeroSection = (state = initialState, action) => {
  switch (action.type) {
    //HERO_SECTION_HOME
    case HERO_SECTION_HOME:
      state = {
        ...state,
        loadingHeroNfts: true,
        errorHeroNfts: false,
        successHeroNfts: false,
        errorMsgHeroNfts: null,
      };
      break;
    case HERO_SECTION_HOME_SUCCESS:
      state = {
        ...state,
        loadingHeroNfts: false,
        errorHeroNfts: false,
        successHeroNfts: true,
        nfts: action.payload,
        errorMsgHeroNfts: null,
      };
      break;
    case HERO_SECTION_HOME_ERROR:
      state = {
        ...state,
        errorMsgHeroNfts: action.payload.data,
        loadingHeroNfts: false,
        errorHeroNfts: true,
        successHeroNfts: false,
      };
      break;
    //MINTED_NFT_COUNT_HOME
    case MINTED_NFT_COUNT_HOME:
      state = {
        ...state,
        loadingMintedCount: true,
        errorMintedCount: false,
        successMintedCount: false,
        errorMsgMintedCount: null,
      };
      break;
    case MINTED_NFT_COUNT_HOME_SUCCESS:
      state = {
        ...state,
        loadingMintedCount: false,
        errorMintedCount: false,
        successMintedCount: true,
        mintedCount: action.payload,
        errorMsgMintedCount: null,
      };
      break;
    case MINTED_NFT_COUNT_HOME_ERROR:
      state = {
        ...state,
        errorMsgMintedCount: action.payload.data,
        loadingMintedCount: false,
        errorMintedCount: true,
        successMintedCount: false,
      };
      break;
    case RESET_HERO_SECTION_HOME:
      state = {
        ...state,
        errorMsgHeroNfts: null,
        loadingHeroNfts: false,
        errorHeroNfts: false,
        successHeroNfts: null,
        errorMsgMintedCount: null,
        loadingMintedCount: false,
        errorMintedCount: false,
        successMintedCount: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default HeroSection;
