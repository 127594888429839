import {
  ANNOUNCEMENT_NEWS_HOME,
  ANNOUNCEMENT_NEWS_HOME_SUCCESS,
  ANNOUNCEMENT_NEWS_HOME_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgHeroUsers: "",
  loadingHeroUsers: false,
  errorHeroUsers: false,
  successHeroUsers: null,
  news: [],
};

const AnnouncementNews = (state = initialState, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_NEWS_HOME:
      return {
        ...state,
        loadingHeroUsers: true,
        errorHeroUsers: false,
        successHeroUsers: false,
        errorMsgHeroUsers: null,
      };
    case ANNOUNCEMENT_NEWS_HOME_SUCCESS:
      return {
        ...state,
        loadingHeroUsers: false,
        errorHeroUsers: false,
        successHeroUsers: true,
        news: action.payload,
        errorMsgHeroUsers: null,
      };
    case ANNOUNCEMENT_NEWS_HOME_ERROR:
      return {
        ...state,
        errorMsgHeroUsers: action.payload.data,
        loadingHeroUsers: false,
        errorHeroUsers: true,
        successHeroUsers: false,
      };
    default:
      return state;
  }
};

export default AnnouncementNews;
