import {
  POST_ADD_SUI_WALLET,
  POST_ADD_SUI_WALLET_ERROR,
  POST_ADD_SUI_WALLET_SUCCESS,
  POST_MERGE_SUI_WALLET,
  POST_MERGE_SUI_WALLET_ERROR,
  POST_MERGE_SUI_WALLET_SUCCESS,
  RESET_POST_ADD_SUI_WALLET,
  RESET_POST_MERGE_SUI_WALLET,
} from "./actionTypes";

const initialState = {
  AddSuiWalletError: null,
  AddSuiWalletSuccess: null,
  AddSuiWallet: [],
  AddSuiWalletMessage: null,
  AddSuiWalletLoading: null,

  MergeSuiWalletError: null,
  MergeSuiWalletSuccess: null,
  MergeSuiWallet: [],
  MergeSuiWalletMessage: null,
  MergeSuiWalletLoading: null,
};

const SuiWalletAccount = (state = initialState, action) => {
  switch (action.type) {
    //POST_ADD_SUI_WALLET
    case POST_ADD_SUI_WALLET:
      state = {
        ...state,
        AddSuiWalletError: false,
        AddSuiWalletSuccess: false,
        AddSuiWalletLoading: true,
      };
      break;
    case POST_ADD_SUI_WALLET_SUCCESS:
      state = {
        ...state,
        AddSuiWalletError: false,
        AddSuiWalletSuccess: true,
        AddSuiWallet: action.payload,
        AddSuiWalletMessage: null,
        AddSuiWalletLoading: false,
      };
      break;
    case POST_ADD_SUI_WALLET_ERROR:
      state = {
        ...state,
        AddSuiWalletError: true,
        AddSuiWalletSuccess: false,
        AddSuiWallet: [],
        AddSuiWalletMessage: action.payload,
        AddSuiWalletLoading: false,
      };
      break;
    case RESET_POST_ADD_SUI_WALLET:
      state = {
        ...state,
        AddSuiWalletError: null,
        AddSuiWalletSuccess: null,
        AddSuiWallet: [],
        AddSuiWalletMessage: null,
        AddSuiWalletLoading: null,
      };
      break;

    //POST_MERGE_SUI_WALLET
    case POST_MERGE_SUI_WALLET:
      state = {
        ...state,
        MergeSuiWalletError: false,
        MergeSuiWalletSuccess: false,
        MergeSuiWalletLoading: true,
      };
      break;
    case POST_MERGE_SUI_WALLET_SUCCESS:
      state = {
        ...state,
        MergeSuiWalletError: false,
        MergeSuiWalletSuccess: true,
        MergeSuiWallet: action.payload,
        MergeSuiWalletMessage: null,
        MergeSuiWalletLoading: false,
      };
      console.log("in success create");
      break;
    case POST_MERGE_SUI_WALLET_ERROR:
      state = {
        ...state,
        MergeSuiWalletError: true,
        MergeSuiWalletSuccess: false,
        MergeSuiWallet: [],
        MergeSuiWalletMessage: action.payload,
        MergeSuiWalletLoading: false,
      };
      break;
    case RESET_POST_MERGE_SUI_WALLET:
      state = {
        ...state,
        MergeSuiWalletError: null,
        MergeSuiWalletSuccess: null,
        MergeSuiWallet: [],
        MergeSuiWalletMessage: null,
        MergeSuiWalletLoading: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SuiWalletAccount;
