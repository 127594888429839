import {
  POST_ADD_ETH_WALLET,
  POST_ADD_ETH_WALLET_ERROR,
  POST_ADD_ETH_WALLET_SUCCESS,
  // POST_MERGE_ETH_WALLET,
  // POST_MERGE_ETH_WALLET_ERROR,
  // POST_MERGE_ETH_WALLET_SUCCESS,
  RESET_POST_ADD_ETH_WALLET,
  // RESET_POST_MERGE_ETH_WALLET,
} from "./actionTypes";

//postAddEthWAllet
export const postAddEthWallet = (data) => {
  return {
    type: POST_ADD_ETH_WALLET,
    payload: { data },
  };
};

export const postAddEthWalletSuccess = (data) => {
  return {
    type: POST_ADD_ETH_WALLET_SUCCESS,
    payload: data,
  };
};

export const postAddEthWalletError = (error) => {
  return {
    type: POST_ADD_ETH_WALLET_ERROR,
    payload: error,
  };
};
//reset states
export const resetPostAddEthWallet = () => {
  return {
    type: RESET_POST_ADD_ETH_WALLET,
  };
};

// //postMergeEthWAllet
// export const postMergeEthWallet = (data) => {
//   return {
//     type: POST_MERGE_ETH_WALLET,
//     payload: { data },
//   };
// };

// export const postMergeEthWalletSuccess = (data) => {
//   return {
//     type: POST_MERGE_ETH_WALLET_SUCCESS,
//     payload: data,
//   };
// };

// export const postMergeEthWalletError = (error) => {
//   return {
//     type: POST_MERGE_ETH_WALLET_ERROR,
//     payload: error,
//   };
// };

// //reset states
// export const resetPostMergeEthWallet = () => {
//   return {
//     type: RESET_POST_MERGE_ETH_WALLET,
//   };
// };
