import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { applyMiddleware, configureStore } from "@reduxjs/toolkit";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
// const composeEnhancers =
//   (typeof window !== "undefined" &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
//   compose;

// export default function store() {
//   const store = configureStore({
//     reducer: rootReducer,
//     // initialState,
//     middleware: middlewares,
//     devTools: process.env.NODE_ENV !== "production",
//   });
//   sagaMiddleware.run(rootSaga);
//   return store;
// }

const configStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    // initialState,
    middleware: middlewares,
    devTools: process.env.NEXT_PUBLIC_STAGE === "stage1",
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

const store = configStore();
export default store;
