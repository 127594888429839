//POST_ADD_SUI_WALLET
export const POST_ADD_SUI_WALLET = "POST_ADD_SUI_WALLET";
export const POST_ADD_SUI_WALLET_SUCCESS = "POST_ADD_SUI_WALLET_SUCCESS";
export const POST_ADD_SUI_WALLET_ERROR = "POST_ADD_SUI_WALLET_ERROR";
export const RESET_POST_ADD_SUI_WALLET = "RESET_POST_ADD_SUI_WALLET";

//POST_MERGE_SUI_WALLET
export const POST_MERGE_SUI_WALLET = "POST_MERGE_SUI_WALLET";
export const POST_MERGE_SUI_WALLET_SUCCESS = "POST_MERGE_SUI_WALLET_SUCCESS";
export const POST_MERGE_SUI_WALLET_ERROR = "POST_MERGE_SUI_WALLET_ERROR";
export const RESET_POST_MERGE_SUI_WALLET = "RESET_POST_MERGE_SUI_WALLET";
