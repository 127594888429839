import {
  SIMILLAR_ARTWORKS_REQUEST,
  SIMILLAR_ARTWORKS_SUCCESS,
  SIMILLAR_ARTWORKS_FAILURE,
} from "./actionTypes";

const initialState = {
  loadingSimillarArtworks: false,
  successSimillarArtworks: false,
  errorSimillarArtworks: null,
  nfts: null,
};
const SimillarArtworks = (state = initialState, action) => {
  switch (action.type) {
    case SIMILLAR_ARTWORKS_REQUEST:
      return {
        ...state,
        loadingSimillarArtworks: true,
        successSimillarArtworks: false,
        errorSimillarArtworks: null,
      };
    case SIMILLAR_ARTWORKS_SUCCESS:
      return {
        ...state,
        loadingSimillarArtworks: false,
        successSimillarArtworks: true,
        errorSimillarArtworks: null,
        nfts: action.payload,
      };
    case SIMILLAR_ARTWORKS_FAILURE:
      return {
        ...state,
        loadingSimillarArtworks: false,
        successSimillarArtworks: false,
        errorSimillarArtworks: action.payload,
      };
    default:
      return state;
  }
};

export default SimillarArtworks;
