// actions.js
import {
  GET_ACCOUNT_REFERRALS,
  GET_ACCOUNT_REFERRALS_SUCCESS,
  GET_ACCOUNT_REFERRALS_FAILURE,
} from "./actionTypes";

export const referralRequest = (userName, requestData) => ({
  type: GET_ACCOUNT_REFERRALS,
  payload: { userName, requestData },
});
export const referralSuccess = (data) => ({
  type: GET_ACCOUNT_REFERRALS_SUCCESS,
  payload: data,
});

export const referralFailure = (error) => ({
  type: GET_ACCOUNT_REFERRALS_FAILURE,
  payload: error,
});
