// actions.js
import {
  GENERAL_SEARCH,
  GENERAL_SEARCH_SUCCESS,
  GENERAL_SEARCH_FAILURE,
  RESET_GENERAL_SEARCH,
} from "./actionTypes";

export const generalSearch = (GeneralSearch) => ({
  type: GENERAL_SEARCH,
  payload: { GeneralSearch },
});

export const generalSearchSuccess = (data) => ({
  type: GENERAL_SEARCH_SUCCESS,
  payload: data,
});

export const generalSearchFailure = (error) => ({
  type: GENERAL_SEARCH_FAILURE,
  payload: error,
});

//reset states
export const resetGeneralSearch = () => {
  return {
    type: RESET_GENERAL_SEARCH,
  };
};
