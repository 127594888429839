import {
  RECENTLY_SOLD_HOME,
  RECENTLY_SOLD_HOME_SUCCESS,
  RECENTLY_SOLD_HOME_ERROR,
  RESET_RECENTLY_SOLD_HOME,
} from "./actionTypes";

const initialState = {
  errorMsgRecentlyNfts: "",
  loadingRecentlyNfts: false,
  errorRecentlyNfts: false,
  successRecentlyNfts: null,
  nfts: null,
};

const RecentlySold = (state = initialState, action) => {
  switch (action.type) {
    //RECENTLY_SOLD_HOME
    case RECENTLY_SOLD_HOME:
      state = {
        ...state,
        loadingRecentlyNfts: true,
        errorRecentlyNfts: false,
        successRecentlyNfts: false,
        errorMsgRecentlyNfts: null,
      };
      break;
    case RECENTLY_SOLD_HOME_SUCCESS:
      state = {
        ...state,
        loadingRecentlyNfts: false,
        errorMsgRecentlyNfts: false,
        successRecentlyNfts: true,
        nfts: action.payload,
      };
      break;
    case RECENTLY_SOLD_HOME_ERROR:
      state = {
        ...state,
        errorMsgRecentlyNfts: action.payload.data,
        loadingRecentlyNfts: false,
        errorRecentlyNfts: true,
        successRecentlyNfts: false,
      };
      break;
    case RESET_RECENTLY_SOLD_HOME:
      state = {
        ...state,
        errorMsgRecentlyNfts: "",
        loadingRecentlyNfts: false,
        errorRecentlyNfts: false,
        successRecentlyNfts: null,
        nfts: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default RecentlySold;
