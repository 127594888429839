import {
  FAVORITES_NFTCARD_REQUEST,
  FAVORITES_NFTCARD_SUCCESS,
  FAVORITES_NFTCARD_FAILURE,
  RESET_FAVORITES_NFTCARD_FAILURE,
  FAVORITES_COLLECTION_REQUEST,
  FAVORITES_COLLECTION_SUCCESS,
  FAVORITES_COLLECTION_FAILURE,
  RESET_FAVORITES_COLLECTION,
} from "./actionTypes";

export const FavoritesNftCardRequest = (requestData) => ({
  type: FAVORITES_NFTCARD_REQUEST,
  payload: requestData,
});

export const FavoritesNftCardSuccess = (data) => ({
  type: FAVORITES_NFTCARD_SUCCESS,
  payload: data,
});

export const FavoritesNftCardFailure = (error) => ({
  type: FAVORITES_NFTCARD_FAILURE,
  payload: error,
});

export const ResetFavoritesNftCardFailure = () => ({
  type: RESET_FAVORITES_NFTCARD_FAILURE,
});

//FavoritesCOLLECTION
export const FavoritesCollection = (requestData) => ({
  type: FAVORITES_COLLECTION_REQUEST,
  payload: requestData,
});

export const FavoritesCollectionSuccess = (data) => ({
  type: FAVORITES_COLLECTION_SUCCESS,
  payload: data,
});

export const FavoritesCollectionFailure = (error) => ({
  type: FAVORITES_COLLECTION_FAILURE,
  payload: error,
});

export const ResetFavoritesCollection = () => ({
  type: RESET_FAVORITES_COLLECTION,
});
