import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  // GET_TWITTER_FARM_EXTRACT_POSTS_ACTION,
  // GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION,
  // GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION,
  // GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION,
  // GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION,
  POST_TWITTER_FARM_MY_POINTS,
} from "./actionTypes";

import {
  // getExtractTwitterPostsActionError,
  // getExtractTwitterPostsActionSuccess,
  // getExtractPostUsersRetweetedActionError,
  // getExtractPostUsersRetweetedActionSuccess,
  // getExtractPostUsersLikedActionError,
  // getExtractPostUsersLikedActionSuccess,
  // getExtractPostUsersReplyedActionError,
  // getExtractPostUsersReplyedActionSuccess,
  // getExtractTwitterFollowersActionError,
  // getExtractTwitterFollowersActionSuccess,
  TwitterMyPointsPostSuccess,
  postTwitterMyPointsPostError,
} from "./actions";

import {
  // getExtractTwitterPosts,
  // getExtractPostUsersRetweeted,
  // getExtractPostUsersLiked,
  // getExtractPostUsersReplyed,
  // getExtractTwitterFollowers,
  postTwitterMyPoints,
} from "@/config/helpers/backend_helper";

function* fetchTwitterMyPointsPost(action) {
  try {
    const response = yield call(postTwitterMyPoints, action.payload);
    yield put(TwitterMyPointsPostSuccess(response));
  } catch (error) {
    yield put(postTwitterMyPointsPostError(error));
  }
}

// function* extractTwitterPosts() {
//   try {
//     const response = yield call(getExtractTwitterPosts);
//     yield put(getExtractTwitterPostsActionSuccess(response));
//   } catch (error) {
//     yield put(getExtractTwitterPostsActionError(error));
//   }
// }

// function* extractPostUsersRetweeted() {
//   try {
//     const response = yield call(getExtractPostUsersRetweeted);
//     yield put(getExtractPostUsersRetweetedActionSuccess(response));
//   } catch (error) {
//     yield put(getExtractPostUsersRetweetedActionError(error));
//   }
// }

// function* extractPostUsersLiked() {
//   try {
//     const response = yield call(getExtractPostUsersLiked);
//     yield put(getExtractPostUsersLikedActionSuccess(response));
//   } catch (error) {
//     yield put(getExtractPostUsersLikedActionError(error));
//   }
// }

// function* extractPostUsersReplyed() {
//   try {
//     const response = yield call(getExtractPostUsersReplyed);
//     yield put(getExtractPostUsersReplyedActionSuccess(response));
//   } catch (error) {
//     yield put(getExtractPostUsersReplyedActionError(error));
//   }
// }

// function* extractTwitterFollowers() {
//   try {
//     const response = yield call(getExtractTwitterFollowers);
//     yield put(getExtractTwitterFollowersActionSuccess(response));
//   } catch (error) {
//     yield put(getExtractTwitterFollowersActionError(error));
//   }
// }

export function* watchTwitterFarmChange() {
  yield takeLatest(POST_TWITTER_FARM_MY_POINTS, fetchTwitterMyPointsPost);
  // yield takeLatest(GET_TWITTER_FARM_EXTRACT_POSTS_ACTION, extractTwitterPosts);
  // yield takeLatest(
  //   GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION,
  //   extractPostUsersRetweeted
  // );
  // yield takeLatest(
  //   GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION,
  //   extractPostUsersLiked
  // );
  // yield takeLatest(
  //   GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION,
  //   extractPostUsersReplyed
  // );
  // yield takeLatest(
  //   GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION,
  //   extractTwitterFollowers
  // );
}

function* TwitterFarmSaga() {
  yield all([fork(watchTwitterFarmChange)]);
}

export default TwitterFarmSaga;
