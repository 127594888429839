//POST_ADD_ETH_WALLET
export const POST_ADD_ETH_WALLET = "POST_ADD_ETH_WALLET";
export const POST_ADD_ETH_WALLET_SUCCESS = "POST_ADD_ETH_WALLET_SUCCESS";
export const POST_ADD_ETH_WALLET_ERROR = "POST_ADD_ETH_WALLET_ERROR";
export const RESET_POST_ADD_ETH_WALLET = "RESET_POST_ADD_ETH_WALLET";

// //POST_MERGE_ETH_WALLET
// export const POST_MERGE_ETH_WALLET = "POST_MERGE_ETH_WALLET";
// export const POST_MERGE_ETH_WALLET_SUCCESS = "POST_MERGE_ETH_WALLET_SUCCESS";
// export const POST_MERGE_ETH_WALLET_ERROR = "POST_MERGE_ETH_WALLET_ERROR";
// export const RESET_POST_MERGE_ETH_WALLET = "RESET_POST_MERGE_ETH_WALLET";
