import { call, put, takeEvery } from "redux-saga/effects";
import { getMuseumReleased } from "@/config/helpers/backend_helper";
import { museumReleasedDataSuccess, museumReleasedDataError } from "./actions";
import { MUSEUM_RELEASED } from "./actionTypes";

function* fetchMuseumReleasedData() {
  try {
    const response = yield call(getMuseumReleased);

    yield put(museumReleasedDataSuccess(response));
  } catch (error) {
    yield put(museumReleasedDataError(error));
  }
}

function* museumReleasedSaga() {
  yield takeEvery(MUSEUM_RELEASED, fetchMuseumReleasedData);
}

export default museumReleasedSaga;
