import {
  MUSEUM_RELEASED,
  MUSEUM_RELEASED_SUCCESS,
  MUSEUM_RELEASED_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgMuseumNfts: "",
  loadingMuseumNfts: false,
  errorMuseumNfts: false,
  successMuseumNfts: null,
  nfts: [],
};

const MuseumReleased = (state = initialState, action) => {
  switch (action.type) {
    case MUSEUM_RELEASED:
      return {
        ...state,
        loadingMuseumNfts: true,
        errorMuseumNfts: false,
        successMuseumNfts: false,
        errorMsgMuseumNfts: null,
      };
    case MUSEUM_RELEASED_SUCCESS:
      return {
        ...state,
        loadingMuseumNfts: false,
        errorMuseumNfts: false,
        successMuseumNfts: true,
        nfts: action.payload,
        errorMsgMuseumNfts: null,
      };
    case MUSEUM_RELEASED_ERROR:
      return {
        ...state,
        errorMsgMuseumNfts: action.payload.data,
        loadingMuseumNfts: false,
        errorMuseumNfts: true,
        successMuseumNfts: false,
      };
    default:
      return state;
  }
};

export default MuseumReleased;
