// actions.js
import {
  TUTORIALS_LIST,
  TUTORIALS_LIST_SUCCESS,
  TUTORIALS_LIST_ERROR,
  TUTORIALS_INCREASE_VIEW_COUNT,
  TUTORIALS_INCREASE_VIEW_COUNT_SUCCESS,
  TUTORIALS_INCREASE_VIEW_COUNT_ERROR,
  RESET_INCREASE_VIEW_COUNT,
  TUTORIALS_LIST2,
  TUTORIALS_LIST_SUCCESS2,
  TUTORIALS_LIST_ERROR2,
} from "./actionTypes";

export const tutorialsData = (data) => {
  return {
    type: TUTORIALS_LIST,
    payload: data,
  };
};

export const tutorialsDataSuccess = (data) => {
  return {
    type: TUTORIALS_LIST_SUCCESS,
    payload: data,
  };
};

export const tutorialsDataError = (error) => {
  return {
    type: TUTORIALS_LIST_ERROR,
    payload: error,
  };
};

export const tutorialsData2 = (data) => {
  return {
    type: TUTORIALS_LIST2,
    payload: data,
  };
};

export const tutorialsDataSuccess2 = (data) => {
  return {
    type: TUTORIALS_LIST_SUCCESS2,
    payload: data,
  };
};

export const tutorialsDataError2 = (error) => {
  return {
    type: TUTORIALS_LIST_ERROR2,
    payload: error,
  };
};

export const increaseViewCountData = (tutorialId) => {
  return {
    type: TUTORIALS_INCREASE_VIEW_COUNT,
    payload: { tutorialId },
  };
};
export const increaseViewCountDataSuccess = (data) => {
  return {
    type: TUTORIALS_INCREASE_VIEW_COUNT_SUCCESS,
    payload: data,
  };
};

export const increaseViewCountDataError = (error) => {
  return {
    type: TUTORIALS_INCREASE_VIEW_COUNT_ERROR,
    payload: error,
  };
};

export const resetIncreaseViewCountData = () => {
  return {
    type: RESET_INCREASE_VIEW_COUNT,
  };
};
