import { call, put, takeEvery } from "redux-saga/effects";
import {
  getUserNotificationSetting,
  postNotificationUpdateSettings,
} from "@/config/helpers/backend_helper";

import {
  NotificationSettingsDataSuccess,
  NotificationSettingsDataError,
  updateNotificationSettingsDataSuccess,
  updateNotificationSettingsDataError,
} from "./action";

import {
  NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
} from "./actionTypes";

function* fetchNotificationSettingsData() {
  try {
    const response = yield call(getUserNotificationSetting);
    yield put(NotificationSettingsDataSuccess(response));
  } catch (error) {
    yield put(NotificationSettingsDataError(error));
  }
}
function* updateNotificationSettingsData(action) {
  try {
    yield call(postNotificationUpdateSettings, action.payload);
    yield put(updateNotificationSettingsDataSuccess());
  } catch (error) {
    yield put(updateNotificationSettingsDataError(error));
  }
}
function* NotificationSettingsSaga() {
  yield takeEvery(NOTIFICATION_SETTINGS, fetchNotificationSettingsData);
  yield takeEvery(UPDATE_NOTIFICATION_SETTINGS, updateNotificationSettingsData);
}

export default NotificationSettingsSaga;
