import {
  ARCHIVE_NFTCARD_REQUEST,
  ARCHIVE_NFTCARD_SUCCESS,
  ARCHIVE_NFTCARD_FAILURE,
  RESET_ARCHIVE_NFTCARD_FAILURE
} from "./actionTypes";

const initialState = {
  loadingArchiveNftCard: false,
  successArchiveNftCard: false,
  errorArchiveNftCard: null,
  nftsArchive: [],
};
const ArchiveNftCard = (state = initialState, action) => {
  switch (action.type) {
    case ARCHIVE_NFTCARD_REQUEST:
      return {
        ...state,
        loadingArchiveNftCard: true,
        successArchiveNftCard: false,
        errorArchiveNftCard: null,
      };
    case ARCHIVE_NFTCARD_SUCCESS:
      return {
        ...state,
        loadingArchiveNftCard: false,
        successArchiveNftCard: true,
        errorArchiveNftCard: null,
        nftsArchive: action.payload,
      };
    case ARCHIVE_NFTCARD_FAILURE:
      return {
        ...state,
        loadingArchiveNftCard: false,
        successArchiveNftCard: false,
        errorArchiveNftCard: action.payload,
      };
    case RESET_ARCHIVE_NFTCARD_FAILURE:
      return {
        ...state,
        successArchiveNftCard: false,
        nftsArchive: [],
      };
    default:
      return state;
  }
};

export default ArchiveNftCard;
