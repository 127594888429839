// reducer.js
import {
  GET_SETTING_CATEGORIES,
  GET_SETTING_CATEGORIES_SUCCESS,
  GET_SETTING_CATEGORIES_ERROR,
} from "./actionTypes";

const initialState = {
  loadingSettingsCategories: false,
  successSettingsCategories: false,
  errorSettingsCategories: false,
  MessageSettingsCategories: null,
  SettingsCategories: [],
};
const GetSettingsCategories = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTING_CATEGORIES:
      return {
        ...state,
        loadingSettingsCategories: true,
        successSettingsCategories: false,
        errorSettingsCategories: null,
      };
    case GET_SETTING_CATEGORIES_SUCCESS:
      return {
        ...state,
        loadingSettingsCategories: false,
        successSettingsCategories: true,
        errorSettingsCategories: null,
        SettingsCategories: action.payload,
      };
    case GET_SETTING_CATEGORIES_ERROR:
      return {
        ...state,
        loadingSettingsCategories: false,
        successSettingsCategories: false,
        errorSettingsCategories: true,
        MessageSettingsCategories: action.payload,
      };
    default:
      return state;
  }
};

export default GetSettingsCategories;
