// reducer.js
import {
  ORDER_CONFIRMATION,
  ORDER_CONFIRMATION_SUCCESS,
  ORDER_CONFIRMATION_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgorderconfirmation: "",
  loadingorderconfirmation: false,
  errororderconfirmation: false,
  successorderconfirmation: null,
  nft: [],
};

const Orderconfirmation = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_CONFIRMATION:
      state = {
        ...state,
        loadingorderconfirmation: true,
        errororderconfirmation: false,
        successorderconfirmation: false,
        errorMsgorderconfirmation: null,
      };
      break;
    case ORDER_CONFIRMATION_SUCCESS:
      state = {
        ...state,
        loadingorderconfirmation: false,
        errororderconfirmation: false,
        successorderconfirmation: true,
        nft: action.payload,
        errorMsgorderconfirmation: null,
      };
      break;
    case ORDER_CONFIRMATION_ERROR:
      state = {
        ...state,
        errorMsgorderconfirmation: action.payload.data,
        loadingorderconfirmation: false,
        errororderconfirmation: true,
        successorderconfirmation: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Orderconfirmation;
