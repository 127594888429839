// saga.js
import { getAllNotificationList } from "@/config/helpers/backend_helper";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  sendLastNotificationFailure,
  sendLastNotificationSuccess,
} from "./action";
import { SEND_LAST_NOTIFICATION_REQUEST } from "./actionTypes";

function* sendLastNotification(action) {
  try {
    const response = yield call(getAllNotificationList, action.payload);
    // console.log(response);
    yield put(sendLastNotificationSuccess(response));
  } catch (error) {
    yield put(sendLastNotificationFailure(error));
  }
}

function* LastnotificationSaga() {
  yield takeLatest(SEND_LAST_NOTIFICATION_REQUEST, sendLastNotification);
}

export default LastnotificationSaga;
