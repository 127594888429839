import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//
import { FOLLOW_MEMBER } from "./actionTypes";
import { followMemberSuccess, followMemberError } from "./actions";
import { toggleFollow } from "@/config/helpers/backend_helper";

function* getfollowtoggle({ payload: username }) {
  try {
    const response = yield call(toggleFollow, username);
    setTimeout(console.log("followtoggle", response), 4000);
    yield put(followMemberSuccess(response));
  } catch (error) {
    yield put(followMemberError(error));
  }
}

export function* watchedfollow() {
  yield takeEvery(FOLLOW_MEMBER, getfollowtoggle);
}

function* followsaga() {
  yield all([fork(watchedfollow)]);
}

export default followsaga;
