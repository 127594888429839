//POST_GET_MY_WITHDRAWALS
export const GET_MY_WITHDRAWALS = "GET_MY_WITHDRAWALS";
export const GET_MY_WITHDRAWALS_SUCCESS = "GET_MY_WITHDRAWALS_SUCCESS";
export const GET_MY_WITHDRAWALS_ERROR = "GET_MY_WITHDRAWALS_ERROR";

//REQUEST_WITHDRAWALS
export const REQUEST_WITHDRAWALS = "REQUEST_WITHDRAWALS";
export const REQUEST_WITHDRAWALS_SUCCESS = "REQUEST_WITHDRAWALS_SUCCESS";
export const REQUEST_WITHDRAWALS_ERROR = "REQUEST_WITHDRAWALS_ERROR";

//CANCEL_WITHDRAWALS
export const CANCEL_WITHDRAWALS = "CANCEL_WITHDRAWALS";
export const CANCEL_WITHDRAWALS_SUCCESS = "CANCEL_WITHDRAWALS";
export const CANCEL_WITHDRAWALS_ERROR = "CANCEL_WITHDRAWALS";

//RESET FLAG
export const RESET_WITHDRAWALS_FLAG = "RESET_WITHDRAWALS_FLAG";
