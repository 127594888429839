import { call, put, takeEvery } from "redux-saga/effects";
import { ASSET_PORTFOLIO } from "./actionTypes";
import { getAccountAssetPortfolio } from "@/config/helpers/backend_helper";
import { assetPortfolioSuccess, assetPortfolioError } from "./action";

function* fetchAssetPortfolioData({ payload: { data } }) {
  try {
    const response = yield call(getAccountAssetPortfolio, data);
    yield put(assetPortfolioSuccess(response));
  } catch (error) {
    yield put(assetPortfolioError(error));
  }
}

export function* assetPortfolioSaga() {
  yield takeEvery(ASSET_PORTFOLIO, fetchAssetPortfolioData);
}

export default assetPortfolioSaga;
