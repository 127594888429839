import {
  MUSEUM_NFTCARD_REQUEST,
  MUSEUM_NFTCARD_SUCCESS,
  MUSEUM_NFTCARD_FAILURE,
  RESET_MUSEUM_NFTCARD_FAILURE,
} from "./actionTypes";

export const MuseumNftCardRequest = (id) => ({
  type: MUSEUM_NFTCARD_REQUEST,
  payload: { id },
});

export const MuseumNftCardSuccess = (data) => ({
  type: MUSEUM_NFTCARD_SUCCESS,
  payload: data,
});

export const MuseumNftCardFailure = (error) => ({
  type: MUSEUM_NFTCARD_FAILURE,
  payload: error,
});

export const ResetMuseumNftCardFailure = () => ({
  type: RESET_MUSEUM_NFTCARD_FAILURE,
});
