// ActionTypes.js
export const GET_LANDING_PAGE_WALLET_DATA_ACTION =
  "GET_LANDING_PAGE_WALLET_DATA_ACTION";
export const GET_LANDING_PAGE_WALLET_DATA_ACTION_SUCCESS =
  "GET_LANDING_PAGE_WALLET_DATA_ACTION_SUCCESS";
export const GET_LANDING_PAGE_WALLET_DATA_ACTION_FAILURE =
  "GET_LANDING_PAGE_WALLET_DATA_ACTION_FAILURE";

export const RESET_GET_LANDING_PAGE_WALLET_DATA_ACTION =
  "RESET_GET_LANDING_PAGE_WALLET_DATA_ACTION";
