// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import { NOT_MARK_ALL_READ } from "./actionTypes";
import { allNotifsVisitedSuccess, allNotifsVisitedError } from "./action";
import { getMarkAllAsRead } from "@/config/helpers/backend_helper";

function* VisitedNotif(action) {
  try {
    const response = yield call(getMarkAllAsRead, action.payload);
    // console.log(response);
    yield put(allNotifsVisitedSuccess(response));
  } catch (error) {
    yield put(allNotifsVisitedError(error));
  }
}

function* VisitedNotifSaga() {
  yield takeLatest(NOT_MARK_ALL_READ, VisitedNotif);
}

export default VisitedNotifSaga;
