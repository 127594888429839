// actions.js
import {
  EXPLORE_REQUEST,
  EXPLORE_SUCCESS,
  EXPLORE_FAILURE,
  EXPLORE_SEARCH_REQUEST,
  EXPLORE_SEARCH_SUCCESS,
  EXPLORE_SEARCH_FAILURE,
  RESET_EXPLORE_SEARCH_REQUEST,
} from "./actionTypes";

export const exploreRequest = (requestData) => ({
  type: EXPLORE_REQUEST,
  payload: requestData,
});

export const exploreSuccess = (data) => ({
  type: EXPLORE_SUCCESS,
  payload: data,
});

export const exploreFailure = (error) => ({
  type: EXPLORE_FAILURE,
  payload: error,
});

//EXPLORE_SEARCH
export const exploreSearchRequest = (Search) => ({
  type: EXPLORE_SEARCH_REQUEST,
  payload: Search,
});

export const exploreSearchSuccess = (data) => ({
  type: EXPLORE_SEARCH_SUCCESS,
  payload: data,
});

export const exploreSearchFailure = (error) => ({
  type: EXPLORE_SEARCH_FAILURE,
  payload: error,
});

export const exploreSearchReset = () => ({
  type: RESET_EXPLORE_SEARCH_REQUEST,
});
