import axios from "axios";
import { api_stage_twitter } from "../config";
import { getCookie } from "./api_helper";

var twitterBaseUrl;
class APIClientTwitter {
  constructor() {
    // default
    // axios.defaults.baseURL = api_stage.API_URL_STAGE1;

    if (
      process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage1") ||
      process.env.NEXT_PUBLIC_URL.toLowerCase().includes("localhost")
    ) {
      twitterBaseUrl = api_stage_twitter.API_URL_STAGE1;
    } else if (process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage2")) {
      twitterBaseUrl = api_stage_twitter.API_URL_STAGE1;
    } else if (process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage3")) {
      twitterBaseUrl = api_stage_twitter.API_URL_MAIN;
    } else {
      twitterBaseUrl = api_stage_twitter.API_URL_MAIN;
    }

    // content type
    axios.defaults.headers.post["Content-Type"] = "application/json";

    if (typeof window !== "undefined") {
      this.setToken();
    }

    // intercepting to capture errors
    axios.interceptors.response.use(function (response) {
      return response.data ? response.data : response;
    });
  }

  setToken() {
    this.token =
      getCookie("authentication") !== ""
        ? JSON.parse(getCookie("authentication")).Token
        : null;
    if (this.token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

    this.config = {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Content-Security-Policy": `default-src 'self' http: https: ${process.env.NEXT_PUBLIC_URL.toLowerCase()} form.typeform.com verify.walletconnect.org api.web3modal.com; script-src 'self' ${process.env.NEXT_PUBLIC_URL.toLowerCase()} cdnjs.cloudflare.com static.cloudflareinsights.com cdn.jsdelivr.net/ghost/ form.typeform.com verify.walletconnect.org api.web3modal.com; style-src 'self' 'report-sample' 'unsafe-inline' fonts.googleapis.com cdnjs.cloudflare.com form.typeform.com verify.walletconnect.org; img-src 'self' data: www.gravatar.com links.services.disqus.com referrer.disqus.com c.disquscdn.com cdn.syndication.twimg.com syndication.twitter.com pbs.twimg.com platform.twitter.com abs.twimg.com www.google-analytics.com; child-src www.instagram.com twitter.com fusiontables.googleusercontent.com fusiontables.google.com www.google.com disqus.com www.youtube.com syndication.twitter.com platform.twitter.com www.youtube-nocookie.com js.stripe.com; connect-src 'self' syndication.twitter.com links.services.disqus.com scotthelme.ghost.io cloudflareinsights.com *.privacymanager.io; font-src 'self' cdnjs.cloudflare.com fonts.gstatic.com fonts.googleapis.com; form-action 'self' syndication.twitter.com; frame-ancestors 'none'; object-src 'none'; base-uri 'none';`,
        "Permissions-Policy": `geolocation=(self), accelerometer=(), camera=(), gyroscope=(), magnetometer=(), microphone=(), payment=(), usb=(), interest-cohort=()`,
        Authorization: "Bearer " + this.token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": ["GET", "POST", "OPTIONS"],
      },
    };
  }

  setTokenMimeImage() {
    this.token =
      getCookie("authentication") !== ""
        ? JSON.parse(getCookie("authentication")).Token
        : null;
    if (this.token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

    this.configMime = {
      headers: {
        "content-type": "image/png",
        Authorization: "Bearer " + this.token,
      },
    };
  }

  setTokenMultiPart() {
    this.token =
      getCookie("authentication") !== ""
        ? JSON.parse(getCookie("authentication")).Token
        : null;
    if (this.token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.token;

    this.config2 = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + this.token,
      },
    };
  }

  async get(url, params) {
    let response;

    let paramKeys = [];
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${twitterBaseUrl}${url}?${queryString}`, params);
    } else {
      // console.log("this token", this.token);
      if (this.token != null) {
        response = axios.get(`${twitterBaseUrl}${url}`, this.config);
      } else {
        if (this.token != null) {
          response = axios.get(`${twitterBaseUrl}${url}`, this.config);
        } else {
          this.setToken();
          response = axios.get(`${twitterBaseUrl}${url}`, this.config);
        }
      }
    }

    return response;
  }

  async getWithNoToken(url, params) {
    let response;

    let paramKeys = [];
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios
        .get(`${twitterBaseUrl}${url}?${queryString}`, params)
        .then((res) => {
          try {
            return res;
          } catch (error) {
            console.error(error);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      response = axios
        .get(`${twitterBaseUrl}${url}`)
        .then((res) => {
          try {
            return res;
          } catch (error) {
            console.error(error);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    return response;
  }

  getBlob = (url, params) => {
    this.setTokenMimeImage();
    if (this.token !== null) {
      return axios
        .get(`${twitterBaseUrl}${url}`, this.configMime, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          try {
            // console.log("responseType", res);
            return res;
          } catch (error) {
            console.error(error);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      this.setToken();
      return axios
        .get(`${twitterBaseUrl}${url}`, this.configMime, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          try {
            // console.log("responseType", res.headers);
            return res;
          } catch (error) {
            console.error(error);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  /**
   * post given data to url
   */
  // async create(url, params) {
  //   let response;

  //   let paramKeys = [];
  //   await new Promise((resolve) => setTimeout(resolve, 500));
  //   if (params) {
  //     Object.keys(params).map((key) => {
  //       paramKeys.push(key + "=" + params[key]);
  //       return paramKeys;
  //     });

  //     const queryString =
  //       paramKeys && paramKeys.length ? paramKeys.join("&") : "";
  //     response = axios.post(`${twitterBaseUrl}${url}?${queryString}`, params);
  //   } else {
  //     // console.log("this token", this.token);
  //     if (this.token != null) {
  //       response = axios.post(`${twitterBaseUrl}${url}`, this.config);
  //     } else {
  //       if (this.token != null) {
  //         response = axios.post(`${twitterBaseUrl}${url}`, this.config);
  //       } else {
  //         this.setToken();
  //         response = axios.post(`${twitterBaseUrl}${url}`, this.config);
  //       }
  //     }
  //   }

  //   return response;
  // }

  create = (url, data) => {
    // console.log("create 238", data, url);
    if (typeof window !== "undefined") {
      this.setToken();
    }
    return axios.post(`${twitterBaseUrl}${url}`, data, this.config);
    // .then((res) => {
    //   try {
    //     return res;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // })
    // .catch((err) => {
    //   console.log("err", err);
    // });
  };

  async create2(url, data, onSuccess, onFailure = null) {
    // console.log(data);
    if (typeof window !== "undefined") {
      this.setToken();
    }
    return axios
      .post(`${twitterBaseUrl}${url}`, data, this.config)
      .then((res) => {
        try {
          onSuccess();
          return res;
        } catch (error) {
          console.error(error);
        }
      })
      .catch((err) => {
        console.log("err", err);
        if (onFailure !== null) {
          onFailure();
        }
      });
  }

  createMultiPart = (url, data) => {
    this.setTokenMultiPart();
    return axios.post(`${twitterBaseUrl}${url}`, data, this.config2);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(`${twitterBaseUrl}${url}`, data);
  };

  put = (url, data) => {
    return axios.put(`${twitterBaseUrl}${url}`, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    this.setToken();
    return axios.delete(`${twitterBaseUrl}${url}`, this.config);
  };
}

export { APIClientTwitter };
