import {
  COLLABORATION_NFTCARD_REQUEST,
  COLLABORATION_NFTCARD_SUCCESS,
  COLLABORATION_NFTCARD_FAILURE,
  RESET_COLLABORATION_NFTCARD_FAILURE
} from "./actionTypes";

const initialState = {
  loadingCollaborationNftCard: false,
  successCollaborationNftCard: false,
  errorCollaborationNftCard: null,
  nftsCollaboration: [],
};
const CollaborationNftCard = (state = initialState, action) => {
  switch (action.type) {
    case COLLABORATION_NFTCARD_REQUEST:
      return {
        ...state,
        loadingCollaborationNftCard: true,
        successCollaborationNftCard: false,
        errorCollaborationNftCard: null,
      };
    case COLLABORATION_NFTCARD_SUCCESS:
      return {
        ...state,
        loadingCollaborationNftCard: false,
        successCollaborationNftCard: true,
        errorCollaborationNftCard: null,
        nftsCollaboration: action.payload,
      };
    case COLLABORATION_NFTCARD_FAILURE:
      return {
        ...state,
        loadingCollaborationNftCard: false,
        successCollaborationNftCard: false,
        errorCollaborationNftCard: action.payload,
      };
    case RESET_COLLABORATION_NFTCARD_FAILURE:
      return {
        ...state,
        successCollaborationNftCard: false,
        nftsCollaboration: [],
      };
    default:
      return state;
  }
};

export default CollaborationNftCard;
