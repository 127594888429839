//GET_USER_COLLECTION
export const GET_USER_COLLECTION = "GET_USER_COLLECTION";
export const GET_USER_COLLECTION_SUCCESS = "GET_USER_COLLECTION_SUCCESS";
export const GET_USER_COLLECTION_ERROR = "GET_USER_COLLECTION_ERROR";
export const RESET_GET_USER_COLLECTION = "RESET_GET_USER_COLLECTION";
export const RESET_GET_USER_COLLECTION2 = "RESET_GET_USER_COLLECTION2";

//CREATE_USER_COLLECTION
export const CREATE_USER_COLLECTION = "CREATE_USER_COLLECTION";
export const CREATE_USER_COLLECTION_SUCCESS = "CREATE_USER_COLLECTION_SUCCESS";
export const CREATE_USER_COLLECTION_ERROR = "CREATE_USER_COLLECTION_ERROR";
export const RESET_CREATE_USER_COLLECTION = "RESET_CREATE_USER_COLLECTION";
