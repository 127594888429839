// saga.js
import { call, put, takeEvery } from "redux-saga/effects";
import { getNftDeleteNft } from "@/config/helpers/backend_helper";
import { deleteNftError, deleteNftSuccess } from "./action";
import { NFT_DELETE } from "./actionTypes";

function* fetchDeleteNft({ payload: { Id } }) {
  try {
    const response = yield call(getNftDeleteNft, Id);
    yield put(deleteNftSuccess(response));
  } catch (error) {
    yield put(deleteNftError(error));
  }
}

function* DeleteNftSaga() {
  yield takeEvery(NFT_DELETE, fetchDeleteNft);
}

export default DeleteNftSaga;
