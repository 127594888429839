// reducer.js
import {
  USER_COLLECTION_NFTS,
  USER_COLLECTION_NFTS_SUCCESS,
  USER_COLLECTION_NFTS_ERROR,
  USER_COLLECTION_DELETE,
  USER_COLLECTION_DELETE_SUCCESS,
  USER_COLLECTION_DELETE_ERROR,
  USER_COLLECTION_EDIT,
  USER_COLLECTION_EDIT_SUCCESS,
  USER_COLLECTION_EDIT_ERROR,
  USER_COLLECTION_DELETE_NFT,
  USER_COLLECTION_DELETE_NFT_SUCCESS,
  USER_COLLECTION_DELETE_NFT_ERROR,
  USER_COLLECTION_NFTS_RESET,
  USER_COLLECTION_EDIT_RESET,
  USER_COLLECTION_DELETE_NFT_RESET,
  USER_ADD_NFT_COLLECTION,
  USER_ADD_NFT_COLLECTION_ERROR,
  USER_ADD_NFT_COLLECTION_SUCCESS,
  USER_ADD_NFT_COLLECTION_RESET,
} from "./actionTypes";

const initialState = {
  errorMsgUserCollectionNft: "",
  loadingUserCollectionNft: false,
  errorUserCollectionNft: false,
  successUserCollectionNft: null,
  nft: null,

  errorMsgUserCollectionDelete: "",
  loadingUserCollectionDelete: false,
  errorUserCollectionDelete: false,
  successUserCollectionDelete: null,
  // nft: [],

  errorMsgUserCollectionEdit: "",
  loadingUserCollectionEdit: false,
  errorUserCollectionEdit: false,
  successUserCollectionEdit: null,
  collectionEdit: null,

  errorMsgUserCollectionDeleteNft: "",
  loadingUserCollectionDeleteNft: false,
  errorUserCollectionDeleteNft: false,
  successUserCollectionDeleteNft: null,

  //add Nft to Colleciton
  loadingAddNftCollection: false,
  errorAddNftCollection: false,
  successAddNftCollection: null,
};

const UserCollectionNft = (state = initialState, action) => {
  switch (action.type) {
    case USER_COLLECTION_NFTS:
      return {
        ...state,
        loadingUserCollectionNft: true,
        errorUserCollectionNft: false,
        successUserCollectionNft: false,
        errorMsgUserCollectionNft: null,
      };
    case USER_COLLECTION_NFTS_SUCCESS:
      return {
        ...state,
        loadingUserCollectionNft: false,
        errorUserCollectionNft: false,
        successUserCollectionNft: true,
        nft: action.payload,
        errorMsgUserCollectionNft: null,
      };
    case USER_COLLECTION_NFTS_ERROR:
      return {
        ...state,
        errorMsgUserCollectionNft: action.payload,
        loadingUserCollectionNft: false,
        errorUserCollectionNft: true,
        successUserCollectionNft: false,
      };

    case USER_COLLECTION_NFTS_RESET:
      return {
        ...state,
        nft: null,
        successUserCollectionNft: false,
        errorMsgUserCollectionDeleteNft: "",
        loadingUserCollectionDeleteNft: false,
        errorUserCollectionDeleteNft: false,
        successUserCollectionDeleteNft: null,
      };

    case USER_COLLECTION_DELETE:
      return {
        ...state,
        loadingUserCollectionDelete: true,
        errorUserCollectionDelete: false,
        successUserCollectionDelete: false,
        errorMsgUserCollectionDelete: null,
      };
    case USER_COLLECTION_DELETE_SUCCESS:
      return {
        ...state,
        loadingUserCollectionDelete: false,
        errorUserCollectionDelete: false,
        successUserCollectionDelete: true,
        // nft: action.payload,
        errorMsgUserCollectionDelete: null,
      };
    case USER_COLLECTION_DELETE_ERROR:
      return {
        ...state,
        errorMsgUserCollectionDelete: action.payload,
        loadingUserCollectionDelete: false,
        errorUserCollectionDelete: true,
        successUserCollectionDelete: false,
      };
    case USER_COLLECTION_EDIT:
      return {
        ...state,
        loadingUserCollectionEdit: true,
        errorUserCollectionEdit: false,
        successUserCollectionEdit: false,
        errorMsgUserCollectionEdit: null,
      };
    case USER_COLLECTION_EDIT_SUCCESS:
      return {
        ...state,
        loadingUserCollectionEdit: false,
        errorUserCollectionEdit: false,
        successUserCollectionEdit: true,
        collectionEdit: action.payload,
        errorMsgUserCollectionEdit: null,
      };
    case USER_COLLECTION_EDIT_ERROR:
      return {
        ...state,
        errorMsgUserCollectionEdit: action.payload,
        loadingUserCollectionEdit: false,
        errorUserCollectionEdit: true,
        successUserCollectionEdit: false,
      };

    case USER_COLLECTION_EDIT_RESET:
      return {
        ...state,
        successUserCollectionEdit: false,
      };

    case USER_COLLECTION_DELETE_NFT:
      return {
        ...state,
        loadingUserCollectionDeleteNft: true,
        errorUserCollectionDeleteNft: false,
        successUserCollectionDeleteNft: false,
        errorMsgUserCollectionDeleteNft: null,
      };
    case USER_COLLECTION_DELETE_NFT_SUCCESS:
      return {
        ...state,
        loadingUserCollectionDeleteNft: false,
        errorUserCollectionDeleteNft: false,
        successUserCollectionDeleteNft: true,
        // nft: action.payload,
        errorMsgUserCollectionDeleteNft: null,
      };
    case USER_COLLECTION_DELETE_NFT_ERROR:
      return {
        ...state,
        errorMsgUserCollectionDeleteNft: action.payload,
        loadingUserCollectionDeleteNft: false,
        errorUserCollectionDeleteNft: true,
        successUserCollectionDeleteNft: false,
      };
    case USER_COLLECTION_DELETE_NFT_RESET:
      return {
        ...state,

        successUserCollectionDeleteNft: false,
      };

    //Add Nft to Collection
    case USER_ADD_NFT_COLLECTION:
      return {
        ...state,
        loadingAddNftCollection: true,
        errorAddNftCollection: false,
        successAddNftCollection: false,
      };
    case USER_ADD_NFT_COLLECTION_SUCCESS:
      return {
        ...state,
        loadingAddNftCollection: false,
        errorAddNftCollection: false,
        successAddNftCollection: true,
      };
    case USER_ADD_NFT_COLLECTION_ERROR:
      return {
        ...state,
        loadingAddNftCollection: false,
        errorAddNftCollection: action.payload,
        successAddNftCollection: false,
      };
    case USER_ADD_NFT_COLLECTION_RESET:
      return {
        ...state,
        loadingAddNftCollection: false,
        errorAddNftCollection: false,
        successAddNftCollection: null,
      };

    default:
      return state;
  }
};

export default UserCollectionNft;
