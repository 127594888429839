// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_ACCOUNT_FOLLOWNIGS,
  GET_ACCOUNT_FOLLOWNIGS_SUCCESS,
  GET_ACCOUNT_FOLLOWNIGS_FAILURE,
} from "./actionTypes";
import { followingSuccess, followingFailure } from "./action";
import { getAccountFollowings } from "@/config/helpers/backend_helper";
function* Following(action) {
  try {
    const { userName, requestData } = action.payload;
    //  console.log(requestData, ":::::::::", userName);
    //  console.log(requestData.userName);
    const user = requestData.userName;
    const response = yield call(getAccountFollowings, user, requestData);
    // console.log(response.Data);
    yield put(followingSuccess(response));
  } catch (error) {
    yield put(followingFailure(error));
  }
}

function* FollowingSaga() {
  yield takeLatest(GET_ACCOUNT_FOLLOWNIGS, Following);
}

export default FollowingSaga;
