// saga.js
import { call, put, takeEvery } from "redux-saga/effects";
import { postEditNft } from "@/config/helpers/backend_helper";
import { EditNftSuccess, EditNftError } from "./action";
import { EDIT_NFT } from "./actionTypes";

function* fetchEditNft(action) {
  try {
    const FormData = action.payload;
    const response = yield call(postEditNft, FormData);
    yield put(EditNftSuccess(response));
  } catch (error) {
    yield put(EditNftError(error.response.data.Errors[0].Message));
  }
}

function* EditNftSaga() {
  yield takeEvery(EDIT_NFT, fetchEditNft);
}

export default EditNftSaga;
