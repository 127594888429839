// actions.js
import {
  MUSEUM_UPCOMING,
  MUSEUM_UPCOMING_SUCCESS,
  MUSEUM_UPCOMING_ERROR,
} from "./actionTypes";

export const museumUpcomingData = () => {
  return {
    type: MUSEUM_UPCOMING,
  };
};

export const museumUpcomingDataSuccess = (data) => {
  return {
    type: MUSEUM_UPCOMING_SUCCESS,
    payload: data,
  };
};

export const museumUpcomingDataError = (data) => {
  return {
    type: MUSEUM_UPCOMING_ERROR,
    payload: data,
  };
};
