import {
  SIMILLAR_ARTWORKS_REQUEST,
  SIMILLAR_ARTWORKS_SUCCESS,
  SIMILLAR_ARTWORKS_FAILURE,
} from "./actionTypes";

export const SimillarArtworksRequest = (requestData) => ({
  type: SIMILLAR_ARTWORKS_REQUEST,
  payload: requestData,
});

export const SimillarArtworksSuccess = (data) => ({
  type: SIMILLAR_ARTWORKS_SUCCESS,
  payload: data,
});

export const SimillarArtworksFailure = (error) => ({
  type: SIMILLAR_ARTWORKS_FAILURE,
  payload: error,
});
