import {
  POST_ADD_SUI_WALLET,
  POST_ADD_SUI_WALLET_ERROR,
  POST_ADD_SUI_WALLET_SUCCESS,
  POST_MERGE_SUI_WALLET,
  POST_MERGE_SUI_WALLET_ERROR,
  POST_MERGE_SUI_WALLET_SUCCESS,
  RESET_POST_ADD_SUI_WALLET,
  RESET_POST_MERGE_SUI_WALLET,
} from "./actionTypes";

//postAddSuiWAllet
export const postAddSuiWallet = (data) => {
  return {
    type: POST_ADD_SUI_WALLET,
    payload: { data },
  };
};

export const postAddSuiWalletSuccess = (data) => {
  return {
    type: POST_ADD_SUI_WALLET_SUCCESS,
    payload: data,
  };
};

export const postAddSuiWalletError = (error) => {
  return {
    type: POST_ADD_SUI_WALLET_ERROR,
    payload: error,
  };
};
//reset states
export const resetPostAddSuiWallet = () => {
  return {
    type: RESET_POST_ADD_SUI_WALLET,
  };
};

//postMergeSuiWAllet
export const postMergeSuiWallet = (data) => {
  return {
    type: POST_MERGE_SUI_WALLET,
    payload: { data },
  };
};

export const postMergeSuiWalletSuccess = (data) => {
  return {
    type: POST_MERGE_SUI_WALLET_SUCCESS,
    payload: data,
  };
};

export const postMergeSuiWalletError = (error) => {
  return {
    type: POST_MERGE_SUI_WALLET_ERROR,
    payload: error,
  };
};

//reset states
export const resetPostMergeSuiWallet = () => {
  return {
    type: RESET_POST_MERGE_SUI_WALLET,
  };
};
