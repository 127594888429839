// reducer.js
import {
  INSPIRE_REQUEST,
  INSPIRE_SUCCESS,
  INSPIRE_FAILURE,
} from "./actionTypes";

const initialState = {
  loadingInspire: false,
  successInspire: false,
  errorInspire: null,
  nfts: null,
};
const Inspire = (state = initialState, action) => {
  switch (action.type) {
    case INSPIRE_REQUEST:
      state = {
        ...state,
        loadingInspire: true,
        successInspire: false,
        errorInspire: null,
      };
      break;
    case INSPIRE_SUCCESS:
      state = {
        ...state,
        loadingInspire: false,
        successInspire: true,
        errorInspire: null,
        nfts: action.payload,
      };
      break;
    case INSPIRE_FAILURE:
      state = {
        ...state,
        loadingInspire: false,
        successInspire: false,
        errorInspire: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Inspire;
