import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { RANDOM_USERNAME } from "./actionTypes";
import { RandomUserNameError, RandomUserNameSuccess } from "./actions";
import { getGenerateRandomUsernames } from "@/config/helpers/backend_helper";

function* Random({ payload: { user } }) {
  try {
    const response = yield call(getGenerateRandomUsernames, user);
    // console.log("randomdata" , response)
    yield put(RandomUserNameSuccess(response));
  } catch (error) {
    yield put(RandomUserNameError(error));
  }
}

export function* watchRandom() {
  yield takeEvery(RANDOM_USERNAME, Random);
}

function* RandomSaga() {
  yield all([fork(watchRandom)]);
}

export default RandomSaga;
