// reducer.js
import {
  HOME_TOP_ARTISTS,
  HOME_TOP_ARTISTS_SUCCESS,
  HOME_TOP_ARTISTS_FAILURE,
  RESET_HOME_TOP_ARTISTS,
} from "./actionTypes";

const initialState = {
  loadingTopArtists: false,
  successTopArtists: false,
  errorTopArtists: null,
  artist: null,
};
const TopArtists = (state = initialState, action) => {
  switch (action.type) {
    case HOME_TOP_ARTISTS:
      return {
        ...state,
        loadingTopArtists: true,
        successTopArtists: false,
        errorTopArtists: null,
      };
    case HOME_TOP_ARTISTS_SUCCESS:
      return {
        ...state,
        loadingTopArtists: false,
        successTopArtists: true,
        errorTopArtists: null,
        artist: action.payload,
      };
    case HOME_TOP_ARTISTS_FAILURE:
      return {
        ...state,
        loadingTopArtists: false,
        successTopArtists: false,
        errorTopArtists: action.payload,
      };
    case RESET_HOME_TOP_ARTISTS:
      console.log("inde reset");
      return {
        ...state,
        loadingTopArtists: false,
        successTopArtists: false,
        errorTopArtists: null,
        artist: null,
      };
    default:
      return state;
  }
};

export default TopArtists;
