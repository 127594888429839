import {
  MUSEUM_WELCOME,
  MUSEUM_WELCOME_SUCCESS,
  MUSEUM_WELCOME_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgMuseumHeroNfts: "",
  loadingMuseumHeroNfts: false,
  errorMuseumHeroNfts: false,
  successMuseumHeroNfts: null,
  nfts: [],
};

const MuseumWelcome = (state = initialState, action) => {
  switch (action.type) {
    case MUSEUM_WELCOME:
      return {
        ...state,
        loadingMuseumHeroNfts: true,
        errorMuseumHeroNfts: false,
        successMuseumHeroNfts: false,
        errorMsgMuseumHeroNfts: null,
      };
    case MUSEUM_WELCOME_SUCCESS:
      return {
        ...state,
        loadingMuseumHeroNfts: false,
        errorMuseumHeroNfts: false,
        successMuseumHeroNfts: true,
        nfts: action.payload,
        errorMsgMuseumHeroNfts: null,
      };
    case MUSEUM_WELCOME_ERROR:
      return {
        ...state,
        errorMsgMuseumHeroNfts: action.payload.data,
        loadingMuseumHeroNfts: false,
        errorMuseumHeroNfts: true,
        successMuseumHeroNfts: false,
      };
    default:
      return state;
  }
};

export default MuseumWelcome;
