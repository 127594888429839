// actions.js
import {
  GET_ACCOUNT_FOLLOWERS,
  GET_ACCOUNT_FOLLOWERS_SUCCESS,
  GET_ACCOUNT_FOLLOWERS_FAILURE,
} from "./actionTypes";

export const followerRequest = (userName, requestData) => ({
  type: GET_ACCOUNT_FOLLOWERS,
  payload: { userName, requestData },
});
export const followerSuccess = (data) => ({
  type: GET_ACCOUNT_FOLLOWERS_SUCCESS,
  payload: data,
});

export const followerFailure = (error) => ({
  type: GET_ACCOUNT_FOLLOWERS_FAILURE,
  payload: error,
});
