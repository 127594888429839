import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
} from "redux-saga/effects";

import { GET_MY_PROFILE } from "./actionTypes";
import { getMyProfileSuccess, getMyProfileError } from "./actions";
import { getAccountProfile } from "@/config/helpers/backend_helper";

function* fetchMyProfile({ payload: { user } }) {
  try {
    const response = yield call(getAccountProfile, user);
    // console.log("fetchmyProfile", response);
    yield put(getMyProfileSuccess(response));
  } catch (error) {
    yield put(getMyProfileError(error));
  }
}

export function* watchMyProfile() {
  // yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeLatest(GET_MY_PROFILE, fetchMyProfile);
}

function* MyProfileSaga() {
  yield all([fork(watchMyProfile)]);
}

export default MyProfileSaga;
