// actions.js
import {
  MUSEUM_WELCOME,
  MUSEUM_WELCOME_SUCCESS,
  MUSEUM_WELCOME_ERROR,
} from "./actionTypes";

export const museumWelcomeData = () => {
  return {
    type: MUSEUM_WELCOME,
  };
};

export const museumWelcomeDataSuccess = (data) => {
  return {
    type: MUSEUM_WELCOME_SUCCESS,
    payload: data,
  };
};

export const museumWelcomeDataError = (data) => {
  return {
    type: MUSEUM_WELCOME_ERROR,
    payload: data,
  };
};
