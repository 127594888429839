import {
  RECENTLY_SOLD_HOME,
  RECENTLY_SOLD_HOME_SUCCESS,
  RECENTLY_SOLD_HOME_ERROR,
  RESET_RECENTLY_SOLD_HOME,
} from "./actionTypes";

//RecentlySold data
export const RecentlyData = () => {
  return {
    type: RECENTLY_SOLD_HOME,
  };
};

export const RecentlyDataSuccess = (data) => {
  return {
    type: RECENTLY_SOLD_HOME_SUCCESS,
    payload: data,
  };
};

export const RecentlyDataError = (data) => {
  return {
    type: RECENTLY_SOLD_HOME_ERROR,
    payload: data,
  };
};

//reset states
export const resetRecentlyStates = () => {
  return {
    type: RESET_RECENTLY_SOLD_HOME,
  };
};
