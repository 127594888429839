import {
  VERIFICATION_FORM,
  VERIFICATION_FORM_SUCCESS,
  VERIFICATION_FORM_ERROR,
  RESET_VERIFICATION_FORM,
} from "./actionTypes";

const initialState = {
  errorMsgVerification: null,
  loadingVerification: false,
  errorVerification: false,
  successVerification: null,
  data: [],
};

const VerificationForm = (state = initialState, action) => {
  switch (action.type) {
    //VERIFICATION_FORM
    case VERIFICATION_FORM:
      state = {
        ...state,
        loadingVerification: true,
        errorVerification: false,
        successVerification: false,
        errorMsgVerification: null,
      };
      break;
    case VERIFICATION_FORM_SUCCESS:
      state = {
        ...state,
        loadingVerification: false,
        errorMsgVerification: false,
        successVerification: true,
        data: action.payload,
      };
      break;
    case VERIFICATION_FORM_ERROR:
      state = {
        ...state,
        errorMsgVerification: action.payload,
        loadingVerification: false,
        errorVerification: true,
        successVerification: false,
      };
      break;
    case RESET_VERIFICATION_FORM:
      state = {
        ...state,
        errorMsgVerification: null,
        loadingVerification: false,
        errorVerification: false,
        successVerification: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VerificationForm;
