// NFTS_NFT_CID
export const NFTS_NFT_CID = "NFTS_NFT_CID";
export const NFTS_NFT_CID_SUCCESS = "NFTS_NFT_CID_SUCCESS";
export const NFTS_NFT_CID_ERROR = "NFTS_NFT_CID_ERROR";

// NFTS_NFT_CID
export const NFTS_LOG_MINT_NFT = "NFTS_LOG_MINT_NFT";
export const NFTS_LOG_MINT_NFT_SUCCESS = "NFTS_LOG_MINT_NFT_SUCCESS";
export const NFTS_LOG_MINT_NFT_ERROR = "NFTS_LOG_MINT_NFT_ERROR";

//RESET
export const REST_MINT_STATES = "REST_MINT_STATES";
