import {
  REFERRAL_DASHBOARD_ARTIST,
  REFERRAL_DASHBOARD_ARTIST_SUCCESS,
  REFERRAL_DASHBOARD_ARTIST_ERROR,
  RESET_REFERRAL_DASHBOARD_ARTIST,
} from "./actionTypes";

const initialState = {
  errorMsgHeroUsers: "",
  loadingHeroUsers: false,
  errorHeroUsers: false,
  successHeroUsers: null,
  users: [],
};

const ReferralDashboard = (state = initialState, action) => {
  switch (action.type) {
    case REFERRAL_DASHBOARD_ARTIST:
      return {
        ...state,
        loadingHeroUsers: true,
        errorHeroUsers: false,
        successHeroUsers: false,
        errorMsgHeroUsers: null,
      };
    case REFERRAL_DASHBOARD_ARTIST_SUCCESS:
      return {
        ...state,
        loadingHeroUsers: false,
        errorHeroUsers: false,
        successHeroUsers: true,
        users: action.payload,
        errorMsgHeroUsers: null,
      };
    case REFERRAL_DASHBOARD_ARTIST_ERROR:
      return {
        ...state,
        errorMsgHeroUsers: action.payload.data,
        loadingHeroUsers: false,
        errorHeroUsers: true,
        successHeroUsers: false,
      };
    case RESET_REFERRAL_DASHBOARD_ARTIST:
      return {
        users: [],
        errorHeroUsers: false,
        successHeroUsers: false,
      };
    default:
      return state;
  }
};

export default ReferralDashboard;
