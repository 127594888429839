// actionTypes.js
export const UPLOAD_NFT = "UPLOAD_NFT";
export const UPLOAD_NFT_SUCCESS = "UPLOAD_NFT_SUCCESS";
export const UPLOAD_NFT_ERROR = "UPLOAD_NFT_ERROR";
export const RESET_UPLOAD_NFT = "RESET_UPLOAD_NFT";

//ai description
export const GENERATE_DESCRIPTION = "GENERATE_DESCRIPTION";
export const GENERATE_DESCRIPTION_SUCCESS = "GENERATE_DESCRIPTION_SUCCESS";
export const GENERATE_DESCRIPTION_ERROR = "GENERATE_DESCRIPTION_ERROR";

//ai shorter description
export const SHORTEREN_DESCRIPTION = "SHORTEREN_DESCRIPTION";
export const SHORTEREN_DESCRIPTION_SUCCESS = "SHORTEREN_DESCRIPTION_SUCCESS";
export const SHORTEREN_DESCRIPTION_ERROR = "SHORTEREN_DESCRIPTION_ERROR";

//Get User Referral Promotion
export const GET_USER_REFERRAL_PROMOTION = "GET_USER_REFERRAL_PROMOTION";
export const GET_USER_REFERRAL_PROMOTION_SUCCESS =
  "GET_USER_REFERRAL_PROMOTION_SUCCESS";
export const GET_USER_REFERRAL_PROMOTION_ERROR =
  "GET_USER_REFERRAL_PROMOTION_ERROR";

export const RESET_USER_REFERRAL_PROMOTION = "RESET_USER_REFERRAL_PROMOTION";
