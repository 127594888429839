import { call, put, takeEvery } from "redux-saga/effects";
import { getMuseumCalendarSection } from "@/config/helpers/backend_helper";
import { museumCalendarDataSuccess, museumCalendarDataError } from "./actions";
import { MUSEUM_CALENDAR } from "./actionTypes";

function* fetchMuseumCalendarData(action) {
  try {
    //  console.log(action.payload);
    const response = yield call(getMuseumCalendarSection, action.payload);
    yield put(museumCalendarDataSuccess(response));
  } catch (error) {
    yield put(museumCalendarDataError(error));
  }
}

function* museumCalendarSaga() {
  yield takeEvery(MUSEUM_CALENDAR, fetchMuseumCalendarData);
}

export default museumCalendarSaga;
