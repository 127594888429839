import {
  HOT_BIDS_HOME,
  HOT_BIDS_HOME_SUCCESS,
  HOT_BIDS_HOME_ERROR,
  RESET_HOT_BIDS_HOME,
} from "./actionTypes";

const initialState = {
  errorMsgHotBids: "",
  loadingHotBids: false,
  errorHotBids: false,
  successHotBids: null,
  nfts: null,
};

const HotBids = (state = initialState, action) => {
  switch (action.type) {
    case HOT_BIDS_HOME:
      state = {
        ...state,
        loadingHotBids: true,
        errorHotBids: false,
        successHotBids: false,
        errorMsgHotBids: null,
      };
      break;
    case HOT_BIDS_HOME_SUCCESS:
      state = {
        ...state,
        loadingHotBids: false,
        errorHotBids: false,
        successHotBids: true,
        nfts: action.payload,
        errorMsgHotBids: null,
      };
      break;
    case HOT_BIDS_HOME_ERROR:
      state = {
        ...state,
        errorMsgHotBids: action.payload,
        loadingHotBids: false,
        errorHotBids: true,
        successHotBids: false,
      };
      break;
    case RESET_HOT_BIDS_HOME:
      state = {
        ...state,
        errorMsgHotBids: "",
        loadingHotBids: false,
        errorHotBids: false,
        successHotBids: null,
        nfts: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default HotBids;
