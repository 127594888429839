import { takeLatest, call, put } from "redux-saga/effects";
import {
  FAVORITES_NFTCARD_REQUEST,
  FAVORITES_NFTCARD_SUCCESS,
  FAVORITES_NFTCARD_FAILURE,
  FAVORITES_COLLECTION_REQUEST,
} from "./actionTypes";
import {
  FavoritesNftCardSuccess,
  FavoritesNftCardFailure,
  FavoritesCollectionSuccess,
  FavoritesCollectionFailure,
} from "./action";
import {
  getNftCards,
  getAccountCollectionNfts,
} from "@/config/helpers/backend_helper";

function* FavoritesNftCard(action) {
  try {
    //   console.log(action.payload);
    const response = yield call(getNftCards, action.payload);
    //  console.log(response);
    yield put(FavoritesNftCardSuccess(response));
  } catch (error) {
    yield put(FavoritesNftCardFailure(error));
  }
}
function* CollectioncFavoritesCard(action) {
  try {
    //   console.log(action.payload);
    const response = yield call(getAccountCollectionNfts, action.payload);
    //  console.log(response);
    yield put(FavoritesCollectionSuccess(response));
  } catch (error) {
    yield put(FavoritesCollectionFailure(error));
  }
}

function* FavoritesNftCardSaga() {
  yield takeLatest(FAVORITES_NFTCARD_REQUEST, FavoritesNftCard);
  yield takeLatest(FAVORITES_COLLECTION_REQUEST, CollectioncFavoritesCard);
}

export default FavoritesNftCardSaga;
