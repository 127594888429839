import {
  //GET_MY_WITHDRAWALS
  GET_MY_WITHDRAWALS,
  GET_MY_WITHDRAWALS_ERROR,
  GET_MY_WITHDRAWALS_SUCCESS,

  //REQUEST_WITHDRAWALS
  REQUEST_WITHDRAWALS,
  REQUEST_WITHDRAWALS_ERROR,
  REQUEST_WITHDRAWALS_SUCCESS,

  //CANCEL_WITHDRAWALS
  CANCEL_WITHDRAWALS,
  CANCEL_WITHDRAWALS_ERROR,
  CANCEL_WITHDRAWALS_SUCCESS,
  RESET_WITHDRAWALS_FLAG,
} from "./actionTypes";

const initialState = {
  //getTwitterMyWithdrawals
  getMyWithdrawalSuccessMsg: null,
  getMyWithdrawal: null,
  getMyWithdrawalError: false,
  getMyWithdrawalSuccess: false,
  getMyWithdrawalLoading: null,
  getMyWithdrawalMessage: null,

  //postTwitterMyActivities
  requestWithdrawalSuccessMsg: null,
  requestWithdrawal: null,
  requestWithdrawalError: false,
  requestWithdrawalSuccess: false,
  requestWithdrawalLoading: null,
  requestWithdrawalMessage: null,

  //getUpdateUserLeague
  cancelWithdrawalSuccessMsg: null,
  cancelWithdrawal: null,
  cancelWithdrawalError: false,
  cancelWithdrawalSuccess: false,
  cancelWithdrawalLoading: null,
  cancelWithdrawalMessage: null,
};

const TwitterWithdrawalsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET_MY_WITHDRAWALS
    case GET_MY_WITHDRAWALS:
      state = {
        ...state,
        getMyWithdrawalSuccessMsg: null,
        getMyWithdrawalError: null,
        getMyWithdrawalSuccess: null,
        getMyWithdrawalLoading: true,
        getMyWithdrawalMessage: null,
      };
      break;
    case GET_MY_WITHDRAWALS_SUCCESS:
      state = {
        ...state,
        getMyWithdrawalLoading: false,
        getMyWithdrawal: action.payload,
        getMyWithdrawalSuccess: true,
        getMyWithdrawalError: false,
      };
      break;
    case GET_MY_WITHDRAWALS_ERROR:
      state = {
        ...state,
        getMyWithdrawalLoading: false,
        getMyWithdrawalError: true,
        getMyWithdrawalMessage: action.payload,
      };
      break;

    // REQUEST_WITHDRAWALS
    case REQUEST_WITHDRAWALS:
      state = {
        ...state,
        requestWithdrawalSuccessMsg: null,
        requestWithdrawalError: null,
        requestWithdrawalSuccess: null,
        requestWithdrawalLoading: true,
        requestWithdrawalMessage: null,
      };
      break;
    case REQUEST_WITHDRAWALS_SUCCESS:
      state = {
        ...state,
        requestWithdrawalLoading: false,
        requestWithdrawal: action.payload,
        requestWithdrawalSuccess: true,
        requestWithdrawalError: false,
      };
      break;
    case REQUEST_WITHDRAWALS_ERROR:
      state = {
        ...state,
        requestWithdrawalLoading: false,
        requestWithdrawalError: true,
        requestWithdrawalMessage: action.payload,
      };
      break;

    // CANCEL_WITHDRAWALS
    case CANCEL_WITHDRAWALS:
      state = {
        ...state,
        cancelWithdrawalSuccessMsg: null,
        cancelWithdrawalError: null,
        cancelWithdrawalSuccess: null,
        cancelWithdrawalLoading: true,
        cancelWithdrawalMessage: null,
      };
      break;
    case CANCEL_WITHDRAWALS_SUCCESS:
      state = {
        ...state,
        cancelWithdrawalLoading: false,
        cancelWithdrawal: action.payload,
        cancelWithdrawalSuccess: true,
        cancelWithdrawalError: false,
      };
      break;
    case CANCEL_WITHDRAWALS_ERROR:
      state = {
        ...state,
        cancelWithdrawalLoading: false,
        cancelWithdrawalError: true,
        cancelWithdrawalMessage: action.payload,
      };
      break;

    case RESET_WITHDRAWALS_FLAG:
      state = {
        ...state,
        getMyWithdrawalLoading: false,
        getMyWithdrawalSuccess: false,
        getMyWithdrawalError: false,
        getMyWithdrawalMessage: null,
        getMyWithdrawalSuccessMsg: null,

        requestWithdrawalSuccessMsg: null,
        requestWithdrawalError: false,
        requestWithdrawalSuccess: false,
        requestWithdrawalLoading: null,
        requestWithdrawalMessage: null,

        cancelWithdrawalSuccessMsg: null,
        cancelWithdrawalError: false,
        cancelWithdrawalSuccess: false,
        cancelWithdrawalLoading: null,
        cancelWithdrawalMessage: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default TwitterWithdrawalsReducer;
