import { takeLatest, call, put } from "redux-saga/effects";
import {
  SIMILLAR_ARTWORKS_REQUEST,
  SIMILLAR_ARTWORKS_SUCCESS,
  SIMILLAR_ARTWORKS_FAILURE,
} from "./actionTypes";
import { SimillarArtworksSuccess, SimillarArtworksFailure } from "./action";
import { getNftCards } from "@/config/helpers/backend_helper";

function* SimillarArtworks(action) {
  try {
    //   console.log(action.payload);
    const response = yield call(getNftCards, action.payload);
    //  console.log(response);
    yield put(SimillarArtworksSuccess(response));
  } catch (error) {
    yield put(SimillarArtworksFailure(error));
  }
}

function* SimillarArtworksSaga() {
  yield takeLatest(SIMILLAR_ARTWORKS_REQUEST, SimillarArtworks);
}

export default SimillarArtworksSaga;
