import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getHomeTopCurators } from "@/config/helpers/backend_helper";
import { topCuratorsDataSuccess, topCuratorsDataError } from "./actions";
import { TOP_CURATORS_HOME } from "./actionTypes";

function* fetchTopCuratorsData() {
  try {
    const response = yield call(getHomeTopCurators);

    yield put(topCuratorsDataSuccess(response));
  } catch (error) {
    yield put(topCuratorsDataError(error));
  }
}

function* topCuratorsSaga() {
  yield takeLatest(TOP_CURATORS_HOME, fetchTopCuratorsData);
}

export default topCuratorsSaga;
