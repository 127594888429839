import {
  //GET_TWITTER_FARM_EXTRACT_POSTS
  GET_TWITTER_FARM_EXTRACT_POSTS_ACTION,
  GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_ERROR,
  GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_SUCCESS,
  //GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED
  GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_ERROR,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_SUCCESS,
  //GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED
  GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_ERROR,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_SUCCESS,
  //GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED
  GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_ERROR,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_SUCCESS,
  //GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS
  GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_ERROR,
  GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_SUCCESS,
  RESET_ALL_TWITTER_FARM_FLAG,

  //POST_TWITTER_FARM_MY_POINTS
  POST_TWITTER_FARM_MY_POINTS,
  POST_TWITTER_FARM_MY_POINTS_SUCCESS,
  POST_TWITTER_FARM_MY_POINTS_ERROR,
} from "./actionTypes";

//POST_TWITTER_FARM_MY_POINTS
export const TwitterMyPointsPost = (data) => {
  return {
    type: POST_TWITTER_FARM_MY_POINTS,
    payload: data,
  };
};

export const TwitterMyPointsPostSuccess = (data) => {
  return {
    type: POST_TWITTER_FARM_MY_POINTS_SUCCESS,
    payload: data,
  };
};

export const postTwitterMyPointsPostError = (error) => {
  return {
    type: POST_TWITTER_FARM_MY_POINTS_ERROR,
    payload: error,
  };
};

//getExtractTwitterPostsAction
export const getExtractTwitterPostsAction = () => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POSTS_ACTION,
  };
};

export const getExtractTwitterPostsActionSuccess = (data) => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_SUCCESS,
    payload: data,
  };
};

export const getExtractTwitterPostsActionError = (data) => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POSTS_ACTION_ERROR,
    payload: data,
  };
};

//getExtractPostUsersRetweetedAction
export const getExtractPostUsersRetweetedAction = () => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION,
  };
};

export const getExtractPostUsersRetweetedActionSuccess = (data) => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_SUCCESS,
    payload: data,
  };
};

export const getExtractPostUsersRetweetedActionError = (data) => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_RETWEETED_ACTION_ERROR,
    payload: data,
  };
};

//getExtractPostUsersLikedAction
export const getExtractPostUsersLikedAction = () => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION,
  };
};

export const getExtractPostUsersLikedActionSuccess = (data) => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_SUCCESS,
    payload: data,
  };
};

export const getExtractPostUsersLikedActionError = (data) => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_LIKED_ACTION_ERROR,
    payload: data,
  };
};

//getExtractPostUsersReplyedAction
export const getExtractPostUsersReplyedAction = () => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION,
  };
};

export const getExtractPostUsersReplyedActionSuccess = (data) => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_SUCCESS,
    payload: data,
  };
};

export const getExtractPostUsersReplyedActionError = (data) => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_REPLYED_ACTION_ERROR,
    payload: data,
  };
};

//getExtractTwitterFollowersAction
export const getExtractTwitterFollowersAction = () => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION,
  };
};

export const getExtractTwitterFollowersActionSuccess = (data) => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_SUCCESS,
    payload: data,
  };
};

export const getExtractTwitterFollowersActionError = (data) => {
  return {
    type: GET_TWITTER_FARM_EXTRACT_POST_USERS_FOLLOWERS_ACTION_ERROR,
    payload: data,
  };
};

//resetAllTwitterFarmActionFlag
export const resetAllTwitterFarmActionFlag = () => {
  return {
    type: RESET_ALL_TWITTER_FARM_FLAG,
  };
};
