// actions.js
import {
  GET_LANDING_PAGE_WALLET_DATA_ACTION,
  GET_LANDING_PAGE_WALLET_DATA_ACTION_SUCCESS,
  GET_LANDING_PAGE_WALLET_DATA_ACTION_FAILURE,
  RESET_GET_LANDING_PAGE_WALLET_DATA_ACTION,
} from "./actionTypes";

export const getLandingPageWalletDataAction = (data) => ({
  type: GET_LANDING_PAGE_WALLET_DATA_ACTION,
  payload: data,
});

export const getLandingPageWalletDataActionSuccess = (data) => ({
  type: GET_LANDING_PAGE_WALLET_DATA_ACTION_SUCCESS,
  payload: data,
});

export const getLandingPageWalletDataActionFailure = (error) => ({
  type: GET_LANDING_PAGE_WALLET_DATA_ACTION_FAILURE,
  payload: error,
});

export const resetGetlandingPageWalletDataAction = () => ({
  type: RESET_GET_LANDING_PAGE_WALLET_DATA_ACTION,
});
