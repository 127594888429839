import {
  POST_ADD_ETH_WALLET,
  POST_ADD_ETH_WALLET_ERROR,
  POST_ADD_ETH_WALLET_SUCCESS,
  // POST_MERGE_ETH_WALLET,
  // POST_MERGE_ETH_WALLET_ERROR,
  // POST_MERGE_ETH_WALLET_SUCCESS,
  RESET_POST_ADD_ETH_WALLET,
  // RESET_POST_MERGE_ETH_WALLET,
} from "./actionTypes";

const initialState = {
  AddEthWalletError: null,
  AddEthWalletSuccess: null,
  AddEthWallet: [],
  AddEthWalletMessage: null,
  AddEthWalletLoading: null,

  // MergeEthWalletError: null,
  // MergeEthWalletSuccess: null,
  // MergeEthWallet: [],
  // MergeEthWalletMessage: null,
  // MergeEthWalletLoading: null,
};

const EthWalletAccount = (state = initialState, action) => {
  switch (action.type) {
    //POST_ADD_ETH_WALLET
    case POST_ADD_ETH_WALLET:
      state = {
        ...state,
        AddEthWalletError: false,
        AddEthWalletSuccess: false,
        AddEthWalletLoading: true,
      };
      break;
    case POST_ADD_ETH_WALLET_SUCCESS:
      state = {
        ...state,
        AddEthWalletError: false,
        AddEthWalletSuccess: true,
        AddEthWallet: action.payload,
        AddEthWalletMessage: null,
        AddEthWalletLoading: false,
      };
      break;
    case POST_ADD_ETH_WALLET_ERROR:
      state = {
        ...state,
        AddEthWalletError: true,
        AddEthWalletSuccess: false,
        AddEthWallet: [],
        AddEthWalletMessage: action.payload,
        AddEthWalletLoading: false,
      };
      break;
    case RESET_POST_ADD_ETH_WALLET:
      state = {
        ...state,
        AddEthWalletError: null,
        AddEthWalletSuccess: null,
        AddEthWallet: [],
        AddEthWalletMessage: null,
        AddEthWalletLoading: null,
      };
      break;

    // //POST_MERGE_ETH_WALLET
    // case POST_MERGE_ETH_WALLET:
    //   state = {
    //     ...state,
    //     MergeEthWalletError: false,
    //     MergeEthWalletSuccess: false,
    //     MergeEthWalletLoading: true,
    //   };
    //   break;
    // case POST_MERGE_ETH_WALLET_SUCCESS:
    //   state = {
    //     ...state,
    //     MergeEthWalletError: false,
    //     MergeEthWalletSuccess: true,
    //     MergeEthWallet: action.payload,
    //     MergeEthWalletMessage: null,
    //     MergeEthWalletLoading: false,
    //   };
    //   console.log("in success create");
    //   break;
    // case POST_MERGE_ETH_WALLET_ERROR:
    //   state = {
    //     ...state,
    //     MergeEthWalletError: true,
    //     MergeEthWalletSuccess: false,
    //     MergeEthWallet: [],
    //     MergeEthWalletMessage: action.payload,
    //     MergeEthWalletLoading: false,
    //   };
    //   break;
    // case RESET_POST_MERGE_ETH_WALLET:
    //   state = {
    //     ...state,
    //     MergeEthWalletError: null,
    //     MergeEthWalletSuccess: null,
    //     MergeEthWallet: [],
    //     MergeEthWalletMessage: null,
    //     MergeEthWalletLoading: null,
    //   };
    //   break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EthWalletAccount;
