import {
  FEATURED_ARTIST_HOME,
  FEATURED_ARTIST_HOME_SUCCESS,
  FEATURED_ARTIST_HOME_ERROR,
  RESET_FEATURED_ARTIST_HOME,
} from "./actionTypes";

//FeaturedArtists data
export const featuredData = () => {
  return {
    type: FEATURED_ARTIST_HOME,
  };
};

export const featuredDataSuccess = (data) => {
  return {
    type: FEATURED_ARTIST_HOME_SUCCESS,
    payload: data,
  };
};

export const featuredDataError = (data) => {
  return {
    type: FEATURED_ARTIST_HOME_ERROR,
    payload: data,
  };
};

//reset states
export const resetFeaturedArtworksStates = () => {
  return {
    type: RESET_FEATURED_ARTIST_HOME,
  };
};
