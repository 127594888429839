// actions.js
import {
  TOP_COLLECTORS_HOME,
  TOP_COLLECTORS_HOME_SUCCESS,
  TOP_COLLECTORS_HOME_ERROR,
} from "./actionTypes";

export const topCollectorsData = () => {
  return {
    type: TOP_COLLECTORS_HOME,
  };
};

export const topCollectorsDataSuccess = (data) => {
  return {
    type: TOP_COLLECTORS_HOME_SUCCESS,
    payload: data,
  };
};

export const topCollectorsDataError = (error) => {
  return {
    type: TOP_COLLECTORS_HOME_ERROR,
    payload: error,
  };
};
