import {
  COVER_PROFILE,
  COVER_PROFILE_ERROR,
  COVER_PROFILE_SUCCESS,
} from "./actionTypes";


//coverProfile
export const coverProfile = (userName) => {
  return {
    type: COVER_PROFILE,
    payload: { userName },
  };
};

export const coverProfileSuccess = (userName) => {
  return {
    type: COVER_PROFILE_SUCCESS,
    payload: userName,
  };
};

export const coverProfileError = (error) => {
  return {
    type: COVER_PROFILE_ERROR,
    payload: error,
  };
};

//resetFlags
export const resetCoverProfileFlag = () => {
  return {
    type: RESET_COVER_PAGE,
  };
};

