// actions.js
import {
  ANNOUNCEMENT_NEWS_HOME,
  ANNOUNCEMENT_NEWS_HOME_SUCCESS,
  ANNOUNCEMENT_NEWS_HOME_ERROR,
} from "./actionTypes";

export const announcementNewsData = () => {
  return {
    type: ANNOUNCEMENT_NEWS_HOME,
  };
};

export const announcementNewsDataSuccess = (data) => {
  return {
    type: ANNOUNCEMENT_NEWS_HOME_SUCCESS,
    payload: data,
  };
};

export const announcementNewsDataError = (data) => {
  return {
    type: ANNOUNCEMENT_NEWS_HOME_ERROR,
    payload: data,
  };
};
