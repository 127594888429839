import {
  //GET_MY_STAKES
  GET_MY_STAKES,
  GET_MY_STAKES_ERROR,
  GET_MY_STAKES_SUCCESS,

  //REQUEST_STAKES
  REQUEST_STAKES,
  REQUEST_STAKES_ERROR,
  REQUEST_STAKES_SUCCESS,

  //CANCEL_STAKES
  CANCEL_STAKES,
  CANCEL_STAKES_ERROR,
  CANCEL_STAKES_SUCCESS,
  RESET_STAKES_FLAG,
} from "./actionTypes";

const initialState = {
  //getMyStake
  getMyStakeSuccessMsg: null,
  getMyStake: null,
  getMyStakeError: false,
  getMyStakeSuccess: false,
  getMyStakeLoading: null,
  getMyStakeMessage: null,

  //requestStake
  requestStakeSuccessMsg: null,
  requestStake: null,
  requestStakeError: false,
  requestStakeSuccess: false,
  requestStakeLoading: null,
  requestStakeMessage: null,

  //cancelStake
  cancelStakeSuccessMsg: null,
  cancelStake: null,
  cancelStakeError: false,
  cancelStakeSuccess: false,
  cancelStakeLoading: null,
  cancelStakeMessage: null,
};

const TwitterStakesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET_MY_STAKES
    case GET_MY_STAKES:
      state = {
        ...state,
        getMyStakeSuccessMsg: null,
        getMyStakeError: null,
        getMyStakeSuccess: null,
        getMyStakeLoading: true,
        getMyStakeMessage: null,
      };
      break;
    case GET_MY_STAKES_SUCCESS:
      state = {
        ...state,
        getMyStakeLoading: false,
        getMyStake: action.payload,
        getMyStakeSuccess: true,
        getMyStakeError: false,
      };
      break;
    case GET_MY_STAKES_ERROR:
      state = {
        ...state,
        getMyStakeLoading: false,
        getMyStakeError: true,
        getMyStakeMessage: action.payload,
      };
      break;

    // REQUEST_STAKES
    case REQUEST_STAKES:
      state = {
        ...state,
        requestStakeSuccessMsg: null,
        requestStakeError: null,
        requestStakeSuccess: null,
        requestStakeLoading: true,
        requestStakeMessage: null,
      };
      break;
    case REQUEST_STAKES_SUCCESS:
      state = {
        ...state,
        requestStakeLoading: false,
        requestStake: action.payload,
        requestStakeSuccess: true,
        requestStakeError: false,
      };
      break;
    case REQUEST_STAKES_ERROR:
      state = {
        ...state,
        requestStakeLoading: false,
        requestStakeError: true,
        requestStakeMessage: action.payload,
      };
      break;

    // CANCEL_STAKES
    case CANCEL_STAKES:
      state = {
        ...state,
        cancelStakeSuccessMsg: null,
        cancelStakeError: null,
        cancelStakeSuccess: null,
        cancelStakeLoading: true,
        cancelStakeMessage: null,
      };
      break;
    case CANCEL_STAKES_SUCCESS:
      state = {
        ...state,
        cancelStakeLoading: false,
        cancelStake: action.payload,
        cancelStakeSuccess: true,
        cancelStakeError: false,
      };
      break;
    case CANCEL_STAKES_ERROR:
      state = {
        ...state,
        cancelStakeLoading: false,
        cancelStakeError: true,
        cancelStakeMessage: action.payload,
      };
      break;

    case RESET_STAKES_FLAG:
      state = {
        ...state,
        getMyStakeLoading: false,
        getMyStakeSuccess: false,
        getMyStakeError: false,
        getMyStakeMessage: null,
        getMyStakeSuccessMsg: null,

        requestStakeSuccessMsg: null,
        requestStakeError: false,
        requestStakeSuccess: false,
        requestStakeLoading: null,
        requestStakeMessage: null,

        cancelStakeSuccessMsg: null,
        cancelStakeError: false,
        cancelStakeSuccess: false,
        cancelStakeLoading: null,
        cancelStakeMessage: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default TwitterStakesReducer;
