import {
  EDIT_GALLARY,
  EDIT_GALLARY_ERROR,
  EDIT_GALLARY_SUCCESS,
  RESET_EDIT_GALLARY,
  RESET_ALL_GALLARY_DATA,
} from "./actionTypes";

//editGallary
export const editGallary = (coverType) => {
  return {
    type: EDIT_GALLARY,
    payload: { coverType },
  };
};

export const editGallarySuccess = (coverType) => {
  return {
    type: EDIT_GALLARY_SUCCESS,
    payload: coverType,
  };
};

export const editGallaryError = (error) => {
  return {
    type: EDIT_GALLARY_ERROR,
    payload: error,
  };
};

//resetFlags
export const reseteditGallaryFlag = () => {
  return {
    type: RESET_EDIT_GALLARY,
  };
};

export const resetEditAllGallaryDataFlag = () => {
  return {
    type: RESET_ALL_GALLARY_DATA,
  };
};
