import { takeLatest, call, put } from "redux-saga/effects";
import {
  ARCHIVE_NFTCARD_REQUEST,
  ARCHIVE_NFTCARD_SUCCESS,
  ARCHIVE_NFTCARD_FAILURE,
} from "./actionTypes";
import { ArchiveNftCardSuccess, ArchiveNftCardFailure } from "./action";
import { getNftCards } from "@/config/helpers/backend_helper";

function* ArchiveNftCard(action) {
  try {
    //   console.log(action.payload);
    const response = yield call(getNftCards, action.payload);
    //  console.log(response);
    yield put(ArchiveNftCardSuccess(response));
  } catch (error) {
    yield put(ArchiveNftCardFailure(error));
  }
}

function* ArchiveNftCardSaga() {
  yield takeLatest(ARCHIVE_NFTCARD_REQUEST, ArchiveNftCard);
}

export default ArchiveNftCardSaga;
