import {
  TESTIMONIAL_HOME,
  TESTIMONIAL_HOME_SUCCESS,
  TESTIMONIAL_HOME_ERROR,
  RESET_TESTIMONIAL_HOME,
} from "./actionTypes";

const initialState = {
  errorMsgTestimonial: "",
  loadingTestimonial: false,
  errorTestimonial: false,
  successTestimonial: null,
  Testimonial: null,
};

const Testimonial = (state = initialState, action) => {
  switch (action.type) {
    //TESTIMONIAL_HOME
    case TESTIMONIAL_HOME:
      state = {
        ...state,
        loadingTestimonial: true,
        errorTestimonial: false,
        successTestimonial: false,
        errorMsgTestimonial: null,
      };
      break;
    case TESTIMONIAL_HOME_SUCCESS:
      state = {
        ...state,
        loadingTestimonial: false,
        errorMsgTestimonial: false,
        successTestimonial: true,
        Testimonial: action.payload,
      };
      break;
    case TESTIMONIAL_HOME_ERROR:
      state = {
        ...state,
        errorMsgTestimonial: action.payload.data,
        loadingTestimonial: false,
        errorTestimonial: true,
        successTestimonial: false,
      };
      break;
    case RESET_TESTIMONIAL_HOME:
      state = {
        ...state,
        errorMsgTestimonial: null,
        loadingTestimonial: false,
        errorTestimonial: false,
        successTestimonial: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Testimonial;
