import {
  ALL_NFTCARD_REQUEST,
  ALL_NFTCARD_SUCCESS,
  ALL_NFTCARD_FAILURE,
  RESET_ALL_NFTCARD_FAILURE,
} from "./actionTypes";

const initialState = {
  loadingAllNftCard: false,
  successAllNftCard: false,
  errorAllNftCard: null,
  nfts: [],
};
const AllNftCard = (state = initialState, action) => {
  switch (action.type) {
    case ALL_NFTCARD_REQUEST:
      return {
        ...state,
        loadingAllNftCard: true,
        successAllNftCard: false,
        errorAllNftCard: null,
      };
    case ALL_NFTCARD_SUCCESS:
      return {
        ...state,
        loadingAllNftCard: false,
        successAllNftCard: true,
        errorAllNftCard: null,
        nfts: action.payload,
      };
    case ALL_NFTCARD_FAILURE:
      return {
        ...state,
        loadingAllNftCard: false,
        successAllNftCard: false,
        errorAllNftCard: action.payload,
      };
    case RESET_ALL_NFTCARD_FAILURE:
      return {
        ...state,
        nfts: [],
        successAllNftCard : false,
      };
    default:
      return state;
  }
};

export default AllNftCard;
