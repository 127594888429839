//EXCLUSIVE_COLLECTIONS_HOME
export const EXCLUSIVE_COLLECTIONS_HOME = "EXCLUSIVE_COLLECTIONS_HOME";
export const EXCLUSIVE_COLLECTIONS_HOME_SUCCESS =
  "EXCLUSIVE_COLLECTIONS_HOME_SUCCESS";
export const EXCLUSIVE_COLLECTIONS_HOME_ERROR =
  "EXCLUSIVE_COLLECTIONS_HOME_ERROR";

export const RESET_EXCLUSIVE_COLLECTIONS_HOME =
  "RESET_EXCLUSIVE_COLLECTIONS_HOME";

//POST_EXCLUSIVE_COLLECTIONS_HOME
export const POST_EXCLUSIVE_COLLECTIONS_HOME =
  "POST_EXCLUSIVE_COLLECTIONS_HOME";
export const POST_EXCLUSIVE_COLLECTIONS_HOME_SUCCESS =
  "POST_EXCLUSIVE_COLLECTIONS_HOME_SUCCESS";
export const POST_EXCLUSIVE_COLLECTIONS_HOME_ERROR =
  "POST_EXCLUSIVE_COLLECTIONS_HOME_ERROR";

export const RESET_POST_EXCLUSIVE_COLLECTIONS_HOME =
  "RESET_POST_EXCLUSIVE_COLLECTIONS_HOME";
