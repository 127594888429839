import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getHomeNews } from "@/config/helpers/backend_helper";
import {
  announcementNewsDataSuccess,
  announcementNewsDataError,
} from "./actions";
import { ANNOUNCEMENT_NEWS_HOME } from "./actionTypes";

function* fetchannouncementNewsData() {
  try {
    const response = yield call(getHomeNews);

    yield put(announcementNewsDataSuccess(response));
  } catch (error) {
    yield put(announcementNewsDataError(error));
  }
}

function* announcementNewsSaga() {
  yield takeLatest(ANNOUNCEMENT_NEWS_HOME, fetchannouncementNewsData);
}

export default announcementNewsSaga;
