import {
  //GET_TWITTER_FARM_EXTRACT_POSTS
  GET_TWITTER_FARM_GET_MY_POINT,
  GET_TWITTER_FARM_GET_MY_POINT_ERROR,
  GET_TWITTER_FARM_GET_MY_POINT_SUCCESS,

  //POST_TWITTER_FARM_MY_ACTIVITIES_ACTION
  POST_TWITTER_FARM_MY_ACTIVITIES_ACTION,
  POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_ERROR,
  POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_SUCCESS,

  //POST_TWITTER_FARM_MY_POINTS
  POST_TWITTER_FARM_MY_POINTS,
  POST_TWITTER_FARM_MY_POINTS_ERROR,
  POST_TWITTER_FARM_MY_POINTS_SUCCESS,

  //POST_TWITTER_FARM_MY_SEED
  POST_TWITTER_FARM_MY_SEED,
  POST_TWITTER_FARM_MY_SEED_ERROR,
  POST_TWITTER_FARM_MY_SEED_SUCCESS,

  //POST_TWITTER_FARM_ALL_USER_POINTS
  POST_TWITTER_FARM_ALL_USER_POINTS,
  POST_TWITTER_FARM_ALL_USER_POINTS_ERROR,
  POST_TWITTER_FARM_ALL_USER_POINTS_SUCCESS,

  //POST_TWITTER_ALL_USER_LIKE_POINTS_V2
  POST_TWITTER_ALL_USER_LIKE_POINTS_V2,
  POST_TWITTER_ALL_USER_LIKE_POINTS_V2_ERROR,
  POST_TWITTER_ALL_USER_LIKE_POINTS_V2_SUCCESS,

  //GET_TWITTER_UPDATE_USERS_LEAGUE
  GET_TWITTER_UPDATE_USERS_LEAGUE,
  GET_TWITTER_UPDATE_USERS_LEAGUE_ERROR,
  GET_TWITTER_UPDATE_USERS_LEAGUE_SUCCESS,

  //GET_TWITTER_SETTING
  GET_TWITTER_SETTING,
  GET_TWITTER_SETTING_SUCCESS,
  GET_TWITTER_SETTING_ERROR,
  RESET_ALL_TWITTER_FARM_POINTS_FLAG,
} from "./actionTypes";

const initialState = {
  //TwitterSetting
  TwitterSetting: null,
  TwitterSettingsError: false,
  TwitterSettingsSuccess: false,
  TwitterSettingsLoading: null,
  TwitterSettingsMessage: null,

  //getMyPoints
  getMyPointsSuccessMsg: null,
  getMyPoints: null,
  getMyPointsError: false,
  getMyPointsSuccess: false,
  getMyPointsLoading: null,
  getMyPointsMessage: null,

  //postMyActivities
  postMyActivitiesSuccessMsg: null,
  postMyActivities: null,
  postMyActivitiesError: false,
  postMyActivitiesSuccess: false,
  postMyActivitiesLoading: null,
  postMyActivitiesMessage: null,

  //postMyPoints
  postMyPointsSuccessMsg: null,
  postMyPoints: null,
  postMyPointsError: false,
  postMyPointsSuccess: false,
  postMyPointsLoading: null,
  postMyPointsMessage: null,

  //postMySeed
  postMySeedSuccessMsg: null,
  postMySeed: null,
  postMySeedError: null,
  postMySeedSuccess: null,
  postMySeedLoading: true,
  postMySeedMessage: null,

  //postAllUserPoints
  postAllUserPointsSuccessMsg: null,
  postAllUserPoints: null,
  postAllUserPointsError: false,
  postAllUserPointsSuccess: false,
  postAllUserPointsLoading: null,
  postAllUserPointsMessage: null,

  //postAllUserLikePointsV2
  postAllUserLikePointsV2SuccessMsg: null,
  postAllUserLikePointsV2: null,
  postAllUserLikePointsV2Error: false,
  postAllUserLikePointsV2Success: false,
  postAllUserLikePointsV2Loading: null,
  postAllUserLikePointsV2Message: null,

  //getUpdateUserLeagueData
  getUpdateUserLeagueData: null,
  getUpdateUserLeagueMsg: null,
  getUpdateUserLeagueError: false,
  getUpdateUserLeagueSuccess: false,
  getUpdateUserLeagueLoading: false,
};

const TwitterPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET_TWITTER_SETTING
    case GET_TWITTER_SETTING:
      state = {
        ...state,

        TwitterSettingsError: false,
        TwitterSettingsSuccess: false,
        TwitterSettingsLoading: true,
      };
      break;
    case GET_TWITTER_SETTING_SUCCESS:
      state = {
        ...state,
        TwitterSetting: action.payload,
        TwitterSettingsError: false,
        TwitterSettingsSuccess: true,
        TwitterSettingsLoading: null,
        TwitterSettingsMessage: null,
      };
      break;
    case GET_TWITTER_SETTING_ERROR:
      state = {
        ...state,
        TwitterSetting: null,
        TwitterSettingsError: true,
        TwitterSettingsSuccess: false,
        TwitterSettingsLoading: null,
        TwitterSettingsMessage: action.payload,
      };
      break;

    // GET_TWITTER_FARM_GET_MY_POINT
    case GET_TWITTER_FARM_GET_MY_POINT:
      state = {
        ...state,
        getMyPointsSuccessMsg: null,
        getMyPointsError: null,
        getMyPointsSuccess: null,
        getMyPointsLoading: true,
        getMyPointsMessage: null,
      };
      break;
    case GET_TWITTER_FARM_GET_MY_POINT_SUCCESS:
      state = {
        ...state,
        getMyPointsLoading: false,
        getMyPoints: action.payload,
        getMyPointsSuccess: true,
        getMyPointsError: false,
      };
      break;
    case GET_TWITTER_FARM_GET_MY_POINT_ERROR:
      state = {
        ...state,
        getMyPointsLoading: false,
        getMyPointsError: true,
        getMyPointsMessage: action.payload,
      };
      break;

    // POST_TWITTER_FARM_MY_ACTIVITIES_ACTION
    case POST_TWITTER_FARM_MY_ACTIVITIES_ACTION:
      state = {
        ...state,
        postMyActivitiesSuccessMsg: null,
        postMyActivitiesError: null,
        postMyActivitiesSuccess: null,
        postMyActivitiesLoading: true,
        postMyActivitiesMessage: null,
      };
      break;
    case POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_SUCCESS:
      state = {
        ...state,
        postMyActivitiesLoading: false,
        postMyActivities: action.payload,
        postMyActivitiesSuccess: true,
        postMyActivitiesError: false,
      };
      break;
    case POST_TWITTER_FARM_MY_ACTIVITIES_ACTION_ERROR:
      state = {
        ...state,
        postMyActivitiesLoading: false,
        postMyActivitiesError: true,
        postMyActivitiesMessage: action.payload,
      };
      break;

    // POST_TWITTER_FARM_MY_POINTS
    case POST_TWITTER_FARM_MY_POINTS:
      state = {
        ...state,
        postMyPointsSuccessMsg: null,
        postMyPointsError: null,
        postMyPointsSuccess: null,
        postMyPointsLoading: true,
        postMyPointsMessage: null,
      };
      break;
    case POST_TWITTER_FARM_MY_POINTS_SUCCESS:
      state = {
        ...state,
        postMyPointsLoading: false,
        postMyPoints: action.payload,
        postMyPointsSuccess: true,
        postMyPointsError: false,
      };
      break;
    case POST_TWITTER_FARM_MY_POINTS_ERROR:
      state = {
        ...state,
        postMyPointsLoading: false,
        postMyPointsError: true,
        postMyPointsMessage: action.payload,
      };
      break;

    // POST_TWITTER_FARM_MY_SEED
    case POST_TWITTER_FARM_MY_SEED:
      state = {
        ...state,
        postMySeedSuccessMsg: null,
        postMySeedError: null,
        postMySeedSuccess: null,
        postMySeedLoading: true,
        postMySeedMessage: null,
      };
      break;
    case POST_TWITTER_FARM_MY_SEED_SUCCESS:
      state = {
        ...state,
        postMySeedLoading: false,
        postMySeed: action.payload,
        postMySeedSuccess: true,
        postMySeedError: false,
      };
      break;
    case POST_TWITTER_FARM_MY_SEED_ERROR:
      state = {
        ...state,
        postMySeedLoading: false,
        postMySeedError: true,
        postMySeedMessage: action.payload,
      };
      break;

    // POST_TWITTER_FARM_ALL_USER_POINTS
    case POST_TWITTER_FARM_ALL_USER_POINTS:
      state = {
        ...state,
        postAllUserPointsSuccessMsg: null,
        postAllUserPointsError: null,
        postAllUserPointsSuccess: null,
        postAllUserPointsLoading: true,
        postAllUserPointsMessage: null,
      };
      break;
    case POST_TWITTER_FARM_ALL_USER_POINTS_SUCCESS:
      state = {
        ...state,
        postAllUserPointsLoading: false,
        postAllUserPoints: action.payload,
        postAllUserPointsSuccess: true,
        postAllUserPointsError: false,
      };
      break;
    case POST_TWITTER_FARM_ALL_USER_POINTS_ERROR:
      state = {
        ...state,
        postAllUserPointsLoading: false,
        postAllUserPointsError: true,
        postAllUserPointsMessage: action.payload,
      };
      break;

    // POST_TWITTER_ALL_USER_LIKE_POINTS_V2
    case POST_TWITTER_ALL_USER_LIKE_POINTS_V2:
      state = {
        ...state,
        postAllUserLikePointsV2SuccessMsg: null,
        postAllUserLikePointsV2Error: null,
        postAllUserLikePointsV2Success: null,
        postAllUserLikePointsV2Loading: true,
        postAllUserLikePointsV2Message: null,
      };
      break;
    case POST_TWITTER_ALL_USER_LIKE_POINTS_V2_SUCCESS:
      state = {
        ...state,
        postAllUserLikePointsV2Loading: false,
        postAllUserLikePointsV2: action.payload,
        postAllUserLikePointsV2Success: true,
        postAllUserLikePointsV2Error: false,
      };
      break;
    case POST_TWITTER_ALL_USER_LIKE_POINTS_V2_ERROR:
      state = {
        ...state,
        postAllUserLikePointsV2Loading: false,
        postAllUserLikePointsV2Error: true,
        postAllUserLikePointsV2Message: action.payload,
      };
      break;

    //GET_TWITTER_UPDATE_USERS_LEAGUE
    case GET_TWITTER_UPDATE_USERS_LEAGUE:
      state = {
        ...state,
        getUpdateUserLeagueError: false,
        getUpdateUserLeagueSuccess: false,
        getUpdateUserLeagueLoading: true,
      };
      break;
    case GET_TWITTER_UPDATE_USERS_LEAGUE_SUCCESS:
      state = {
        ...state,
        getUpdateUserLeagueData: action.payload,
        getUpdateUserLeagueMsg: null,
        getUpdateUserLeagueError: false,
        getUpdateUserLeagueSuccess: true,
        getUpdateUserLeagueLoading: false,
      };
      break;
    case GET_TWITTER_UPDATE_USERS_LEAGUE_ERROR:
      state = {
        ...state,
        getUpdateUserLeagueData: null,
        getUpdateUserLeagueMsg: action.payload,
        getUpdateUserLeagueError: true,
        getUpdateUserLeagueSuccess: false,
        getUpdateUserLeagueLoading: false,
      };
      break;

    case RESET_ALL_TWITTER_FARM_POINTS_FLAG:
      state = {
        ...state,
        getMyPointsLoading: false,
        getMyPointsSuccess: false,
        getMyPointsError: false,
        getMyPointsMessage: null,
        getMyPointsSuccessMsg: null,

        postMyActivitiesSuccessMsg: null,
        postMyActivitiesError: false,
        postMyActivitiesSuccess: false,
        postMyActivitiesLoading: null,
        postMyActivitiesMessage: null,

        postMyPointsSuccessMsg: null,
        postMyPointsError: false,
        postMyPointsSuccess: false,
        postMyPointsLoading: null,
        postMyPointsMessage: null,

        postAllUserPointsSuccessMsg: null,
        postAllUserPointsError: false,
        postAllUserPointsSuccess: false,
        postAllUserPointsLoading: null,
        postAllUserPointsMessage: null,

        postAllUserLikePointsV2SuccessMsg: null,
        postAllUserLikePointsV2Error: false,
        postAllUserLikePointsV2Success: false,
        postAllUserLikePointsV2Loading: null,
        postAllUserLikePointsV2Message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default TwitterPointsReducer;
