import {
  MUSEUM_HOME,
  MUSEUM_HOME_SUCCESS,
  MUSEUM_HOME_ERROR,
  RESET_MUSEUM_HOME,
} from "./actionTypes";

const initialState = {
  errorMsgMuseumNfts: "",
  loadingMuseumNfts: false,
  errorMuseumNfts: false,
  successMuseumNfts: null,
  nfts: null,
};

const Museum = (state = initialState, action) => {
  switch (action.type) {
    case MUSEUM_HOME:
      return {
        ...state,
        loadingMuseumNfts: true,
        errorMuseumNfts: false,
        successMuseumNfts: false,
        errorMsgMuseumNfts: null,
      };
    case MUSEUM_HOME_SUCCESS:
      return {
        ...state,
        loadingMuseumNfts: false,
        errorMsgMuseumNfts: false,
        successMuseumNfts: true,
        nfts: action.payload,
      };
    case MUSEUM_HOME_ERROR:
      return {
        ...state,
        errorMsgMuseumNfts: action.payload.data,
        loadingMuseumNfts: false,
        errorMuseumNfts: true,
        successMuseumNfts: false,
      };
    case RESET_MUSEUM_HOME:
      return {
        ...initialState,
      };
      break;
    default:
      return state;
  }
};

export default Museum;
