// reducer.js
import {
  CHECK_AVAILABLE_USERNAME,
  CHECK_AVAILABLE_USERNAME_SUCCESS,
  CHECK_AVAILABLE_USERNAME_FAILURE,
} from "./actionTypes";

const initialState = {
  loadingCheckUserName: false,
  successCheckUserName: false,
  errorCheckUserName: null,
  CheckUserName: false,
};
const CheckUserName = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_AVAILABLE_USERNAME:
      return {
        ...state,
        loadingCheckUserName: true,
        successCheckUserName: false,
        errorCheckUserName: null,
      };
    case CHECK_AVAILABLE_USERNAME_SUCCESS:
      return {
        ...state,
        loadingCheckUserName: false,
        successCheckUserName: true,
        errorCheckUserName: null,
        CheckUserName: action.payload,
      };
    case CHECK_AVAILABLE_USERNAME_FAILURE:
      return {
        ...state,
        loadingCheckUserName: false,
        successCheckUserName: false,
        errorCheckUserName: action.payload,
      };
    default:
      return state;
  }
};

export default CheckUserName;
