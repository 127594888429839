import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  postUploadNft,
  postGenerateAiDescription,
  postGenerateAiDescriptionShorten,
  getAccountUserReferralPromotion,
} from "@/config/helpers/backend_helper";
import {
  uploadNftDataSuccess,
  uploadNftDataError,
  GenerateError,
  GenerateSuccess,
  ShorterenError,
  ShorterenSuccess,
  getUserReferralPromotionSuccess,
  getUserReferralPromotionError,
} from "./action";
import {
  UPLOAD_NFT,
  GENERATE_DESCRIPTION,
  SHORTEREN_DESCRIPTION,
  GET_USER_REFERRAL_PROMOTION,
} from "./actionTypes";

function* fetchUploadFormData(action) {
  try {
    const FormData = action.payload;
    const response = yield call(postUploadNft, FormData);
    yield put(uploadNftDataSuccess(response));
  } catch (error) {
    yield put(uploadNftDataError(error.response.data.Errors[0].Message));
  }
}

function* fetchGenerateDescripton({ payload: { name } }) {
  try {
    const response = yield call(postGenerateAiDescription, name);
    yield put(GenerateSuccess(response));
  } catch (error) {
    yield put(GenerateError(error));
  }
}

function* fetchShorterenDescription({ payload: { story } }) {
  try {
    const response = yield call(postGenerateAiDescriptionShorten, story);
    yield put(ShorterenSuccess(response));
  } catch (error) {
    yield put(ShorterenError(error));
  }
}

function* fetchUserReferralPromotion() {
  try {
    const response = yield call(getAccountUserReferralPromotion);
    yield put(getUserReferralPromotionSuccess(response));
  } catch (error) {
    yield put(getUserReferralPromotionError(error));
  }
}

function* UploadNftSaga() {
  yield takeEvery(UPLOAD_NFT, fetchUploadFormData);
  yield takeEvery(GENERATE_DESCRIPTION, fetchGenerateDescripton);
  yield takeEvery(SHORTEREN_DESCRIPTION, fetchShorterenDescription);
  yield takeLatest(GET_USER_REFERRAL_PROMOTION, fetchUserReferralPromotion);
}

export default UploadNftSaga;
