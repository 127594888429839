// actions.js
import {
  MUSEUM_CURRENTWEEK,
  MUSEUM_CURRENTWEEK_SUCCESS,
  MUSEUM_CURRENTWEEK_ERROR,
} from "./actionTypes";

//current week
export const museumCurrentWeek = () => {
  return {
    type: MUSEUM_CURRENTWEEK,
  };
};

export const museumCurrentWeekSuccess = (data) => {
  return {
    type: MUSEUM_CURRENTWEEK_SUCCESS,
    payload: data,
  };
};

export const museumCurrentWeekError = (data) => {
  return {
    type: MUSEUM_CURRENTWEEK_ERROR,
    payload: data,
  };
};
