// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import { NSFW_NFTS_REQUEST } from "./actionTypes";
import { nsfwNftsSuccess, nsfwNftsFailure } from "./action";
import { getNftNSFWs } from "@/config/helpers/backend_helper";

function* Nsfw(action) {
  try {
    const response = yield call(getNftNSFWs, action.payload);
    yield put(nsfwNftsSuccess(response));
  } catch (error) {
    yield put(nsfwNftsFailure(error));
  }
}

function* NsfwSaga() {
  yield takeLatest(NSFW_NFTS_REQUEST, Nsfw);
}

export default NsfwSaga;
