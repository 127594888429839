import {
  TOP_CURATORS_HOME,
  TOP_CURATORS_HOME_SUCCESS,
  TOP_CURATORS_HOME_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgHeroUsers: "",
  loadingHeroUsers: false,
  errorHeroUsers: false,
  successHeroUsers: null,
  users: null,
};

const TopCurators = (state = initialState, action) => {
  switch (action.type) {
    case TOP_CURATORS_HOME:
      return {
        ...state,
        loadingHeroUsers: true,
        errorHeroUsers: false,
        successHeroUsers: false,
        errorMsgHeroUsers: null,
      };
    case TOP_CURATORS_HOME_SUCCESS:
      return {
        ...state,
        loadingHeroUsers: false,
        errorHeroUsers: false,
        successHeroUsers: true,
        users: action.payload,
        errorMsgHeroUsers: null,
      };
    case TOP_CURATORS_HOME_ERROR:
      return {
        ...state,
        errorMsgHeroUsers: action.payload.data,
        loadingHeroUsers: false,
        errorHeroUsers: true,
        successHeroUsers: false,
      };
    default:
      return state;
  }
};

export default TopCurators;
