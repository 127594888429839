// reducer.js
import {
  GET_ACCOUNT_FOLLOWERS,
  GET_ACCOUNT_FOLLOWERS_SUCCESS,
  GET_ACCOUNT_FOLLOWERS_FAILURE,
} from "./actionTypes";

const initialState = {
  loadingFollower: false,
  successFollower: false,
  errorFollower: null,
  Follower: [],
};
const Follower = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_FOLLOWERS:
      return {
        ...state,
        loadingFollower: true,
        successFollower: false,
        errorFollower: null,
      };
    case GET_ACCOUNT_FOLLOWERS_SUCCESS:
      return {
        ...state,
        loadingFollower: false,
        successFollower: true,
        errorFollower: null,
        Follower: action.payload,
      };
    case GET_ACCOUNT_FOLLOWERS_FAILURE:
      return {
        ...state,
        loadingFollower: false,
        successFollower: false,
        errorFollower: action.payload,
      };
    default:
      return state;
  }
};

export default Follower;
