import { call, put, takeEvery } from "redux-saga/effects";
import { getMuseumCurrentWeek } from "@/config/helpers/backend_helper";
import { museumCurrentWeekError, museumCurrentWeekSuccess } from "./actions";
import { MUSEUM_CURRENTWEEK } from "./actionTypes";

function* fetchMuseumCurrentWeek() {
  try {
    //  console.log(action.payload);
    const response = yield call(getMuseumCurrentWeek);
    yield put(museumCurrentWeekSuccess(response));
  } catch (error) {
    yield put(museumCurrentWeekError(error));
  }
}

function* museumCuerrntWeekSaga() {
  yield takeEvery(MUSEUM_CURRENTWEEK, fetchMuseumCurrentWeek);
}

export default museumCuerrntWeekSaga;
