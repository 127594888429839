import {
  ACCOUNT_REPORT_USER,
  ACCOUNT_REPORT_USER_SUCCESS,
  ACCOUNT_REPORT_USER_ERROR,
  RESET_ACCOUNT_REPORT_USER,
} from "./actionTypes";

const initialState = {
  errorMsgReportUser: "",
  loadingReportUser: false,
  errorReportUser: false,
  successReportUser: null,
  ReportUser: [],
};

const ReportUser = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_REPORT_USER:
      state = {
        ...state,
        loadingReportUser: true,
        errorReportUser: false,
        successReportUser: false,
        errorMsgReportUser: null,
      };
      break;
    case ACCOUNT_REPORT_USER_SUCCESS:
      state = {
        ...state,
        loadingReportUser: false,
        errorMsgReportUser: false,
        successReportUser: true,
        ReportUser: action.payload,
      };
      break;
    case ACCOUNT_REPORT_USER_ERROR:
      state = {
        ...state,
        errorMsgReportUser: action.payload.data,
        loadingReportUser: false,
        errorReportUser: true,
        successReportUser: false,
      };
      break;
    case RESET_ACCOUNT_REPORT_USER:
      state = {
        ...state,
        errorMsgReportUser: "",
        loadingReportUser: false,
        errorReportUser: false,
        successReportUser: null,
        ReportUser: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ReportUser;
