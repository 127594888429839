// saga.js
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getHomeTopCollectors } from "@/config/helpers/backend_helper";
import { topCollectorsDataSuccess, topCollectorsDataError } from "./actions";
import { TOP_COLLECTORS_HOME } from "./actionTypes";

function* fetchTopCollectorsData() {
  try {
    const response = yield call(getHomeTopCollectors);

    yield put(topCollectorsDataSuccess(response));
  } catch (error) {
    yield put(topCollectorsDataError(error));
  }
}

function* topCollectorsSaga() {
  yield takeLatest(TOP_COLLECTORS_HOME, fetchTopCollectorsData);
}

export default topCollectorsSaga;
