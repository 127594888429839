// saga.js
import { getlandingPageWalletData } from "@/config/helpers/backend_helper";
import { call, put, takeLatest } from "redux-saga/effects";
import { GET_LANDING_PAGE_WALLET_DATA_ACTION } from "./actionTypes";
import {
  getLandingPageWalletDataActionFailure,
  getLandingPageWalletDataActionSuccess,
} from "./action";

function* fetchtlandingPageWalletData(action) {
  try {
    const response = yield call(getlandingPageWalletData, action.payload);
    yield put(getLandingPageWalletDataActionSuccess(response));
  } catch (error) {
    yield put(getLandingPageWalletDataActionFailure(error));
  }
}

function* LandingPageSaga() {
  yield takeLatest(
    GET_LANDING_PAGE_WALLET_DATA_ACTION,
    fetchtlandingPageWalletData
  );
}

export default LandingPageSaga;
