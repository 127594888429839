// actions.js
import {
  ORDER_CONFIRMATION,
  ORDER_CONFIRMATION_SUCCESS,
  ORDER_CONFIRMATION_ERROR,
} from "./actionTypes";

export const orderconfirmationData = (slagId) => {
  return {
    type: ORDER_CONFIRMATION,
    payload: { slagId },
  };
};

export const orderconfirmationDataSuccess = (data) => {
  return {
    type: ORDER_CONFIRMATION_SUCCESS,
    payload: data,
  };
};

export const orderconfirmationDataError = (error) => {
  return {
    type: ORDER_CONFIRMATION_ERROR,
    payload: error,
  };
};
