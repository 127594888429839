// saga.js
import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { GET_SETTINGS } from "./actionTypes";
import { getSettingsSuccess, getSettingsError } from "./action";
import { getSettings } from "@/config/helpers/backend_helper";
import { setItem } from "@/config/helpers/api_helper";

function* fetchSetingsData() {
  try {
    const response = yield call(getSettings);
    setItem("settingsData", response);
    // console.log("enums" , response);
    yield put(getSettingsSuccess(response));
  } catch (error) {
    yield put(getSettingsError(error));
  }
}

export function* watchSettings() {
  yield takeEvery(GET_SETTINGS, fetchSetingsData);
}

function* SettingsSaga() {
  yield all([fork(watchSettings)]);
}

export default SettingsSaga;
