export const FAVORITES_NFTCARD_REQUEST = "FAVORITES_NFTCARD_REQUEST";
export const FAVORITES_NFTCARD_SUCCESS = "FAVORITES_NFTCARD_SUCCESS";
export const FAVORITES_NFTCARD_FAILURE = "FAVORITES_NFTCARD_FAILURE";
export const RESET_FAVORITES_NFTCARD_FAILURE =
  "RESET_FAVORITES_NFTCARD_FAILURE";

export const FAVORITES_COLLECTION_REQUEST = "FAVORITES_COLLECTION_REQUEST";
export const FAVORITES_COLLECTION_SUCCESS = "FAVORITES_COLLECTION_SUCCESS";
export const FAVORITES_COLLECTION_FAILURE = "FAVORITES_COLLECTION_FAILURE";
export const RESET_FAVORITES_COLLECTION = "RESET_FAVORITES_COLLECTION";
