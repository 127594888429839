import {
  //GET_MY_WITHDRAWALS
  GET_MY_WITHDRAWALS,
  GET_MY_WITHDRAWALS_ERROR,
  GET_MY_WITHDRAWALS_SUCCESS,

  //REQUEST_WITHDRAWALS
  REQUEST_WITHDRAWALS,
  REQUEST_WITHDRAWALS_ERROR,
  REQUEST_WITHDRAWALS_SUCCESS,

  //CANCEL_WITHDRAWALS
  CANCEL_WITHDRAWALS,
  CANCEL_WITHDRAWALS_ERROR,
  CANCEL_WITHDRAWALS_SUCCESS,
  RESET_WITHDRAWALS_FLAG,
} from "./actionTypes";

//postGetMyWithdrawals
export const getMyWithdrawals = (data) => {
  return {
    type: GET_MY_WITHDRAWALS,
    payload: data,
  };
};
export const getMyWithdrawalsSuccess = (data) => {
  return {
    type: GET_MY_WITHDRAWALS_SUCCESS,
    payload: data,
  };
};
export const getMyWithdrawalsError = (data) => {
  return {
    type: GET_MY_WITHDRAWALS_ERROR,
    payload: data,
  };
};

//requestWithdrawalss
export const requestWithdrawals = (data) => {
  return {
    type: REQUEST_WITHDRAWALS,
    payload: data,
  };
};

export const requestWithdrawalsSuccess = (data) => {
  return {
    type: REQUEST_WITHDRAWALS_SUCCESS,
    payload: data,
  };
};

export const requestWithdrawalsError = (error) => {
  return {
    type: REQUEST_WITHDRAWALS_ERROR,
    payload: error,
  };
};

//cancelWithdrawals
export const cancelWithdrawals = (data) => {
  return {
    type: CANCEL_WITHDRAWALS,
    payload: { data },
  };
};

export const cancelWithdrawalsSuccess = (data) => {
  return {
    type: CANCEL_WITHDRAWALS_SUCCESS,
    payload: data,
  };
};

export const cancelWithdrawalsError = (error) => {
  return {
    type: CANCEL_WITHDRAWALS_ERROR,
    payload: error,
  };
};

//resetWithdrawalsFlag
export const resetWithdrawalsFlag = () => {
  return {
    type: RESET_WITHDRAWALS_FLAG,
  };
};
