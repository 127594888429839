import {
  HOME_MARKET_PLACE_INFO,
  HOME_MARKET_PLACE_INFO_SUCCESS,
  HOME_MARKET_PLACE_INFO_ERROR,
  RESET_HOME_MARKET_PLACE_INFO,
} from "./actionTypes";

const initialState = {
  errorMsgMarketPlaceInfo: "",
  loadingMarketPlaceInfo: false,
  errorMarketPlaceInfo: false,
  successMarketPlaceInfo: null,
  MarketPlaceInfo: null,
};

const HomeMarketPlaceInfo = (state = initialState, action) => {
  switch (action.type) {
    //HOME_MARKET_PLACE_INFO
    case HOME_MARKET_PLACE_INFO:
      state = {
        ...state,
        loadingMarketPlaceInfo: true,
        errorMarketPlaceInfo: false,
        successMarketPlaceInfo: false,
        errorMsgMarketPlaceInfo: null,
      };
      break;
    case HOME_MARKET_PLACE_INFO_SUCCESS:
      state = {
        ...state,
        loadingMarketPlaceInfo: false,
        errorMsgMarketPlaceInfo: false,
        successMarketPlaceInfo: true,
        MarketPlaceInfo: action.payload,
      };
      break;
    case HOME_MARKET_PLACE_INFO_ERROR:
      state = {
        ...state,
        errorMsgMarketPlaceInfo: action.payload.data,
        loadingMarketPlaceInfo: false,
        errorMarketPlaceInfo: true,
        successMarketPlaceInfo: false,
      };
      break;
    case RESET_HOME_MARKET_PLACE_INFO:
      state = {
        ...state,
        errorMsgMarketPlaceInfo: "",
        loadingMarketPlaceInfo: false,
        errorMarketPlaceInfo: false,
        successMarketPlaceInfo: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default HomeMarketPlaceInfo;
