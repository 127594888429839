import {
  MANAGE_MY_BIDS_NFTCARD_REQUEST,
  MANAGE_MY_BIDS_NFTCARD_SUCCESS,
  MANAGE_MY_BIDS_NFTCARD_FAILURE,
  RESET_MANAGE_MY_BIDS_NFTCARD_FAILURE
} from "./actionTypes";

const initialState = {
  loadingManageMyBidsNftCard: false,
  successManageMyBidsNftCard: false,
  errorManageMyBidsNftCard: null,
  nftsManageMyBids: [],
};
const ManageMyBidsNftCard = (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_MY_BIDS_NFTCARD_REQUEST:
      return {
        ...state,
        loadingManageMyBidsNftCard: true,
        successManageMyBidsNftCard: false,
        errorManageMyBidsNftCard: null,
      };
    case MANAGE_MY_BIDS_NFTCARD_SUCCESS:
      return {
        ...state,
        loadingManageMyBidsNftCard: false,
        successManageMyBidsNftCard: true,
        errorManageMyBidsNftCard: null,
        nftsManageMyBids: action.payload,
      };
    case MANAGE_MY_BIDS_NFTCARD_FAILURE:
      return {
        ...state,
        loadingManageMyBidsNftCard: false,
        successManageMyBidsNftCard: false,
        errorManageMyBidsNftCard: action.payload,
      };
    case RESET_MANAGE_MY_BIDS_NFTCARD_FAILURE:
      return {
        ...state,
        successManageMyBidsNftCard: false,
        nftsManageMyBids: [],
      };
    default:
      return state;
  }
};

export default ManageMyBidsNftCard;
