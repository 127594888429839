import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { MUSEUM_NFTCARD_REQUEST } from "./actionTypes";
import { MuseumNftCardFailure, MuseumNftCardSuccess } from "./action";
import { getMuseumCardsProfile } from "@/config/helpers/backend_helper";

function* MuseumNftCard(action) {
  try {
    const response = yield call(getMuseumCardsProfile, action.payload);
    yield put(MuseumNftCardSuccess(response));
  } catch (error) {
    yield put(MuseumNftCardFailure(error));
  }
}

function* MuseumNftCardSaga() {
  yield takeEvery(MUSEUM_NFTCARD_REQUEST, MuseumNftCard);
}

export default MuseumNftCardSaga;
