// reducer.js
import {
  NSFW_NFTS_REQUEST,
  NSFW_NFTS_SUCCESS,
  NSFW_NFTS_FAILURE,
} from "./actionTypes";

const initialState = {
  loadingNsfwNfts: false,
  successNsfwNfts: false,
  errorNsfwNfts: null,
  nsfwNfts: null,
};
const Nsfw = (state = initialState, action) => {
  switch (action.type) {
    case NSFW_NFTS_REQUEST:
      state = {
        ...state,
        loadingNsfwNfts: true,
        successNsfwNfts: false,
        errorNsfwNfts: null,
      };
      break;
    case NSFW_NFTS_SUCCESS:
      state = {
        ...state,
        loadingNsfwNfts: false,
        successNsfwNfts: true,
        errorNsfwNfts: null,
        nsfwNfts: action.payload,
      };
      break;
    case NSFW_NFTS_FAILURE:
      state = {
        ...state,
        loadingNsfwNfts: false,
        successNsfwNfts: false,
        errorNsfwNfts: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Nsfw;
