// saga.js
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getHomeTopPhotographers } from "@/config/helpers/backend_helper";
import {
  topPhotographersDataSuccess,
  topPhotographersDataError,
  topPhotographersReferralsDataSuccess,
  topPhotographersReferralsDataError,
  topPhotographersSellDataSuccess,
  topPhotographersSellDataError,
  topPhotographersMintedDataSuccess,
  topPhotographersMintedDataError,
  topPhotographersListedDataSuccess,
  topPhotographersListedDataError,
} from "./actions";
import {
  TOP_PHOTOGRAPHERS_HOME,
  TOP_PHOTOGRAPHERS_PAGE_SELL,
  TOP_PHOTOGRAPHERS_PAGE_REFERRALS,
  TOP_PHOTOGRAPHERS_PAGE_MINTED,
  TOP_PHOTOGRAPHERS_PAGE_LISTED,
} from "./actionTypes";

function* fetchTopPhotographersData({ payload: { type } }) {
  try {
    const response = yield call(getHomeTopPhotographers, type);

    yield put(topPhotographersDataSuccess(response));
  } catch (error) {
    yield put(topPhotographersDataError(error));
  }
}

function* fetchTopPhotographersDataReferrals({ payload: { type } }) {
  try {
    const response = yield call(getHomeTopPhotographers, type);

    yield put(topPhotographersReferralsDataSuccess(response));
  } catch (error) {
    yield put(topPhotographersReferralsDataError(error));
  }
}

function* fetchTopPhotographersDataSell({ payload: { type } }) {
  try {
    const response = yield call(getHomeTopPhotographers, type);

    yield put(topPhotographersSellDataSuccess(response));
  } catch (error) {
    yield put(topPhotographersSellDataError(error));
  }
}

function* fetchTopPhotographersDataMinted({ payload: { type } }) {
  try {
    const response = yield call(getHomeTopPhotographers, type);

    yield put(topPhotographersMintedDataSuccess(response));
  } catch (error) {
    yield put(topPhotographersMintedDataError(error));
  }
}
function* fetchTopPhotographersDataListed({ payload: { type } }) {
  try {
    const response = yield call(getHomeTopPhotographers, type);

    yield put(topPhotographersListedDataSuccess(response));
  } catch (error) {
    yield put(topPhotographersListedDataError(error));
  }
}
function* topPhotographersSaga() {
  yield takeLatest(TOP_PHOTOGRAPHERS_HOME, fetchTopPhotographersData);
  yield takeLatest(
    TOP_PHOTOGRAPHERS_PAGE_REFERRALS,
    fetchTopPhotographersDataReferrals
  );
  yield takeLatest(TOP_PHOTOGRAPHERS_PAGE_SELL, fetchTopPhotographersDataSell);
  yield takeLatest(
    TOP_PHOTOGRAPHERS_PAGE_MINTED,
    fetchTopPhotographersDataMinted
  );
  yield takeLatest(
    TOP_PHOTOGRAPHERS_PAGE_LISTED,
    fetchTopPhotographersDataListed
  );
}

export default topPhotographersSaga;
