// actions.js
import {
  NFT_DETAILS,
  NFT_DETAILS_SUCCESS,
  NFT_DETAILS_ERROR,
  NFT_DETAILS_STEP2,
  NFT_DETAILS_STEP2_SUCCESS,
  NFT_DETAILS_STEP2_ERROR,
  NFT_TOGGLE_ARCHIVE,
  NFT_TOGGLE_ARCHIVE_SUCCESS,
  NFT_TOGGLE_ARCHIVE_ERROR,
  NFT_CANCEL_PROMOTION,
  NFT_CANCEL_PROMOTION_SUCCESS,
  NFT_CANCEL_PROMOTION_ERROR,
  NFT_CHECK_DETAILS,
  NFT_CHECK_DETAILS_SUCCESS,
  NFT_CHECK_DETAILS_ERROR,
  RESET_NFT_DETAIL_DATA,
  RESET_NFT_DETAIL_STEP2_DATA,
  RESET_NFT_DETAIL_ALL_DATA,
  RESET_TOGGLE_ARCHIVE_DATA,
  RESET_ONLY_NFT_DETAIL_DATA,
  RESET_CANCEL_PROMOTION_DATA,
} from "./actionTypes";

//NftDetailData
export const NftDetailData = (Id) => {
  return {
    type: NFT_DETAILS,
    payload: { Id },
  };
};

export const NftDetailDataSuccess = (data) => {
  return {
    type: NFT_DETAILS_SUCCESS,
    payload: data,
  };
};

export const NftDetailDataError = (error) => {
  return {
    type: NFT_DETAILS_ERROR,
    payload: error,
  };
};

//NftDetailDataStep2
export const NftDetailDataStep2 = (Id) => {
  return {
    type: NFT_DETAILS_STEP2,
    payload: { Id },
  };
};

export const NftDetailDataStep2Success = (data) => {
  return {
    type: NFT_DETAILS_STEP2_SUCCESS,
    payload: data,
  };
};

export const NftDetailDataStep2Error = (error) => {
  return {
    type: NFT_DETAILS_STEP2_ERROR,
    payload: error,
  };
};

//GET_NFT_CHECK_DETAILS
export const nftCheckDetails = (Id) => {
  return {
    type: NFT_CHECK_DETAILS,
    payload: { Id },
  };
};

export const nftCheckDetailsSuccess = (data) => {
  return {
    type: NFT_CHECK_DETAILS_SUCCESS,
    payload: data,
  };
};

export const nftCheckDetailsError = (error) => {
  return {
    type: NFT_CHECK_DETAILS_ERROR,
    payload: error,
  };
};

//archive
export const nftToggleArchive = (Id) => {
  return {
    type: NFT_TOGGLE_ARCHIVE,
    payload: { Id },
  };
};

export const nftToggleArchiveSuccess = (data) => {
  return {
    type: NFT_TOGGLE_ARCHIVE_SUCCESS,
    payload: data,
  };
};

export const nftToggleArchiveError = (error) => {
  return {
    type: NFT_TOGGLE_ARCHIVE_ERROR,
    payload: error,
  };
};

export const nftCancelPromotion = (Id) => {
  return {
    type: NFT_CANCEL_PROMOTION,
    payload: { Id },
  };
};

export const nftCancelPromotionSuccess = (data) => {
  return {
    type: NFT_CANCEL_PROMOTION_SUCCESS,
    payload: data,
  };
};

export const nftCancelPromotionError = (error) => {
  return {
    type: NFT_CANCEL_PROMOTION_ERROR,
    payload: error,
  };
};

export const resetNftData = () => {
  return {
    type: RESET_NFT_DETAIL_DATA,
  };
};

export const resetNftDataStep2 = () => {
  return {
    type: RESET_NFT_DETAIL_STEP2_DATA,
  };
};

export const resetOnlyNftData = () => {
  return {
    type: RESET_ONLY_NFT_DETAIL_DATA,
  };
};

export const resetAllNftDetailData = () => {
  return {
    type: RESET_NFT_DETAIL_ALL_DATA,
  };
};

export const resetToggleArchiveData = () => {
  return {
    type: RESET_TOGGLE_ARCHIVE_DATA,
  };
};

export const resetCancelPromotionData = () => {
  return {
    type: RESET_CANCEL_PROMOTION_DATA,
  };
};
