import { call, put, takeLatest } from "redux-saga/effects";

import {
  getHomeExclusiveCollections,
  postHomeExclusiveCollections,
} from "@/config/helpers/backend_helper";

import {
  ExclusiveDataError,
  ExclusiveDataSuccess,
  postExclusiveDataError,
  postExclusiveDataSuccess,
} from "./actions";

import {
  EXCLUSIVE_COLLECTIONS_HOME,
  POST_EXCLUSIVE_COLLECTIONS_HOME,
} from "./actionTypes";

function* fetchExclusiveCollectionsData() {
  try {
    const response = yield call(getHomeExclusiveCollections);
    yield put(ExclusiveDataSuccess(response));
  } catch (error) {
    yield put(ExclusiveDataError(error));
  }
}

function* fetchPostExclusiveCollectionsData({ payload: { data } }) {
  try {
    const response = yield call(postHomeExclusiveCollections, data);
    yield put(postExclusiveDataSuccess(response));
  } catch (error) {
    yield put(postExclusiveDataError(error));
  }
}

function* ExclusiveCollectionsSaga() {
  yield takeLatest(EXCLUSIVE_COLLECTIONS_HOME, fetchExclusiveCollectionsData);
  yield takeLatest(
    POST_EXCLUSIVE_COLLECTIONS_HOME,
    fetchPostExclusiveCollectionsData
  );
}

export default ExclusiveCollectionsSaga;
