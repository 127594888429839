// reducer.js
import {
  SEND_LAST_NOTIFICATION_REQUEST,
  SEND_LAST_NOTIFICATION_SUCCESS,
  SEND_LAST_NOTIFICATION_FAILURE,
  RESET_SEND_LAST_NOTIFICATION,
} from "./actionTypes";

const initialState = {
  loadingfilterNotification: false,
  successfilterNotification: false,
  errorfilterNotification: null,
  notifs: [],
};
const LastNotificationFilter = (state = initialState, action) => {
  switch (action.type) {
    case SEND_LAST_NOTIFICATION_REQUEST:
      return {
        ...state,
        loadingfilterNotification: true,
        successfilterNotification: false,
        errorfilterNotification: null,
      };
    case SEND_LAST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loadingfilterNotification: false,
        successfilterNotification: true,
        errorfilterNotification: null,
        notifs: action.payload,
      };
    case SEND_LAST_NOTIFICATION_FAILURE:
      return {
        ...state,
        loadingfilterNotification: false,
        successfilterNotification: false,
        errorfilterNotification: action.payload,
      };
    case RESET_SEND_LAST_NOTIFICATION:
      return {
        ...state,
        loadingfilterNotification: false,
        successfilterNotification: false,
        errorfilterNotification: null,
        notifs: [],
      };
    default:
      return state;
  }
};

export default LastNotificationFilter;
