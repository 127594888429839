import {
  EXCLUSIVE_COLLECTIONS_HOME,
  EXCLUSIVE_COLLECTIONS_HOME_ERROR,
  EXCLUSIVE_COLLECTIONS_HOME_SUCCESS,
  POST_EXCLUSIVE_COLLECTIONS_HOME,
  POST_EXCLUSIVE_COLLECTIONS_HOME_ERROR,
  POST_EXCLUSIVE_COLLECTIONS_HOME_SUCCESS,
  RESET_EXCLUSIVE_COLLECTIONS_HOME,
  RESET_POST_EXCLUSIVE_COLLECTIONS_HOME,
} from "./actionTypes";

const initialState = {
  errorMsgExclusiveNfts: "",
  loadingExclusiveNfts: false,
  errorExclusiveNfts: false,
  successExclusiveNfts: null,
  nfts: null,

  errorMsgPostExclusiveNfts: "",
  loadingPostExclusiveNfts: false,
  errorPostExclusiveNfts: false,
  successPostExclusiveNfts: null,
  PostExclusiveNfts: null,
};

const ExclusiveArtworks = (state = initialState, action) => {
  switch (action.type) {
    //EXCLUSIVE_COLLECTIONS_HOME
    case EXCLUSIVE_COLLECTIONS_HOME:
      state = {
        ...state,
        loadingExclusiveNfts: true,
        errorExclusiveNfts: false,
        successExclusiveNfts: false,
        errorMsgExclusiveNfts: null,
      };
      break;
    case EXCLUSIVE_COLLECTIONS_HOME_SUCCESS:
      state = {
        ...state,
        loadingExclusiveNfts: false,
        errorMsgExclusiveNfts: false,
        successExclusiveNfts: true,
        nfts: action.payload,
      };
      break;
    case EXCLUSIVE_COLLECTIONS_HOME_ERROR:
      state = {
        ...state,
        errorMsgExclusiveNfts: action.payload.data,
        loadingExclusiveNfts: false,
        errorExclusiveNfts: true,
        successExclusiveNfts: false,
      };
      break;
    case RESET_EXCLUSIVE_COLLECTIONS_HOME:
      state = {
        ...state,
        errorMsgExclusiveNfts: null,
        loadingExclusiveNfts: false,
        errorExclusiveNfts: false,
        successExclusiveNfts: null,
        nfts: null,
      };
      break;

    //EXCLUSIVE_COLLECTIONS_HOME_POST
    case POST_EXCLUSIVE_COLLECTIONS_HOME:
      state = {
        ...state,
        loadingPostExclusiveNfts: true,
        errorPostExclusiveNfts: false,
        successPostExclusiveNfts: false,
        errorMsgPostExclusiveNfts: null,
        PostExclusiveNfts: null,
      };
      break;
    case POST_EXCLUSIVE_COLLECTIONS_HOME_SUCCESS:
      state = {
        ...state,
        loadingPostExclusiveNfts: false,
        errorMsgPostExclusiveNfts: false,
        errorPostExclusiveNfts: false,
        successPostExclusiveNfts: true,
        PostExclusiveNfts: action.payload,
      };
      break;
    case POST_EXCLUSIVE_COLLECTIONS_HOME_ERROR:
      state = {
        ...state,
        errorMsgPostExclusiveNfts: action.payload.data,
        loadingPostExclusiveNfts: false,
        errorPostExclusiveNfts: true,
        successPostExclusiveNfts: false,
        PostExclusiveNfts: null,
      };
      break;
    case RESET_POST_EXCLUSIVE_COLLECTIONS_HOME:
      state = {
        ...state,
        errorMsgPostExclusiveNfts: null,
        loadingPostExclusiveNfts: false,
        errorPostExclusiveNfts: false,
        successPostExclusiveNfts: null,
        PostExclusiveNfts: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ExclusiveArtworks;
