import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import {
  cancelWithdrawalsError,
  cancelWithdrawalsSuccess,
  getMyWithdrawalsError,
  getMyWithdrawalsSuccess,
  requestWithdrawalsError,
  requestWithdrawalsSuccess,
} from "./actions";

import {
  postCancelWithdrawals,
  postGetMyWithdrawals,
  postRequestWithdrawals,
} from "@/config/helpers/backend_helper";

import {
  CANCEL_WITHDRAWALS,
  GET_MY_WITHDRAWALS,
  REQUEST_WITHDRAWALS,
} from "./actionTypes";

function* fetchGetMyWithdrawals(action) {
  try {
    const response = yield call(postGetMyWithdrawals, action.payload);
    yield put(getMyWithdrawalsSuccess(response));
  } catch (error) {
    yield put(getMyWithdrawalsError(error));
  }
}

function* fetchRequestWithdrawals(action) {
  try {
    const response = yield call(postRequestWithdrawals, action.payload);
    yield put(requestWithdrawalsSuccess(response));
  } catch (error) {
    yield put(requestWithdrawalsError(error));
  }
}

function* fetchCancelWithdrawals({ payload: { data } }) {
  try {
    console.log("238-saga-data", data);
    const response = yield call(postCancelWithdrawals, data);
    yield put(cancelWithdrawalsSuccess(response));
  } catch (error) {
    yield put(cancelWithdrawalsError(error));
  }
}

export function* watchTwitterWithdrawals() {
  yield takeLatest(GET_MY_WITHDRAWALS, fetchGetMyWithdrawals);
  yield takeLatest(REQUEST_WITHDRAWALS, fetchRequestWithdrawals);
  yield takeLatest(CANCEL_WITHDRAWALS, fetchCancelWithdrawals);
}

function* TwitterWithdrawalsSaga() {
  yield all([fork(watchTwitterWithdrawals)]);
}

export default TwitterWithdrawalsSaga;
