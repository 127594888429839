import {
  COLLECTED_NFTCARD_REQUEST,
  COLLECTED_NFTCARD_SUCCESS,
  COLLECTED_NFTCARD_FAILURE,
  RESET_COLLECTED_NFTCARD_FAILURE
} from "./actionTypes";

export const CollectedNftCardRequest = (requestData) => ({
  type: COLLECTED_NFTCARD_REQUEST,
  payload: requestData,
});

export const CollectedNftCardSuccess = (data) => ({
  type: COLLECTED_NFTCARD_SUCCESS,
  payload: data,
});

export const CollectedNftCardFailure = (error) => ({
  type: COLLECTED_NFTCARD_FAILURE,
  payload: error,
});

export const ResetCollectedNftCardFailure = () => ({
  type: RESET_COLLECTED_NFTCARD_FAILURE,
});
