//EDIT_PROFILE
export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR";
export const RESET_EDIT_PROFILE = "RESET_EDIT_PROFILE";

//GET_PROFILE
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

//CHECK_FOLLOW_USER
export const CHECK_FOLLOW_USER = "CHECK_FOLLOW_USER";
export const CHECK_FOLLOW_USER_SUCCESS = "CHECK_FOLLOW_USER_SUCCESS";
export const CHECK_FOLLOW_USER_ERROR = "CHECK_FOLLOW_USER_ERROR";

//RESET_FLAGS
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";
export const RESET_ALL_PROFILE_DATA_FLAG = "RESET_ALL_PROFILE_DATA_FLAG";
export const RESET_CHECK_FOLLOW_DATA_FLAG = "RESET_CHECK_FOLLOW_DATA_FLAG";
