import {
  CREATED_NFTCARD_REQUEST,
  CREATED_NFTCARD_SUCCESS,
  CREATED_NFTCARD_FAILURE,
  RESET_CREATED_NFTCARD_FAILURE
} from "./actionTypes";

export const CreatedNftCardRequest = (requestData) => ({
  type: CREATED_NFTCARD_REQUEST,
  payload: requestData,
});

export const CreatedNftCardSuccess = (data) => ({
  type: CREATED_NFTCARD_SUCCESS,
  payload: data,
});

export const CreatedNftCardFailure = (error) => ({
  type: CREATED_NFTCARD_FAILURE,
  payload: error,
});

export const ResetCreatedNftCardFailure = () => ({
  type: RESET_CREATED_NFTCARD_FAILURE,
});