import { takeLatest, call, put } from "redux-saga/effects";
import {
  MANAGE_MY_BIDS_NFTCARD_REQUEST,
  MANAGE_MY_BIDS_NFTCARD_SUCCESS,
  MANAGE_MY_BIDS_NFTCARD_FAILURE,
} from "./actionTypes";
import {
  ManageMyBidsNftCardSuccess,
  ManageMyBidsNftCardFailure,
} from "./action";
import { getNftCards } from "@/config/helpers/backend_helper";

function* ManageMyBidsNftCard(action) {
  try {
    //   console.log(action.payload);
    const response = yield call(getNftCards, action.payload);
    //  console.log(response);
    yield put(ManageMyBidsNftCardSuccess(response));
  } catch (error) {
    yield put(ManageMyBidsNftCardFailure(error));
  }
}

function* ManageMyBidsNftCardSaga() {
  yield takeLatest(MANAGE_MY_BIDS_NFTCARD_REQUEST, ManageMyBidsNftCard);
}

export default ManageMyBidsNftCardSaga;
