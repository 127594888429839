// actionTypes.js
export const NFT_DETAILS = "NFT_DETAILS";
export const NFT_DETAILS_SUCCESS = "NFT_DETAILS_SUCCESS";
export const NFT_DETAILS_ERROR = "NFT_DETAILS_ERROR";

export const NFT_DETAILS_STEP2 = "NFT_DETAILS_STEP2";
export const NFT_DETAILS_STEP2_SUCCESS = "NFT_DETAILS_STEP2_SUCCESS";
export const NFT_DETAILS_STEP2_ERROR = "NFT_DETAILS_STEP2_ERROR";

export const NFT_TOGGLE_ARCHIVE = "NFT_TOGGLE_ARCHIVE";
export const NFT_TOGGLE_ARCHIVE_SUCCESS = "NFT_TOGGLE_ARCHIVE_SUCCESS";
export const NFT_TOGGLE_ARCHIVE_ERROR = "NFT_TOGGLE_ARCHIVE_ERROR";

export const NFT_CANCEL_PROMOTION = "NFT_CANCEL_PROMOTION";
export const NFT_CANCEL_PROMOTION_SUCCESS = "NFT_CANCEL_PROMOTION_SUCCESS";
export const NFT_CANCEL_PROMOTION_ERROR = "NFT_CANCEL_PROMOTION_ERROR";

export const NFT_CHECK_DETAILS = "NFT_CHECK_DETAILS";
export const NFT_CHECK_DETAILS_SUCCESS = "NFT_CHECK_DETAILS_SUCCESS";
export const NFT_CHECK_DETAILS_ERROR = "NFT_CHECK_DETAILS_ERROR";

export const RESET_NFT_DETAIL_DATA = "RESET_NFT_DETAIL_DATA";
export const RESET_NFT_DETAIL_STEP2_DATA = "RESET_NFT_DETAIL_STEP2_DATA";
export const RESET_ONLY_NFT_DETAIL_DATA = "RESET_ONLY_NFT_DETAIL_DATA";
export const RESET_NFT_DETAIL_ALL_DATA = "RESET_NFT_DETAIL_ALL_DATA";
export const RESET_TOGGLE_ARCHIVE_DATA = "RESET_TOGGLE_ARCHIVE_DATA";
export const RESET_CANCEL_PROMOTION_DATA = "RESET_CANCEL_PROMOTION_DATA";
