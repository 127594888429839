import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { getHomeNewListing } from "@/config/helpers/backend_helper";

import { NewlistingDataSuccess, NewlistingDataError } from "./actions";

import { NEW_LISTING_HOME } from "./actionTypes";

function* fetchNewListingData() {
  try {
    const response = yield call(getHomeNewListing);
    yield put(NewlistingDataSuccess(response));
  } catch (error) {
    yield put(NewlistingDataError(error));
  }
}

function* newListingSaga() {
  yield takeLatest(NEW_LISTING_HOME, fetchNewListingData);
}

export default newListingSaga;
