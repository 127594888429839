import {
  ACCOUNT_COLLECTION_NFTS_REQUEST,
  ACCOUNT_COLLECTION_NFTS_SUCCESS,
  ACCOUNT_COLLECTION_NFTS_FAILURE,
  RESET_ACCOUNT_COLLECTION_NFTS_FAILURE
} from "./actionTypes";

const initialState = {
  loadingAccountCollectionNfts: false,
  successAccountCollectionNfts: false,
  errorAccountCollectionNfts: null,
  nftsAccountCollectionNfts: [],
};
const AccountCollectionNfts = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_COLLECTION_NFTS_REQUEST:
      return {
        ...state,
        loadingAccountCollectionNfts: true,
        successAccountCollectionNfts: false,
        errorAccountCollectionNfts: null,
      };
    case ACCOUNT_COLLECTION_NFTS_SUCCESS:
      return {
        ...state,
        loadingAccountCollectionNfts: false,
        successAccountCollectionNfts: true,
        errorAccountCollectionNfts: null,
        nftsAccountCollectionNfts: action.payload,
      };
    case ACCOUNT_COLLECTION_NFTS_FAILURE:
      return {
        ...state,
        loadingAccountCollectionNfts: false,
        successAccountCollectionNfts: false,
        errorAccountCollectionNfts: action.payload,
      };
    case RESET_ACCOUNT_COLLECTION_NFTS_FAILURE:
      return {
        ...state,
        successAccountCollectionNfts: false,
        nftsAccountCollectionNfts: [],
      };
    default:
      return state;
  }
};

export default AccountCollectionNfts;
