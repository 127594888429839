import {
  EDIT_NFT,
  EDIT_NFT_SUCCESS,
  EDIT_NFT_ERROR,
  RESET_EDIT_NFT,
} from "./actionTypes";

const initialState = {
  errorMsg: "",
  loading: false,
  error: false,
  success: null,
  form: [],
};

const EditNft = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_NFT:
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
        errorMsg: null,
      };
      break;
    case EDIT_NFT_SUCCESS:
      state = {
        ...state,
        loading: false,
        errorMsg: false,
        success: true,
        form: action.payload,
      };
      break;
    case EDIT_NFT_ERROR:
      state = {
        ...state,
        errorMsg: action.payload,
        loading: false,
        error: true,
        success: false,
      };
      break;
    case RESET_EDIT_NFT:
      state = {
        ...state,
        errorMsg: "",
        loading: false,
        error: false,
        success: null,
        form: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EditNft;
