import {
  FAVORITES_NFTCARD_REQUEST,
  FAVORITES_NFTCARD_SUCCESS,
  FAVORITES_NFTCARD_FAILURE,
  RESET_FAVORITES_NFTCARD_FAILURE,
  FAVORITES_COLLECTION_REQUEST,
  FAVORITES_COLLECTION_SUCCESS,
  FAVORITES_COLLECTION_FAILURE,
  RESET_FAVORITES_COLLECTION,
} from "./actionTypes";

const initialState = {
  loadingFavoritesNftCard: false,
  successFavoritesNftCard: false,
  errorFavoritesNftCard: null,
  nftsFavorites: [],
  loadingFavoritesCollection: false,
  successFavoritesCollection: false,
  errorFavoritesCollection: null,
  CollectionFavorites: [],
};
const FavoritesNftCard = (state = initialState, action) => {
  switch (action.type) {
    case FAVORITES_NFTCARD_REQUEST:
      return {
        ...state,
        loadingFavoritesNftCard: true,
        successFavoritesNftCard: false,
        errorFavoritesNftCard: null,
      };
    case FAVORITES_NFTCARD_SUCCESS:
      return {
        ...state,
        loadingFavoritesNftCard: false,
        successFavoritesNftCard: true,
        errorFavoritesNftCard: false,
        nftsFavorites: action.payload,
      };
    case FAVORITES_NFTCARD_FAILURE:
      return {
        ...state,
        loadingFavoritesNftCard: false,
        successFavoritesNftCard: false,
        errorFavoritesNftCard: action.payload,
      };
    case RESET_FAVORITES_NFTCARD_FAILURE:
      return {
        ...state,
        loadingFavoritesNftCard: false,
        successFavoritesNftCard: false,
        errorFavoritesNftCard: null,
        nftsFavorites: [],
      };
    //collecitons
    case FAVORITES_COLLECTION_REQUEST:
      return {
        ...state,
        loadingFavoritesCollection: true,
        successFavoritesCollection: false,
        errorFavoritesCollection: null,
      };
    case FAVORITES_COLLECTION_SUCCESS:
      return {
        ...state,
        loadingFavoritesCollection: false,
        successFavoritesCollection: true,
        errorFavoritesCollection: null,
        CollectionFavorites: action.payload,
      };
    case FAVORITES_COLLECTION_FAILURE:
      return {
        ...state,
        loadingFavoritesCollection: false,
        successFavoritesCollection: false,
        errorFavoritesCollection: action.payload,
      };
    case RESET_FAVORITES_COLLECTION:
      return {
        ...state,
        loadingFavoritesCollection: false,
        successFavoritesCollection: false,
        errorFavoritesCollection: null,
        CollectionFavorites: [],
      };
    default:
      return state;
  }
};

export default FavoritesNftCard;
