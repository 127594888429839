// ActionTypes.js
export const EXPLORE_REQUEST = "EXPLORE_REQUEST";
export const EXPLORE_SUCCESS = "EXPLORE_SUCCESS";
export const EXPLORE_FAILURE = "EXPLORE_FAILURE";

//search explor
export const EXPLORE_SEARCH_REQUEST = "EXPLORE_SEARCH_REQUEST";
export const EXPLORE_SEARCH_SUCCESS = "EXPLORE_SEARCH_SUCCESS";
export const EXPLORE_SEARCH_FAILURE = "EXPLORE_SEARCH_FAILURE";
export const RESET_EXPLORE_SEARCH_REQUEST = "RESET_EXPLORE_SEARCH_REQUEST";
