import { takeLatest, call, put } from "redux-saga/effects";
import {
  ACCOUNT_COLLECTION_NFTS_REQUEST,
  ACCOUNT_COLLECTION_NFTS_SUCCESS,
  ACCOUNT_COLLECTION_NFTS_FAILURE,
} from "./actionTypes";
import {
  AccountCollectionNftsSuccess,
  AccountCollectionNftsFailure,
} from "./action";
import { getAccountCollectionNfts } from "@/config/helpers/backend_helper";

function* AccountCollectionNfts(action) {
  try {
    //console.log(action.payload);
    const response = yield call(getAccountCollectionNfts, action.payload);
    // console.log(response);

    yield put(AccountCollectionNftsSuccess(response));
  } catch (error) {
    yield put(AccountCollectionNftsFailure(error));
  }
}

function* AccountCollectionNftsSaga() {
  yield takeLatest(ACCOUNT_COLLECTION_NFTS_REQUEST, AccountCollectionNfts);
}

export default AccountCollectionNftsSaga;
