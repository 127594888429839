import {
  MANAGE_MY_BIDS_NFTCARD_REQUEST,
  MANAGE_MY_BIDS_NFTCARD_SUCCESS,
  MANAGE_MY_BIDS_NFTCARD_FAILURE,
  RESET_MANAGE_MY_BIDS_NFTCARD_FAILURE
} from "./actionTypes";

export const ManageMyBidsNftCardRequest = (requestData) => ({
  type: MANAGE_MY_BIDS_NFTCARD_REQUEST,
  payload: requestData,
});

export const ManageMyBidsNftCardSuccess = (data) => ({
  type: MANAGE_MY_BIDS_NFTCARD_SUCCESS,
  payload: data,
});

export const ManageMyBidsNftCardFailure = (error) => ({
  type: MANAGE_MY_BIDS_NFTCARD_FAILURE,
  payload: error,
});

export const ResetManageMyBidsNftCardFailure = () => ({
  type: RESET_MANAGE_MY_BIDS_NFTCARD_FAILURE,
});
